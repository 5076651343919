import { Injectable } from '@angular/core';
import { attachParametersToUrl } from './utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IUser } from 'app/services/repositories/user-repository.service';
import { Observable } from 'rxjs';

/*
    Rest action definitions for white label of the portal
*/
@Injectable({ providedIn: 'root' })
export class ApplicationRepositoryService {
  constructor(private http: HttpClient) {}

  login(appId: string, params: ILoginParams): Observable<IUser> {
    const url = '/2/application/' + appId + '/login';
    const formData = new FormData();
    formData.append('email', params.email);
    formData.append('password', params.password);
    return this.http.post<IUser>(url, formData, { headers: new HttpHeaders({ enctype: 'multipart/form-data' }) });
  }

  getInfo(params: IAppInfoParams): Observable<IAppInfo> {
    const url = '/2/application/info';
    return this.http.get<IAppInfo>(attachParametersToUrl(url, params));
  }

  getRoles(): Observable<string[]> {
    const url = '/2/application/role';
    return this.http.get<string[]>(url);
  }

  // TODO test this still works in PeterPans portal

  addPortalUser(appId: string, body: IAddUserBody): Observable<any> {
    const url = '/2/application/' + appId + '/invite';
    return this.http.post<any>(url, body);
  }
}

export interface IAddUserBody {
  email: string;
  name: string;
  role: string;
}

export interface ILoginParams {
  email: string;
  password: string;
}

export interface IAppInfoParams {
  referer?: string;
}

export interface IAppInfo {
  applicationId?: string;
  iconUrl?: string;
}
