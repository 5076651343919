import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ToggleSideNavService } from '../../services/toggle-side-nav.service';
import { IUser, UserRepositoryService } from '@/app/services/repositories/user-repository.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppConfigService } from '@/app/config/app-config.service';
import { SessionService } from '@/app/core/auth/session.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { produce } from 'immer';

@UntilDestroy()
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class SidenavComponent implements OnInit {
  navLinks = [];

  logoPng = 'assets/marker.png'; // for cases where a person  in search autocomplete doesn't have a profile pic
  users: IUser[] = [];
  @ViewChild(MatSidenav, { static: true }) sideNav: MatSidenav; // Finds the first MatSidenav in view
  @ViewChild('searchFormInputValue', { static: true }) searchFormInputValue: any;

  // sideNav should initially be open unless on small screen device.
  private mediaMatcher: MediaQueryList = matchMedia(`(max-width: 600px)`);

  searchTextChanged = new Subject<string>();
  loading = false;

  constructor(
    public router: Router,
    public toggleSideNavService: ToggleSideNavService,
    public appConfig: AppConfigService,
    public userService: UserRepositoryService,
    public currentUser: SessionService
  ) {}

  get currentAdminGroupId() {
    return this.currentUser.currentAdminGroup.groupId;
  }

  private getNavLinks() {
    const n = [
      {
        icon: 'insert_chart',
        label: 'Insights',
        items: [
          {
            icon: 'home',
            url: '/insights/dashboard',
            label: 'Dashboard',
          },
          {
            icon: 'group',
            url: '/insights/demographics',
            label: 'Analytics',
          },
          this.currentAdminGroupId != null && {
            icon: 'message',
            url: '/insights/push',
            label: 'Instant Push Stats',
          },
          {
            icon: 'map',
            label: 'Maps',
            items: [
              {
                icon: 'account_circle',
                url: '/insights/user-map/all',
                label: 'All Users',
              },
              this.currentAdminGroupId != null && {
                icon: 'account_circle',
                url: '/insights/user-map/group',
                label: 'Group Users',
              },
            ],
          },
        ],
      },
      {
        icon: 'group',
        label: 'Group',
        items: [
          this.currentAdminGroupId != null && {
            icon: 'info',
            url: '/group/info',
            label: 'Group Info',
          },
          this.currentAdminGroupId === null && {
            icon: 'add',
            url: '/group/create',
            label: 'Create Group',
          },
          {
            icon: 'list',
            url: '/group/list',
            label: 'Groups List',
          },
        ],
      },
      {
        icon: 'view_list',
        label: 'Feed',
        items: [
          {
            icon: 'view_list',
            url: '/feed',
            label: 'Feed',
          },
          {
            icon: 'chat',
            url: '/comments',
            label: 'Comments',
          },
        ],
      },
      {
        icon: 'notification_important',
        label: 'Engagement',
        items: [
          {
            icon: 'trip_origin',
            url: '/geoFences',
            label: 'GeoFences',
          },
          {
            icon: 'group',
            url: '/userSegments',
            label: 'User Segments',
          },
          {
            icon: 'perm_device_information',
            url: '/templates',
            label: 'Message Templates',
          },
          {
            icon: 'my_location',
            url: '/triggers',
            label: 'Message Triggers',
          },
          {
            icon: 'sms',
            url: '/push',
            label: 'Instant Push',
          },
        ],
      },
      {
        icon: 'book_online',
        label: 'Booking',
        items: [
          {
            icon: 'search',
            url: '/booking/query',
            label: 'Booking Query',
          },
        ],
      },
      {
        icon: 'card_giftcard',
        label: 'Rewards',
        items: [
          {
            icon: 'format_list_numbered',
            url: '/rewards/leaderboard',
            label: 'Status Leader Board',
          },
        ],
      },
    ] as MenuItem[];

    const { extendNav = () => {} } = this.appConfig.appConfig;

    try {
      return produce(n, extendNav);
    } catch (e) {
      console.error(e);
      return n;
    }
    // eslint-disable-next-line @typescript-eslint/semi, @typescript-eslint/member-delimiter-style
  }

  // TODO fix change detection error when logging out
  // error caused by binding to method in view shouldSideNavBeOpen()
  // try using a variable instead? e.g. 'shouldSideNavBeOpen: boolean'
  ngOnInit(): void {
    this.navLinks = this.getNavLinks();

    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      this.updateLoadingState(event);
    });

    this.currentUser.currentAdminGroup$.pipe(untilDestroyed(this)).subscribe(() => {
      this.navLinks = this.getNavLinks();
    });

    // This is actively watching for screen size change via router
    this.router.events.pipe(untilDestroyed(this)).subscribe(() => {
      if (!this.shouldSideNavBeOpen()) {
        this.sideNav.close();
      }
    });

    // subscribed to an observable called sideNavToggle$
    this.toggleSideNavService.sideNavToggle$.pipe(untilDestroyed(this)).subscribe(() => this.sideNav.toggle());

    this.toggleSideNavService.sideNavOpen$.pipe(untilDestroyed(this)).subscribe(() => this.sideNav.open());

    this.toggleSideNavService.sideNavClose$.pipe(untilDestroyed(this)).subscribe(() => this.sideNav.close());

    this.searchTextChanged.pipe(debounceTime(300), untilDestroyed(this)).subscribe((val) => {
      this.loadUserChoices(val);
    });
  }

  updateLoadingState(routerEvent) {
    if (routerEvent instanceof NavigationStart) {
      this.loading = true;
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.loading = false;
    }
  }

  autocompleteDropdown(input) {
    const value = input.currentTarget.value;
    // need type check to avoid passing an IUser object when user selected and causing an error
    if (typeof value === 'string' && value.length >= 3) {
      this.searchTextChanged.next(value);
    } else {
      this.users = [];
    }
  }

  removeNaughtySymbols(str: string) {
    // The following symbols cause errors: []{}\|^
    return str.replace(/[[\]{}|^\\]/g, '');
  }

  loadUserChoices(value: string) {
    if (value) {
      this.userService
        .getUsers({
          search: this.removeNaughtySymbols(value),
          showDeactive: true,
          groupId: this.currentAdminGroupId,
        })
        .subscribe((result) => {
          this.users = result.results;
        });
    }
  }

  shouldSideNavBeOpen(): boolean {
    // Should be closed by default if on small screen or not logged in
    return this.isScreenBig() && this.currentUser.isLoggedIn;
  }

  isScreenBig(): boolean {
    return !this.mediaMatcher.matches;
  }

  navToUserProfile(e) {
    this.router.navigateByUrl(`/user/${e.source.value.id}`);
    this.searchFormInputValue.nativeElement.value = '';
  }

  displayBlank(): string {
    return '';
  }
}
