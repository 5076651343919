import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IUser } from 'app/services/repositories/user-repository.service';
import { IDateRange } from '@/app/components/shared/date-selector/date-selector.component';
/*
    FYI groups isn't its own microservice on the backend. It's in the user service
 */

@Injectable({ providedIn: 'root' })
export class GroupsRepositoryService {
  // TODO refactor this class into the style of the other REST services

  constructor(private http: HttpClient) {}

  getStats(dateRange: IDateRange, groupId: string): Observable<IGroupsStats> {
    const url = '/2/groups/' + groupId + '/stats' + '?' + 'to=' + dateRange.to + '&from=' + dateRange.from;
    return this.http.get<IGroupsStats>(url);
  }

  getGroupInfo(groupId: string): Observable<IGroupInfo> {
    const url = '/2/groups/' + groupId;
    return this.http.get<IGroupInfo>(url);
  }

  getGroupAdmins(groupId: string): Observable<IUser[]> {
    const url = '/2/groups/' + groupId + '/admins';
    return this.http.get<IUser[]>(url);
  }

  getGroupMarketingUser(groupId: string): Observable<IUser> {
    const url = '/2/groups/' + groupId + '/marketinguser';
    return this.http.get<IUser>(url);
  }

  postGroup(newGroup: IGroupInfo): Observable<any> {
    const url = '/2/groups/';
    return this.http.post<IGroupInfo>(url, newGroup);
  }

  putGroup(editedGroup: IGroupInfo, groupId: string): Observable<any> {
    const url = '/2/groups/' + groupId;
    return this.http.put<IGroupInfo>(url, editedGroup);
  }

  postLogo(file: Blob, groupId: string): Observable<string> {
    const url = '/2/groups/' + groupId + '/image/logo';

    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(url, formData, { responseType: 'text' });
  }

  postCover(file: Blob, groupId: string): Observable<string> {
    const url = '/2/groups/' + groupId + '/image/background';

    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(url, formData, { responseType: 'text' });
  }

  addGroupAdmin(newAdminEmails: string[], groupId: string): Observable<any> {
    const url = '/2/groups/' + groupId + '/admins';
    return this.http.post<any>(url, newAdminEmails);
  }

  deleteGroupAdmin(adminEmail: string, groupId: string) {
    const url = '/2/groups/' + groupId + '/admins?adminuseremail=' + adminEmail;
    return this.http.delete<any>(url);
  }

  addMarketingUser(newMarketingUserEmail: string, groupId: string): Observable<any> {
    const url = '/2/groups/' + groupId + '/marketinguser?marketinguseremail=' + newMarketingUserEmail;
    return this.http.post<any>(url, newMarketingUserEmail);
  }

  deleteMarketingUser(marketingUserEmail: string, groupId: string) {
    const url = '/2/groups/' + groupId + '/marketinguser?marketinguseremail=' + marketingUserEmail;
    return this.http.delete<any>(url);
  }

  deleteGroup(groupId: string) {
    const url = '/2/groups/' + groupId;
    return this.http.delete<any>(url);
  }
}

export interface IGroupInfo {
  backgroundURL?: string;
  description?: string;
  groupPublic?: boolean;
  id?: string;
  imageURL?: string;
  metaData?: IGroupMeta;
  name?: string;
  publicUrl?: string;
  type?: string;
  websiteUrl?: string;
  welcomeMessage?: string;
}

export interface IGroupMeta {
  customerId?: string;
  groupStats?: any;
  memberCount: number;
  members: IUser[];
  membershipStatus: string;
  notices?: any;
  numberOfDeals: number;
}

export interface IGroupsStats {
  newMembers: number;
  totalMembers: number;
  pushSent: number;
  pushOpen: number;
  pushRead: number;
  newPosts: number;
  newComments: number;
  postViews: number;
}
