import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  ICroppedPhoto,
  IMyCropperSettings,
  PhotoCropperModalComponent,
} from '../../modals/photo-cropper-modal/photo-cropper-modal.component';

@Component({
  selector: 'app-photos-uploader',
  templateUrl: './photos-uploader.component.html',
  styleUrls: ['./photos-uploader.component.scss'],
})
export class PhotosUploaderComponent {
  @Input()
  public parentForm: UntypedFormGroup;

  @Output()
  photosUpdated = new EventEmitter<ICroppedPhoto[]>();

  @ViewChild('hiddenPhotoInput', { static: true })
  hiddenPhotoInput: any;

  photosAdded: ICroppedPhoto[] = [];
  cropperModalConfig: MatDialogConfig = new MatDialogConfig();

  constructor(public changeDetectorRef: ChangeDetectorRef, public modal: MatDialog) {
    this.cropperModalConfig.width = '600px';
  }

  onNewPhotosUploaded(event: any) {
    const fileList: FileList = event.target.files;

    // Open cropping modal
    this.cropperModalConfig.data = <IMyCropperSettings>{
      files: fileList,
    };
    const modalRef = this.modal.open(PhotoCropperModalComponent, this.cropperModalConfig);

    // when modal closes we receive our cropped images
    modalRef.afterClosed().subscribe((result) => {
      // on cropper response add/replace in array (no duplicates allowed)
      this.addCroppedPhotosToArray(result.data);
      // Clear the actual input in case user deletes photo then tries to reupload the same photo.
      this.hiddenPhotoInput.nativeElement.value = '';
      this.changeDetectorRef.detectChanges();
      // then send the picture to the parent component
      this.photosUpdated.emit(this.photosAdded);
    });
  }

  addCroppedPhotosToArray(photos: ICroppedPhoto[]) {
    for (let i = 0; i < photos.length; i++) {
      const photoAlreadyExistsIndex = this.findPhotoInArray(photos[i].fileName);
      if (photoAlreadyExistsIndex === -1) {
        // add photo if it hasn't already been uploaded
        this.photosAdded.push(photos[i]);
      } else {
        // else replace the existing photo with the new version
        this.photosAdded[photoAlreadyExistsIndex] = photos[i];
      }
    }

    this.updateFileNames();
    this.changeDetectorRef.detectChanges();
    this.photosUpdated.emit(this.photosAdded);
    // Clear the actual input in case user deletes photo then tries to reupload the same photo.
    this.hiddenPhotoInput.nativeElement.value = '';
  }

  /*
   * function checking if a photo with this name has already been uploaded.
   * returns index if found and -1 if not found
   *
   * Used because I need each photo to have a unique name for delete button on cards to work
   */
  findPhotoInArray(fileName: string): number {
    let index = -1;
    for (let i = 0; i < this.photosAdded.length; i++) {
      if (fileName === this.photosAdded[i].fileName) {
        index = i;
      }
    }
    return index;
  }

  // displaying the names of the files in the Additional Photos input for style
  updateFileNames() {
    let displayString = '';
    for (let i = 0; i < this.photosAdded.length; i++) {
      displayString = displayString + ' ' + this.photosAdded[i].fileName;
    }
    this.parentForm.controls['photosInput'].setValue(displayString);
  }

  deletePhoto(photo: ICroppedPhoto) {
    const indexOfPhoto = this.findPhotoInArray(photo.fileName);
    this.photosAdded.splice(indexOfPhoto, 1);
    this.updateFileNames();
    this.changeDetectorRef.detectChanges();
    this.photosUpdated.emit(this.photosAdded);
  }
}
