<mat-toolbar color="primary">
  <span>Comments</span>
</mat-toolbar>

<div class="page-content">
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-form-field fxFlex>
      <input
        type="text"
        #searchFormInputValue
        matInput
        (input)="changeSearchText($event)"
        placeholder="Search for Comments"
        value="{{ searchText }}"
      />
    </mat-form-field>
    <mat-form-field class="fill-parent" fxFlex="30" class="filter-input" *ngIf="dataSource.data.length > 0">
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" />
    </mat-form-field>
  </div>
  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
    <p>Loading...</p>
    <div class="spinner-container">
      <mat-spinner [diameter]="20" [strokeWidth]="4"></mat-spinner>
    </div>
  </div>

  <table
    mat-table
    [dataSource]="dataSource"
    multiTemplateDataRows
    matSort
    class="mat-elevation-z8"
    [class.loading]="loading"
  >
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: displayedColumns"
      class="element-row"
      [class.expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef fxFlex="65" fxLayout="row" fxLayoutAlign="start center">Comment</th>
      <td mat-cell *matCellDef="let element" fxFlex="65" fxLayout="row" fxLayoutAlign="start center">
        <div *ngIf="element.comment">
          {{ element.comment }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="postedByName">
      <th mat-header-cell *matHeaderCellDef fxFlex="20" fxLayout="row" fxLayoutAlign="start center" mat-sort-header>
        Posted By
      </th>
      <td mat-cell *matCellDef="let element" fxFlex="20" fxLayout="row" fxLayoutAlign="start center">
        <div *ngIf="element.postedByName">
          <a routerLink="/user/{{ element.postedBy }}" href="/user/{{ element.postedBy }}">
            {{ element.postedByName }}
          </a>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="posted">
      <th mat-header-cell *matHeaderCellDef fxFlex="15" fxLayout="row" fxLayoutAlign="start center" mat-sort-header>
        Posted Date
      </th>
      <td mat-cell *matCellDef="let element" fxFlex="15" fxLayout="row" fxLayoutAlign="start center">
        <div *ngIf="element.posted">
          {{ element.posted | date: 'mediumDate' }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
          <app-comment-card [cardInfo]="element"></app-comment-card>
        </div>
      </td>
    </ng-container>
  </table>
  <mat-paginator *ngIf="dataSource.data.length > 0" [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons>
  </mat-paginator>
</div>
