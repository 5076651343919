<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button *ngIf="showMenuButton" mat-icon-button (click)="toggleSideNav()">
      <mat-icon>menu</mat-icon>
    </button>
    <a class="logoLink" [routerLink]="['/']">
      <img class="logo" [src]="appConfig.whiteLabelLogo" />
    </a>
    <div fxLayout="row" class="profile" *ngIf="!!user" [matTooltip]="user.id" [matTooltipPosition]="'right'">
      <img fxLayout="row" class="profile-pic" [src]="user.profileImage" alt="Profile Image" />
      <div fxLayout="row" class="profile-detail">
        {{ user.userName }}<br />
        {{ user.email }}
      </div>
    </div>
    <div fxFlex></div>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="1em">
      <button
        *ngIf="showMenuButton"
        [disabled]="numberReportedService.numReported <= 0"
        mat-button
        routerLink="/feed/reported"
      >
        <mat-icon
          [matBadge]="numberReportedService.numReported"
          matBadgePosition="before"
          [matBadgeColor]="numberReportedService.numReported > 0 && 'warn'"
          matBadgeSize="small"
          >report_problem</mat-icon
        >
        Reported
      </button>

      <div *ngIf="showMenuButton && appConfig.whiteLabelAppId === 'TRAVELLO'">
        <button mat-button [matMenuTriggerFor]="messagesDropdown" #messagesDropdownTrigger="matMenuTrigger">
          <mat-icon
            [matBadge]="currentUser.chats.metaData.unreadCount"
            matBadgePosition="before"
            [matBadgeColor]="currentUser.chats.metaData.unreadCount > 0 && 'warn'"
            matBadgeSize="small"
            >email</mat-icon
          >
          Messages
        </button>
        <mat-menu #messagesDropdown="matMenu" class="mat-menu-panel-flexible">
          <div class="dropdown-menu">
            <div
              class="dropdown-item"
              *ngFor="let chat of currentUser.chats.results"
              (click)="navToChatMessage(chat.otherParty.id)"
            >
              <app-chat-card [chat]="chat"></app-chat-card>
            </div>
            <div class="empty-dropdown-message" *ngIf="currentUser.chats.results.length === 0">
              You have no messages.
            </div>
          </div>
        </mat-menu>
      </div>
      <button mat-button (click)="openContactUsModal()">Contact</button>
      <button *ngIf="showMenuButton" mat-button [routerLink]="['/logout']">Logout</button>
      <div
        *ngIf="
          showMenuButton &&
          (currentUser.adminGroups.length > 0 || currentUser.isAdmin) &&
          appConfig.whiteLabelAppId === 'TRAVELLO'
        "
      >
        <button mat-button [matMenuTriggerFor]="groupDropdown" #groupDropdownTrigger="matMenuTrigger">
          {{ currentUser.currentAdminGroup.name }} ▼
        </button>
        <mat-menu #groupDropdown="matMenu">
          <mat-list class="dropdown-menu">
            <mat-list-item class="dropdown-item dense" *ngIf="currentUser.isAdmin" (click)="selectAdminGroup()">
              {{ currentUser.defaultAdminGroup.name }}
            </mat-list-item>
            <mat-list-item
              class="dropdown-item dense"
              *ngFor="let group of currentUser.adminGroups"
              (click)="selectAdminGroup(group)"
            >
              {{ group.name }}
            </mat-list-item>
          </mat-list>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
