import { IChatsResponse, IUser, IGroupRole } from '@/app/services/repositories/user-repository.service';
import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export const DEFAULT_NO_MESSAGES: IChatsResponse = {
  cursor: '',
  metaData: { count: 0, unreadCount: 0 },
  results: [],
};

export const DEFAULT_ADMIN_GROUP: IGroupRole = {
  role: 'ADMIN',
  groupId: null,
  name: 'Admin',
};

export enum AccessLevel {
  NOT_LOGGED_IN = 0,
  MEMBER,
  GROUP_MEMBER,
  GROUP_MARKETING,
  GROUP_ADMIN,
  FULL_ADMIN,
}

export interface SessionState {
  currentUser?: IUser;
  chats: IChatsResponse;
  currentAdminGroup: IGroupRole;
  token?: string;
  claims: Record<string, any>;
  groups: IGroupRole[];
  adminGroups: IGroupRole[];
  isAdmin: boolean;
}

export function createInitialState(): SessionState {
  return {
    currentUser: null,
    chats: DEFAULT_NO_MESSAGES,
    currentAdminGroup: DEFAULT_ADMIN_GROUP,
    token: null,
    claims: {},
    groups: [],
    adminGroups: [],
    isAdmin: false,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session', resettable: true })
export class SessionStore extends Store<SessionState> {
  constructor() {
    super(createInitialState());
  }
}
