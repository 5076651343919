import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared.module';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APIInterceptor } from './api-intercepter';
import { BugsnagErrorHandler } from './core/error-handler/bugsnag-error-handler';
import { AppConfigService } from './config/app-config.service';

import { BrowserModule } from '@angular/platform-browser';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { environment } from '../environments/environment';
import { ToolbarModule } from './toolbar/toolbar.module';
import { AuthConfigModule } from './auth/auth-config.module';
import { PublicEventsService } from 'angular-auth-oidc-client';

@NgModule({
  declarations: [AppComponent, LoginPageComponent],
  imports: [
    // leaving BrowserAnimationsModule out of imports file because I will use NoopAnimationsModule instead in tests
    BrowserAnimationsModule,
    // use RouterTestingModule instead in tests
    AppRoutingModule,
    // don't want to use a real key in unit tests
    SharedModule,
    BrowserModule,
    environment.production
      ? []
      : AkitaNgDevtools.forRoot({ name: `business-portal akita (${window.__APP_CONFIG__?.environment})` }),
    ToolbarModule,
    AuthConfigModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: BugsnagErrorHandler,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfigService: AppConfigService) => () => appConfigService.loadAppConfig(),
      multi: true,
      deps: [AppConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true,
    },
    { provide: NG_ENTITY_SERVICE_CONFIG, useValue: { baseUrl: 'https://jsonplaceholder.typicode.com' } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private readonly eventService: PublicEventsService) {}
}
