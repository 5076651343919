import { Component } from '@angular/core';
import { IGroupRole } from '@/app/services/repositories/user-repository.service';
import { GroupsRepositoryService } from '@/app/services/repositories/groups-repository.service';
import { SessionService } from '@/app/core/auth/session.service';
import { PopupsService } from '@/app/services/popups.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-groups-list',
  templateUrl: './groups-list.component.html',
  styleUrls: ['./groups-list.component.scss'],
})
export class GroupsListComponent {
  displayedColumns = ['name', 'actions'];

  constructor(
    private currentUser: SessionService,
    private groupsService: GroupsRepositoryService,
    private popups: PopupsService,
    private toastr: ToastrService
  ) {}

  get groups() {
    return this.currentUser.adminGroups;
  }

  get currentGroup() {
    return this.currentUser.currentAdminGroup;
  }

  selectAdminGroup(selectedGroup: IGroupRole) {
    this.currentUser.selectAdminGroup(selectedGroup);
  }

  async deleteGroup(group: IGroupRole) {
    if (await this.popups.confirm('Are you sure you want to delete the group "' + group.name + '"?')) {
      this.doDeleteGroup(group);
    }
  }

  private doDeleteGroup(group: IGroupRole) {
    this.groupsService.deleteGroup(group.groupId).subscribe(async () => {
      const groupName = group.name;
      this.currentUser.resetGroup = false;
      await this.currentUser.syncOnce();
      this.toastr.success(`"${groupName}" removed`);
    });
  }
}
