<div>
  <br />

  <div *ngIf="!loading" class="mat-elevation-z8 table-div">
    <mat-table #table matSort [dataSource]="geofences">
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      <ng-container matColumnDef="name">
        <mat-header-cell fxFlex="30" *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell fxFlex="30" *matCellDef="let element">
          <div *ngIf="element.name">
            {{ element.name }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="radius">
        <mat-header-cell fxFlex="30" *matHeaderCellDef> Radius </mat-header-cell>
        <mat-cell fxFlex="30" *matCellDef="let element">
          <div *ngIf="element.radius">{{ element.radius / 1000 | number: '1.0-1' }} km</div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell fxFlex="40" *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell fxFlex="40" *matCellDef="let element">
          <div fxLayout="row" fxLayoutGap="2em">
            <button mat-raised-button color="primary" (click)="viewGeoFence(element)">View</button>
            <button mat-raised-button color="warn" (click)="openConfirmationDialog(element)">Delete</button>
          </div>
        </mat-cell>
      </ng-container>
    </mat-table>
  </div>

  <br />
  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
    <p>Loading...</p>
    <div class="spinner-container">
      <mat-spinner [diameter]="20" [strokeWidth]="4"></mat-spinner>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <p *ngIf="geofences.length === 0 && !loading">You don't have any geofences set up.</p>
  </div>
</div>
