<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxLayoutGap="2em">
  <form [formGroup]="triggerForm" (ngSubmit)="triggerForm.valid && save()" fxLayout="column" fxLayoutGap="1em"
    fxFlex="60">
    <br />

    <mat-form-field class="fill-parent">
      <mat-select formControlName="fence" placeholder="Select Geo Fence" required>
        <mat-option *ngFor="let geofence of geofences" [value]="geofence.id">{{ geofence.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="fill-parent">
      <mat-select formControlName="userSegment" placeholder="Select User Segment" required>
        <mat-option *ngFor="let userSegment of userSegments" [value]="userSegment.id">{{
          userSegment.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="fill-parent">
      <mat-select formControlName="template" placeholder="Select Template" required>
        <mat-option *ngFor="let template of templates" [value]="template.id">{{ template.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="fill-parent">
      <mat-select formControlName="eventType" placeholder="Select Event Type" (selectionChange)="showHideSubform()"
        required>
        <mat-option value="ENTER">Enter</mat-option>
        <mat-option value="EXIT">Exit</mat-option>
        <mat-option value="TIMED">Schedule</mat-option>
      </mat-select>
    </mat-form-field>

    <div [ngClass]="{ hidden: hideScheduleForm }">
      <mat-form-field class="fill-parent">
        <mat-select formControlName="timeZone" placeholder="Time Zone" required>
          <mat-option *ngFor="let timeZone of timeZones" [value]="timeZone.name">
            {{ timeZone.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div fxLayout="row" fxLayoutGap="1em">
        <mat-form-field class="fill-parent">
          <mat-select formControlName="hour" placeholder="Hour" required>
            <mat-option value="00">00</mat-option>
            <mat-option value="01">01</mat-option>
            <mat-option value="02">02</mat-option>
            <mat-option value="03">03</mat-option>
            <mat-option value="04">04</mat-option>
            <mat-option value="05">05</mat-option>
            <mat-option value="06">06</mat-option>
            <mat-option value="07">07</mat-option>
            <mat-option value="08">08</mat-option>
            <mat-option value="09">09</mat-option>
            <mat-option value="10">10</mat-option>
            <mat-option value="11">11</mat-option>
            <mat-option value="12">12</mat-option>
            <mat-option value="13">13</mat-option>
            <mat-option value="14">14</mat-option>
            <mat-option value="15">15</mat-option>
            <mat-option value="16">16</mat-option>
            <mat-option value="17">17</mat-option>
            <mat-option value="18">18</mat-option>
            <mat-option value="19">19</mat-option>
            <mat-option value="20">20</mat-option>
            <mat-option value="21">21</mat-option>
            <mat-option value="22">22</mat-option>
            <mat-option value="23">23</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="fill-parent">
          <mat-select formControlName="minute" placeholder="Minute" required>
            <mat-option value="00">00</mat-option>
            <mat-option value="05">05</mat-option>
            <mat-option value="10">10</mat-option>
            <mat-option value="15">15</mat-option>
            <mat-option value="20">20</mat-option>
            <mat-option value="25">25</mat-option>
            <mat-option value="30">30</mat-option>
            <mat-option value="35">35</mat-option>
            <mat-option value="40">40</mat-option>
            <mat-option value="45">45</mat-option>
            <mat-option value="50">50</mat-option>
            <mat-option value="55">55</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayoutGap="1em grid">
        <mat-checkbox fxFlex="33" formControlName="monday">Monday</mat-checkbox>
        <mat-checkbox fxFlex="33" formControlName="tuesday">Tuesday</mat-checkbox>
        <mat-checkbox fxFlex="33" formControlName="wednesday">Wednesday</mat-checkbox>
      </div>
      <div fxLayoutGap="1em grid">
        <mat-checkbox fxFlex="33" formControlName="thursday">Thursday</mat-checkbox>
        <mat-checkbox fxFlex="33" formControlName="friday">Friday</mat-checkbox>
        <mat-checkbox fxFlex="33" formControlName="saturday">Saturday</mat-checkbox>
      </div>
      <div fxLayoutGap="1em grid">
        <mat-checkbox fxFlex="33" formControlName="sunday">Sunday</mat-checkbox>
      </div>
    </div>

    <mat-form-field>
      <input matInput placeholder="Cool Off Days" type="number" formControlName="coolOff" required />
      <mat-error> This is <strong>required</strong> </mat-error>
    </mat-form-field>

    <button mat-raised-button color="primary" type="submit" [disabled]="!triggerForm.valid">Save</button>
  </form>
  <div fxFlex="30">
    <app-mock-mobile-template [templateHtml]="getTemplate(triggerForm.get('template').value)?.marketingText">
    </app-mock-mobile-template>
  </div>
</div>
