<mat-toolbar color="primary">
  <span>Feed</span>
</mat-toolbar>

<div class="page-content">
  <nav mat-tab-nav-bar mat-align-tabs="center" animationDuration="0ms" color="accent">
    <a
      mat-tab-link
      *ngFor="let link of navLinks"
      [routerLink]="link.url"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
    >
      {{ link.label }}
    </a>
  </nav>

  <br />

  <div *ngIf="loading" fxLayout="row">
    <div fxFlex></div>
    <div class="spinner-container">
      <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
    </div>
    <div fxFlex></div>
  </div>
  <div *ngIf="!loading && this.posts.length === 0" fxLayout="row">
    <div fxFlex></div>
    <p>
      No <span *ngIf="this.feedFilterRadio === 'REPORTEDONLY'">reported </span
      ><span *ngIf="this.feedFilterRadio === 'PINNEDONLY'">pinned </span>posts at this time
    </p>
    <div fxFlex></div>
  </div>
  <div *ngIf="!loading" fxLayout="row">
    <div fxFlex></div>
    <div fxLayout="column" fxLayoutGap="0.5em">
      <app-feed-card
        *ngFor="let post of posts"
        [cardInfo]="post"
        [reportedPost]="this.feedFilterRadio === 'REPORTEDONLY'"
        (deletePost)="deletePost($event)"
        (unPinPost)="getPosts(this.feedFilterRadio)"
      ></app-feed-card>
    </div>
    <div fxFlex></div>
  </div>
  <br />
  <div *ngIf="!loading && this.feedFilterRadio === 'ALL'" fxLayout="row">
    <div fxFlex></div>
    <button fxFlex="50" mat-raised-button color="primary" (click)="getPosts(cursor)">
      <mat-icon>chevron_right</mat-icon> Show More
    </button>
    <div fxFlex></div>
  </div>
  <br />
  <div *ngIf="!loading && this.feedFilterRadio === 'ALL' && !onFirstPage" fxLayout="row">
    <div fxFlex></div>
    <button fxFlex="50" mat-raised-button color="primary" (click)="getPosts(this.feedFilterRadio)">
      <mat-icon>refresh</mat-icon> Back to Newest
    </button>
    <div fxFlex></div>
  </div>
</div>
