import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { canActivateWithAccessGuard } from '@/app/core/auth/access-guard';
import { atLeastGroupAdmin } from '@/app/core/auth/auth-pipes';
import { ChangeCreditPageComponent } from '@/app/pages/rewards/pages/change-credit-page.component';

const routes: Routes = [
  { path: ':id', component: ChangeCreditPageComponent, ...canActivateWithAccessGuard(atLeastGroupAdmin) },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class ChangeCreditPageRoutingModule {}
