import { ErrorHandler, Injectable, Injector } from '@angular/core';
import Bugsnag, { Event } from '@bugsnag/js';
import { BugsnagErrorHandler as _BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { HttpErrorResponse } from '@angular/common/http';
import { AppConfigService } from '@/app/config/app-config.service';
import { SessionService } from '../auth/session.service';

@Injectable({ providedIn: 'root' })
export class BugsnagErrorHandler implements ErrorHandler {
  private errorHandler: _BugsnagErrorHandler;

  constructor(private appConfigService: AppConfigService, private injector: Injector) {
    this.appConfigService.ready$
      .toPromise()
      .then(() => {
        this.init();
      })
      .catch(console.error);
  }

  get userId() {
    const currentUser = this.injector.get(SessionService);
    return currentUser.currentUser.id;
  }

  private init() {
    const config = this.appConfigService.appConfig;
    const info = this.appConfigService.buildInfo;

    console.log('Starting Travello Business Portal version ' + info.version + ' in ' + config.environment + ' mode');
    const client = Bugsnag.start({
      apiKey: 'e860405e62a54d4000050669d790006e',
      appVersion: info.version,
      enabledReleaseStages: ['production', 'staging'],
      releaseStage: config.environment,
      onError: this.onError,
    });

    this.errorHandler = new _BugsnagErrorHandler(client);
  }

  private onError(event: Event) {
    if (this.userId) {
      event.setUser(this.userId);
      // don't set the user on the client, otherwise we can't revert to using IP addresses on logout
    }
    if (
      event.errors[0] &&
      event.errors[0].errorClass === 'HttpErrorResponse' &&
      event.originalError &&
      event.originalError.error
    ) {
      // Angular's HttpErrorResponse doesn't have a stacktrace, so we need to fudge things
      const errorResp = event.originalError as HttpErrorResponse;
      event.addMetadata('angular', 'HTTPErrorResponse.error', errorResp.error);
      // the URL that was called is in the message, so let's group the errors based on the URL path (non-host)
      event.groupingHash = errorResp.message.match('://[^/]+(/[^?]+)')[1];
    }
  }

  handleError(error: any): void {
    if (this.errorHandler) {
      this.errorHandler.handleError(error);
    } else {
      console.error(error);
    }
  }
}
