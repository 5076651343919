<mat-toolbar color="primary">
  <span
    >Chat<span *ngIf="otherParty"> with {{ otherParty.userName }}</span></span
  >
</mat-toolbar>

<div class="page-content">
  <div fxLayout="column">
    <div *ngFor="let message of chatMessages">
      <div class="chat-date">{{ message.date | date: 'medium' }}</div>
      <app-chat-message *ngIf="message.from.id === me.id" [fromMe]="true" [message]="message"></app-chat-message>
      <app-chat-message *ngIf="message.from.id !== me.id" [fromMe]="false" [message]="message"></app-chat-message>
    </div>

    <div>
      <form
        [formGroup]="newMessageForm"
        (ngSubmit)="newMessageForm.valid && saveMessage()"
        fxLayout="row"
        fxLayoutGap="1em"
        fxLayoutAlign="space-around center"
      >
        <div fxFlex="4"></div>
        <mat-form-field fxFlex>
          <textarea matInput placeholder="Type a message" formControlName="newMessage" rows="1"></textarea>
        </mat-form-field>
        <button mat-raised-button color="primary" type="submit" [disabled]="!newMessageForm.valid">Send</button>
      </form>
    </div>
  </div>
</div>
