<mat-card class="feed-card">
  <mat-card-header>
    <a mat-card-avatar class="flat-link" [routerLink]="getUserProfileUrl(cardInfo.postedBy)" target="_blank">
      <img *ngIf="thumbnailImage" [src]="thumbnailImage" />
    </a>
    <mat-card-title>
      <a class="flat-link" [routerLink]="getUserProfileUrl(cardInfo.postedBy)" target="_blank">{{ postedByName }}</a>
    </mat-card-title>
    <mat-card-subtitle>{{ posted | date: 'medium' }}</mat-card-subtitle>
    <div fxFlex></div>

    <mat-card-actions>
      <ng-container *ngIf="!reportedPost && currentUser.isAdmin">
        <button color="success" *ngIf="!cardInfo.featured" mat-button (click)="makeEditorsPick()">
          <mat-icon>insert_emoticon</mat-icon> Editors Pick
        </button>
        <button color="success" *ngIf="cardInfo.featured" mat-button (click)="removeEditorsPick()">
          <mat-icon>insert_emoticon</mat-icon> Editors Pick Remove
        </button>
      </ng-container>
      <ng-container *ngIf="!reportedPost">
        <button color="primary" *ngIf="cardInfo.stayOnTop === undefined" mat-button (click)="openPinModal()">
          <mat-icon>arrow_upward</mat-icon> Pin
        </button>
        <button
          color="primary"
          *ngIf="cardInfo.stayOnTop === 0 || cardInfo.stayOnTop >= 1"
          mat-button
          (click)="unPinItem()"
        >
          <mat-icon>arrow_downward</mat-icon> Unpin
        </button>
      </ng-container>
      <ng-container *ngIf="reportedPost">
        <button mat-button color="success" [disabled]="approved" (click)="approvePost()">
          <mat-icon>check</mat-icon> Approve
        </button>
      </ng-container>
      <ng-container>
        <button mat-button color="warn" (click)="deleteClicked()"><mat-icon>delete_forever</mat-icon> Delete</button>
      </ng-container>
    </mat-card-actions>
  </mat-card-header>
  <mat-card-content>
    <div *ngIf="cardInfo.text" class="post-text">
      {{ cardInfo.text }}
    </div>
  </mat-card-content>
  <img class="card-picture" *ngIf="image" [src]="image" />

  <mat-card-actions>
    <div>
      <div *ngIf="!showComments" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-button [disabled]="numComments === 0" (click)="displayComments()">
          Show comments ({{ numComments }} Comments)<mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <div class="likes-data">
          <mat-icon>thumb_up</mat-icon> {{ likes }} <span class="like-text" *ngIf="likes === 1">Like</span
          ><span class="like-text" *ngIf="likes !== 1">Likes</span>
        </div>
      </div>
      <div *ngIf="showComments" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-button (click)="hideComments()">
          Hide comments ({{ numComments }} Comments)<mat-icon>keyboard_arrow_up</mat-icon>
        </button>
        <div class="likes-data">
          <mat-icon>thumb_up</mat-icon> {{ likes }} <span class="like-text" *ngIf="likes === 1">Like</span
          ><span class="like-text" *ngIf="likes !== 1">Likes</span>
        </div>
      </div>

      <div class="card-comments" *ngIf="showComments">
        <div *ngIf="loading" fxLayout="row">
          <div fxFlex></div>
          <div class="spinner-container">
            <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
          </div>
          <div fxFlex></div>
        </div>
        <div *ngIf="!loading">
          <div class="card-comment" *ngFor="let comment of comments" fxLayout="row">
            <img *ngIf="comment.thumbnailImage" class="comment-profile-pic" [src]="comment.thumbnailImage" />
            <div fxFlex class="card-comment-bubble">
              <span class="comment-name">{{ comment.postedByName }} </span>
              {{ comment.comment }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-post-add-comment">
      <form
        [formGroup]="commentForm"
        (ngSubmit)="commentForm.valid && saveComment()"
        fxLayout="row"
        fxLayoutGap="1em"
        fxLayoutAlign="space-around center"
      >
        <div style="width: 22px"></div>
        <mat-form-field fxFlex>
          <input matInput placeholder="Add Comment" formControlName="comment" />
        </mat-form-field>
        <button mat-raised-button color="primary" type="submit" [disabled]="!commentForm.valid">Save</button>
      </form>
    </div>
  </mat-card-actions>
</mat-card>
