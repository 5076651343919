import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs/operators';
import { ITemplate, MarketingRepositoryService } from '@/app/services/repositories/marketing-repository.service';
import { TINYMCE_CONFIG } from '@/app/config/static-options';
import { SessionService } from '@/app/core/auth/session.service';
import { IMockTemplateMobile } from '@/app/components/shared/mock-mobile-template/mock-mobile-template.component';
import { EditTemplateService } from '@/app/services/edit-template.service';

// this page is being used for 2 different purposes
export enum ETemplatePageMode {
  Create = 'create', // create mode: for creating new templates
  Edit = 'edit', // edit mode for editing existing templates
}

@Component({
  selector: 'app-edit-template-page',
  templateUrl: './edit-template-page.component.html',
  styleUrls: ['./edit-template-page.component.scss'],
})
export class EditTemplatePageComponent implements OnInit {
  mode: ETemplatePageMode = ETemplatePageMode.Create;
  eTemplatePageMode = ETemplatePageMode;
  existingTemplateId: string;

  templateForm: UntypedFormGroup;
  tinymceConfig = TINYMCE_CONFIG;

  preview: IMockTemplateMobile = {
    marketingText: '<p></p>',
  };

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public marketingService: MarketingRepositoryService,
    public toastr: ToastrService,
    public currentUser: SessionService,
    public router: Router,
    public editTemplateService: EditTemplateService,
    private activatedRoute: ActivatedRoute
  ) {
    this.createForm();
  }

  get currentAdminGroupId() {
    return this.currentUser.currentAdminGroup.groupId;
  }

  ngOnInit() {
    this.bindFormInputsToPreview();

    this.activatedRoute.queryParams.pipe(filter((params) => params.mode)).subscribe((params) => {
      this.mode = params.mode;

      const existingTemplate = this.editTemplateService.$template.getValue();
      if (existingTemplate !== null) {
        this.existingTemplateId = existingTemplate.id;
        this.loadExistingTemplateIntoForm(existingTemplate);
      }
    });

    // This sets off a second round of change detection to avoid the forms validation throwing a ExpressionChangedAfterItHasBeenCheckedError
    this.changeDetectorRef.detectChanges();
  }

  createForm() {
    this.templateForm = new UntypedFormGroup({
      templateName: new UntypedFormControl(''),
      pushMessage: new UntypedFormControl(''),
      marketingText: new UntypedFormControl(''),
    });
  }

  saveTemplate() {
    this.marketingService
      .postTemplate({
        group: this.currentAdminGroupId,
        marketingText: this.templateForm.get('marketingText').value,
        message: this.templateForm.get('pushMessage').value,
        name: this.templateForm.get('templateName').value,
      })
      .subscribe(
        () => {
          this.toastr.success('Template Saved');
          this.router.navigateByUrl('/templates');
        },
        () => {
          this.toastr.error('Template Save Failed');
        }
      );
  }

  // This is how I get the preview to update when user enters stuff in the WYSIWYG editor
  bindFormInputsToPreview() {
    this.templateForm.controls['marketingText'].valueChanges.subscribe((val) => {
      this.preview = {
        marketingText: val,
      };
    });
  }

  loadExistingTemplateIntoForm(template: ITemplate) {
    this.templateForm.controls['marketingText'].setValue(template.marketingText);
    this.templateForm.controls['pushMessage'].setValue(template.message);
    this.templateForm.controls['templateName'].setValue(template.name);
  }

  saveEdit() {
    this.marketingService
      .editTemplate(this.existingTemplateId, {
        marketingText: this.templateForm.get('marketingText').value,
        message: this.templateForm.get('pushMessage').value,
        name: this.templateForm.get('templateName').value,
      })
      .subscribe(
        () => {
          this.toastr.success('Template edited');
          this.router.navigateByUrl('/templates');
        },
        () => {
          this.toastr.error('Edit failed');
        }
      );
  }
}
