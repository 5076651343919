import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MarketingRepositoryService } from '@/app/services/repositories/marketing-repository.service';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SessionService } from '@/app/core/auth/session.service';
import {
  PhotoCropperModalComponent,
  ICroppedPhoto,
  IMyCropperSettings,
  ICropperReturnData,
} from '@/app/components/modals/photo-cropper-modal/photo-cropper-modal.component';

@Component({
  selector: 'app-simplified-template-page',
  templateUrl: './simplified-template-page.component.html',
  styleUrls: ['./simplified-template-page.component.scss'],
})
export class SimplifiedTemplatePageComponent {
  cropperModalConfig: MatDialogConfig = new MatDialogConfig();
  modalRef: MatDialogRef<PhotoCropperModalComponent, MatDialogConfig>;
  loading = false;

  templateForm: UntypedFormGroup;

  croppedPhoto: ICroppedPhoto;
  photoFile: File;
  @ViewChild('hiddenPhotoInput', { static: true }) hiddenPhotoInput: ElementRef;
  @ViewChild('title', { static: true }) title: ElementRef;
  @ViewChild('text', { static: true }) text: ElementRef;
  @ViewChild('button', { static: true }) button: ElementRef;
  color = '#10ABE4';

  get buttonContainerStyle() {
    return `
      text-align: center;
      margin: 32px 0;
    `.replace(/\r?\n|\r/g, '');
  }

  buttonStyle(background: string) {
    return `
      display: inline-block;
      text-align:center;
      font-family: Roboto, sans-serif;
      padding:1rem;
      -webkit-border-radius:50px;
      -moz-border-radius:50px;
      border-radius:50px;
      font-weight:900;
      font-size:22px;
      color:#FFFFFF;
      background:${background};
      text-decoration:none;
  `.replace(/\r?\n|\r/g, '');
  }

  constructor(
    public modal: MatDialog,
    public marketingService: MarketingRepositoryService,
    public toastr: ToastrService,
    public currentUser: SessionService,
    public changeDetectorRef: ChangeDetectorRef,
    public router: Router
  ) {
    this.cropperModalConfig.width = '600px';
    this.createForm();
  }

  get currentAdminGroupId() {
    return this.currentUser.currentAdminGroup.groupId;
  }

  // This triggers after file has been input
  onPhotoChange($event) {
    const files = $event.target.files; // FileList Object
    this.photoFile = files[0]; // File Object

    const cropperSettings: IMyCropperSettings = {
      files,
      height: 400,
      width: 400,
    };

    // Open cropping modal
    this.cropperModalConfig.data = cropperSettings;
    this.modalRef = this.modal.open(PhotoCropperModalComponent, this.cropperModalConfig);

    // when modal closes we receive our cropped image
    this.modalRef.afterClosed().subscribe((result: ICropperReturnData | undefined) => {
      if (result !== undefined) {
        this.croppedPhoto = result.data[0];
        // Clear the actual input in case user deletes photo then tries to reupload the same photo.
        this.hiddenPhotoInput.nativeElement.value = '';
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  createForm() {
    this.templateForm = new UntypedFormGroup({
      templateName: new UntypedFormControl('', Validators.required),
      pushMessage: new UntypedFormControl('', Validators.required),
      hideButton: new UntypedFormControl(false),
      url: new UntypedFormControl('', Validators.required),
    });
  }

  // only call this when all the necessary fields are validating
  prepareTemplateHtml() {
    const buttonString = `
      <div id="template-button-container" style="${this.buttonContainerStyle}">
        <a id="template-button" href="${this.templateForm.get('url').value}"
        style="${this.buttonStyle(this.color)}">
        ${this.button.nativeElement.innerText}
        </a>
      </div>
    `;

    const templateString = `
      <div id="template-container" style="margin:-30px;">
        <img id="template-image" alt="Template Picture" src="${this.croppedPhoto.srcString}"/>
        <div id="template-content-container" style="margin:10px; padding:20px;">
          <h2 id="template-title" style="font-weight:700; padding:0; margin:4px; font-family:Roboto, sans-serif; text-align:center; word-wrap: break-word; color: #707070;">
            ${this.title.nativeElement.innerText}
          </h2>
          <p id="template-text" style="text-align: center; padding:0; margin:4px; font-family:Roboto, sans-serif; word-wrap: break-word; color: grey;">
            ${this.text.nativeElement.innerText}
          </p>
          ${this.templateForm.get('hideButton').value === true ? '' : buttonString}
        </div>
      </div>`;

    return templateString;
  }

  saveTemplate() {
    this.loading = true;
    this.marketingService
      .postTemplate({
        group: this.currentAdminGroupId,
        marketingText: this.prepareTemplateHtml(),
        message: this.templateForm.get('pushMessage').value,
        name: this.templateForm.get('templateName').value,
      })
      .subscribe(
        () => {
          this.toastr.success('Template Saved');
          this.router.navigateByUrl('/templates');
          this.loading = false;
        },
        () => {
          this.toastr.error('Template Save Failed');
          this.loading = false;
        }
      );
  }
  isTemplateValid() {
    return (
      this.title.nativeElement.innerText.length > 0 &&
      this.text.nativeElement.innerText.length > 0 &&
      (this.templateForm.get('hideButton').value === true ? true : this.button.nativeElement.innerText.length > 0) && // if button hidden then ignore this check
      (this.croppedPhoto !== undefined || this.croppedPhoto !== null)
    );
  }

  // when hiding button I still have to fill in button url field to satisfy form validation
  fillDummyUrl() {
    if (this.templateForm.get('hideButton').value === false) {
      this.templateForm.get('url').setValue('http://www.google.com');
      this.button.nativeElement.innerText = 'Example';
    } else {
      this.templateForm.get('url').setValue('');
      this.button.nativeElement.innerText = '';
    }
  }
}
