<mat-dialog-content>
  <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="16px">
    <img-cropper #myCropper [image]="imageData" [settings]="cropperSettings1" (onCrop)="cropped($event)"></img-cropper>
    <img  *ngIf="imageData.image" #preview class="image-preview" [src]="imageData.image" [style.width.px]="150 * previewRatio" />
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button type="button" mat-raised-button color="accent" (click)="onDoneCropping()">Finished Cropping</button>
</mat-dialog-actions>
