export const TINYMCE_CONFIG = {
  base_url: '/tinymce', // Root for resources
  suffix: '.min', // Suffix to use when loading resources
  plugins: ['paste', 'image', 'code'],
  paste_data_images: true,
  mode: 'textareas',
  force_br_newlines: false,
  force_p_newlines: false,
  forced_root_block: '',
  height: 500,
  content_css: '/assets/tinymce-content.css',
  valid_children: '+body[style]',
};

export const MAP_CIRCLE_OPTIONS: google.maps.CircleOptions = {
  fillColor: '#fc8d8d',
  strokeWeight: 0,
  strokeOpacity: 0.5,
};
