import { MAP_CIRCLE_OPTIONS } from '@/app/config/static-options';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IGeosegment } from '@/app/services/repositories/marketing-repository.service';

@Component({
  selector: 'app-view-geofence-modal',
  templateUrl: './view-geofence-modal.component.html',
  styleUrls: ['./view-geofence-modal.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ViewGeofenceModalComponent {
  // default settings for the map
  latitude = -27.47;
  longitude = 153.02;
  zoom = 11;
  radius = 1000;

  circleOptions = MAP_CIRCLE_OPTIONS;

  constructor(
    public dialogRef: MatDialogRef<ViewGeofenceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IGeosegment
  ) {
    this.latitude = data.location.latitude;
    this.longitude = data.location.longitude;
    this.radius = data.radius;
    this.zoom = this.calcZoom(data.radius);
  }

  closeModal() {
    this.dialogRef.close();
  }

  calcZoom(radius: number): number {
    if (radius < 1000) {
      return 14;
    } else if (radius < 10000) {
      return 11;
    } else if (radius < 100000) {
      return 8;
    } else if (radius < 1000000) {
      return 6;
    } else {
      return 2;
    }
  }
}
