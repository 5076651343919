import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-contact-us-modal',
  templateUrl: './contact-us-modal.component.html',
  styleUrls: ['./contact-us-modal.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ContactUsModalComponent {
  constructor(public dialogRef: MatDialogRef<ContactUsModalComponent>) {}

  closeModal() {
    this.dialogRef.close();
  }
}
