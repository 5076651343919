import { Component, OnInit } from '@angular/core';
import { IUserSegment, MarketingRepositoryService } from '@/app/services/repositories/marketing-repository.service';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from '@/app/core/auth/session.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { PopupsService } from '@/app/services/popups.service';

@UntilDestroy()
@Component({
  selector: 'app-your-usersegments-page',
  templateUrl: './your-usersegments-page.component.html',
  styleUrls: ['./your-usersegments-page.component.scss'],
})
export class YourUserSegmentsPageComponent implements OnInit {
  segments: IUserSegment[] = [];
  displayedColumns = ['name', 'gender', 'age', 'lastActive', 'created', 'nationality', 'interests', 'actions'];

  loading = false;

  constructor(
    private marketingService: MarketingRepositoryService,
    private currentUser: SessionService,
    private toastr: ToastrService,
    private popups: PopupsService
  ) {}

  get currentAdminGroupId() {
    return this.currentUser.currentAdminGroup.groupId;
  }

  ngOnInit() {
    this.currentUser.currentAdminGroup$.pipe(untilDestroyed(this)).subscribe(() => {
      this.getUserSegments();
    });
    this.getUserSegments();
  }

  getUserSegments() {
    this.loading = true;
    this.marketingService.getUserSegments({ groupId: this.currentAdminGroupId }).subscribe(
      (result) => {
        this.loading = false;
        this.segments = result.results.reverse();
      },
      () => {
        this.loading = false;
      }
    );
  }

  deleteSegment(segment: IUserSegment) {
    this.marketingService.deleteUserSegment(segment.id).subscribe(
      () => {
        this.toastr.success('Segment deleted.');
        this.deleteRowFromTable(segment);
      },
      () => this.toastr.error('Failed to delete user segment.')
    );
  }

  deleteRowFromTable(segment: IUserSegment) {
    const itemIndex = this.segments.findIndex((obj) => obj.id === segment.id);
    this.segments.splice(itemIndex, 1);
    // https://stackoverflow.com/questions/48991210/angular-material-mat-table-data-not-refreshed-from-parent-changes?rq=1
    // https://stackoverflow.com/questions/7486085/copying-array-by-value-in-javascript
    this.segments = this.segments.slice();
  }

  async openConfirmationDialog(segment: IUserSegment) {
    if (await this.popups.confirm('Are you sure you want to delete this?')) {
      this.deleteSegment(segment);
    }
  }
}
