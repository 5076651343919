import { Component, OnInit } from '@angular/core';
import { IPushAnalytics, MarketingRepositoryService } from '@/app/services/repositories/marketing-repository.service';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from '@/app/core/auth/session.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-message-analytics',
  templateUrl: './message-analytics.component.html',
  styleUrls: ['./message-analytics.component.scss'],
})
export class MessageAnalyticsComponent implements OnInit {
  stats: IPushAnalytics[] = [];
  displayedColumns = ['date', 'message', 'fence', 'userSegment', 'opened', 'read', 'sent'];

  groupSelected = false;
  loading = false;

  constructor(
    public marketingService: MarketingRepositoryService,
    public toastr: ToastrService,
    private currentUser: SessionService
  ) {}

  get currentAdminGroupId() {
    return this.currentUser.currentAdminGroup.groupId;
  }

  ngOnInit() {
    this.currentUser.currentAdminGroup$.pipe(untilDestroyed(this)).subscribe(() => {
      this.getStats();
    });
    this.getStats();
  }

  getStats() {
    this.stats = [];
    const groupId = this.currentAdminGroupId;
    this.groupSelected = !!groupId;
    if (groupId) {
      this.loading = true;
      this.marketingService
        .getPushAnalytics({
          groupId,
          stats: true,
        })
        .subscribe(
          (result) => {
            this.stats = result.results;
            this.loading = false;
          },
          (error) => {
            console.log(error);
            this.loading = false;
            this.toastr.error('Failed to retrieve data.');
          }
        );
    }
  }
}
