import { OnChanges } from '@angular/core';
import { Component, Input, SimpleChanges } from '@angular/core';
import { format, parseISO } from 'date-fns';
import { EChartsOption } from 'echarts';
import { COLOUR_PALETTE, IDataSeriesEntry } from '../constants';
@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnChanges {
  @Input() data: IDataSeriesEntry[];
  @Input() xLabel: string;
  @Input() yLabel: string;

  @Input() chartTitle?: string;
  @Input() chartSubTitle?: string;
  @Input() horizontal?: boolean = undefined;

  options: EChartsOption = {};

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.plotChart(this.preprocessData(changes.data.currentValue));
    }
  }

  preprocessData(data: IDataSeriesEntry[]) {
    return data.map((d) => {
      const date = parseISO(d.name);
      return { name: `${date}`, value: [format(date, 'yyyy/M/d'), d.value] };
    });
  }

  plotChart(data: any) {
    const options: EChartsOption = {
      title: {
        text: this.chartTitle,
        subtext: this.chartSubTitle,
      },
      color: COLOUR_PALETTE,
      grid: {
        left: '2%',
        right: '2%',
        bottom: 70,
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        formatter([params]) {
          const {
            data: { name, value },
            seriesName,
          } = params;
          const date = new Date(name);
          return `${format(date, 'yyyy/M/d')}: ${value[1]} ${seriesName}`;
        },
        axisPointer: {
          animation: false,
        },
      },
      xAxis: {
        type: 'time',
        splitLine: {
          show: true,
        },
      },
      yAxis: {
        type: 'value',
        splitLine: {
          show: false,
        },
      },
      series: [
        {
          name: this.yLabel,
          type: 'line',
          showSymbol: false,
          data,
        },
      ],
      dataZoom: data.length
        ? [
            {
              type: 'inside',
              show: true,
              xAxisIndex: [0],
            },
            {
              type: 'slider',
              show: true,
              xAxisIndex: [0],
            },
          ]
        : [],
    };

    this.options = options;
  }
}
