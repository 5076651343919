<div class="page-content">
  <form [formGroup]="reportsForm" novalidate>
    <app-date-selector
      [hideRangeSelect]="false"
      [parentForm]="reportsForm"
      (dateRangeChanged)="setDateRange($event)"
    ></app-date-selector>
  </form>

  <br />

  <mat-grid-list *ngIf="chartsLoaded; else loading" cols="3" gutterSize="1em" rowHeight="450px">
    <mat-grid-tile [colspan]="2">
      <mat-card class="fill-parent">
        <app-line-chart
          chartTitle="New Users over Time"
          [data]="newUsersGraphData"
          [xLabel]="newUserXLabel"
          [yLabel]="newUserYLabel"
        ></app-line-chart>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-card class="fill-parent">
        <app-pie-chart chartTitle="Gender Split" [data]="genderGraphData"></app-pie-chart>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-card class="fill-parent">
        <app-bar-chart
          [horizontal]="true"
          [data]="nationalityGraphData"
          [xLabel]="nationalityXLabel"
          [yLabel]="nationalityYLabel"
          sortBy="value"
          chartTitle="User Nationality"
        ></app-bar-chart>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-card class="fill-parent">
        <app-bar-chart
          [horizontal]="true"
          [data]="currentCountryGraphData"
          [xLabel]="currentCountryXLabel"
          [yLabel]="currentCountryYLabel"
          sortBy="value"
          chartTitle="User Current Country"
        ></app-bar-chart>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-card class="fill-parent">
        <app-bar-chart
          chartTitle="User Age Brackets"
          [data]="ageGraphData"
          [xLabel]="ageXLabel"
          [yLabel]="ageYLabel"
        ></app-bar-chart>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
  <ng-template #loading>
    <div class="spinner-container">
      <h3>Loading...</h3>
    </div>
  </ng-template>
</div>
