<div class="page-content">
  <app-date-selector [hideRangeSelect]="false" (dateRangeChanged)="setDateRange($event)"></app-date-selector>
  <div *ngIf="!loadingGroupStats">
    <div *ngIf="currentAdminGroupId !== null">
      <h3>Group Stats</h3>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutGap="1em">
        <app-dashboard-card
          fxFlex
          [color]="'green'"
          [statTitle]="'Total Members'"
          [data]="totalMembers"
          [icon]="'group'"
        ></app-dashboard-card>
        <app-dashboard-card
          fxFlex
          [color]="'purple'"
          [statTitle]="'New Members'"
          [data]="newMembers"
          [icon]="'person_add'"
        ></app-dashboard-card>
      </div>
    </div>
  </div>
  <div *ngIf="!loadingFeedStats">
    <div>
      <h3>Feed Stats</h3>
      <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around center" fxLayoutGap="1em">
        <app-dashboard-card
          fxFlex
          [color]="'red'"
          [statTitle]="'New Posts'"
          [data]="newPosts"
          [icon]="'fiber_new'"
        ></app-dashboard-card>
        <app-dashboard-card
          fxFlex
          [color]="'blue'"
          [statTitle]="'New Comments'"
          [data]="newComments"
          [icon]="'comment'"
        ></app-dashboard-card>
        <app-dashboard-card
          fxFlex
          [color]="'green'"
          [statTitle]="'Post Views'"
          [data]="postViews"
          [icon]="'streetview'"
        ></app-dashboard-card>
      </div>
    </div>
  </div>
  <div *ngIf="!loadingNotificationStats">
    <h3>Push Notification Stats</h3>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around center" fxLayoutGap="1em">
      <app-dashboard-card
        fxFlex
        [color]="'purple'"
        [statTitle]="'Push Sent'"
        [data]="totalPushSent"
        [icon]="'arrow_upward'"
      ></app-dashboard-card>
      <app-dashboard-card
        fxFlex
        [color]="'red'"
        [statTitle]="'Push Read'"
        [data]="totalPushRead"
        [icon]="'local_library'"
      ></app-dashboard-card>
      <app-dashboard-card
        fxFlex
        [color]="'blue'"
        [statTitle]="'Push App Open'"
        [data]="totalPushOpen"
        [icon]="'touch_app'"
      ></app-dashboard-card>
    </div>
  </div>
  <div *ngIf="!loadingMarketingStats">
    <h3>Geo Fence Stats</h3>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-around center" fxLayoutGap="1em">
      <app-dashboard-card
        fxFlex
        [color]="'purple'"
        [statTitle]="'Total Fences'"
        [data]="totalRegions"
        [icon]="'scatter_plot'"
      ></app-dashboard-card>
      <app-dashboard-card
        fxFlex
        [color]="'green'"
        [statTitle]="'Fences Entered'"
        [data]="regionEnters"
        [icon]="'arrow_forward'"
      ></app-dashboard-card>
      <app-dashboard-card
        fxFlex
        [color]="'red'"
        [statTitle]="'Fences Exited'"
        [data]="regionExits"
        [icon]="'arrow_back'"
      ></app-dashboard-card>
    </div>
  </div>

  <div
    *ngIf="loadingFeedStats || loadingNotificationStats || loadingMarketingStats || loadingGroupStats"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <p>Loading...</p>
    <div class="spinner-container">
      <mat-spinner [diameter]="20" [strokeWidth]="4"></mat-spinner>
    </div>
  </div>
</div>
