import { AccessPipeGenerator } from './access-guard';
import { AccessLevel } from './session.store';

export const groupOnly: AccessPipeGenerator = (next, state) => async ({ currentAdminGroup }) => currentAdminGroup.id !== null;

export const atLeastGroupAdmin: AccessPipeGenerator = () => async ({ accessLevel }) =>
  accessLevel >= AccessLevel.GROUP_ADMIN || ['logout'];

export const fullAdminOnly: AccessPipeGenerator = (next, state) => async ({ accessLevel }) => accessLevel >= AccessLevel.FULL_ADMIN;

export const fullAdminOrMyself: AccessPipeGenerator = ({ params: { id } }) => async ({ currentUser, accessLevel }) =>
  !!currentUser && (accessLevel >= AccessLevel.FULL_ADMIN || currentUser.id === id);
