import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { RewardServicePromiseClient } from '@travello/grpc-api/js/reward_grpc_web_pb';
import { LeaderBoardItem, LeaderBoardRequest } from '@travello/grpc-api/js/reward_pb';
import { AppConfigService } from '@/app/config/app-config.service';
import { SessionService } from '@/app/core/auth/session.service';

@Injectable()
export class LeaderBoardResolver implements Resolve<Promise<Array<LeaderBoardItem>>> {
  client: RewardServicePromiseClient;

  constructor(private appConfigService: AppConfigService, private currentUserService: SessionService) {
    const config = this.appConfigService.appConfig;
    this.client = new RewardServicePromiseClient(config.grpcUrl);
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const request = new LeaderBoardRequest();
    switch (route.paramMap.get('style')) {
      case 'weekly': {
        request.setDateWindow(1); // at the moment the LeaderBoardRequest.DateWindow values compile, but are 'undefined' at runtime
        break;
      }
      case 'all': {
        request.setDateWindow(0);
        break;
      }
      default: {
        console.error('Unexpected leaderboard style: ' + route.paramMap.get('style'));
        return [];
      }
    }

    const response = await this.client.leaderBoard(request, this.currentUserService.authHeader);
    return response.getTopItemsList();
  }
}

export { LeaderBoardItem };
