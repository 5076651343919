<div>
  <br />

  <div *ngIf="!loading" class="mat-elevation-z8 table-div">
    <mat-table #table [dataSource]="triggers">
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      <ng-container matColumnDef="fence">
        <mat-header-cell fxFlex *matHeaderCellDef> Fence Name </mat-header-cell>
        <mat-cell fxFlex *matCellDef="let element">
          <div *ngIf="element.marketingLocationName">
            {{ element.marketingLocationName }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="userSegment">
        <mat-header-cell fxFlex *matHeaderCellDef> User Segment </mat-header-cell>
        <mat-cell fxFlex *matCellDef="let element">
          <div *ngIf="element.marketingUserSegmentName">
            {{ element.marketingUserSegmentName }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="template">
        <mat-header-cell fxFlex *matHeaderCellDef> Template Name </mat-header-cell>
        <mat-cell fxFlex *matCellDef="let element">
          <div *ngIf="element.marketingMessageTemplateName">
            {{ element.marketingMessageTemplateName }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="eventType">
        <mat-header-cell fxFlex *matHeaderCellDef> Event Type </mat-header-cell>
        <mat-cell fxFlex *matCellDef="let element">
          <div *ngIf="element.eventType">
            {{ element.eventType }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="coolOff">
        <mat-header-cell fxFlex *matHeaderCellDef> Cool Off Days </mat-header-cell>
        <mat-cell fxFlex *matCellDef="let element">
          <div *ngIf="element.coolOffDays">
            {{ element.coolOffDays }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell fxFlex *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell fxFlex *matCellDef="let element">
          <div fxLayout="row" fxLayoutGap="2em">
            <button mat-raised-button color="warn" (click)="openConfirmationDialog(element)">Delete</button>
          </div>
        </mat-cell>
      </ng-container>
    </mat-table>
  </div>

  <br />
  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
    <p>Loading...</p>
    <div class="spinner-container">
      <mat-spinner [diameter]="20" [strokeWidth]="4"></mat-spinner>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <p *ngIf="triggers.length === 0 && !loading">You don't have any geo triggers set up.</p>
  </div>
  <br />
</div>
