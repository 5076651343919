import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ITemplate } from '@/app/services/repositories/marketing-repository.service';

/*
  A service to allow me pass an ITemplate object from the your-templates page to the create-template page for editing
*/

@Injectable({ providedIn: 'root' })
export class EditTemplateService {
  public $template: BehaviorSubject<ITemplate> = new BehaviorSubject<ITemplate>(null);
}
