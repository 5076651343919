import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IMarketingLocation } from '@/app/services/repositories/marketing-repository.service';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// SVG icons for map
const PLANE_SVG =
  'm2,106h28l24,30h72l-44,-133h35l80,132h98c21,0 21,34 0,34l-98,0 -80,134h-35l43,-133h-71l-24,30h-28l15,-47';
const TARGET_SVG =
  'M9,0C4.029,0,0,4.029,0,9s4.029,9,9,9s9-4.029,9-9S13.971,0,9,0z M9,15.93 c-3.83,0-6.93-3.1-6.93-6.93S5.17,2.07,9,2.07s6.93,3.1,6.93,6.93S12.83,15.93,9,15.93 M12.5,9c0,1.933-1.567,3.5-3.5,3.5S5.5,10.933,5.5,9S7.067,5.5,9,5.5 S12.5,7.067,12.5,9z';

@Component({
  selector: 'app-journey-map',
  templateUrl: './journey-map.component.html',
  styleUrls: ['./journey-map.component.scss'],
})
export class JourneyMapComponent implements OnDestroy {
  private _locations: IMarketingLocation[] = [];

  @Input()
  set locations(l: IMarketingLocation[]) {
    this._locations = l;
    this.prepareAndLoadChart();
  }

  get locations() {
    return this._locations;
  }

  chart: any;

  constructor() {}

  ngOnDestroy() {
    if (this.chart) {
      this.chart.clear();
    }
  }

  prepareAndLoadChart() {
    if (this.chart) {
      this.chart.clear();
    }
    const images = [];
    const lines = [];
    const animations = [];

    let firstLocation;

    const lineLat = [];
    const lineLong = [];

    const line = {
      id: 'line1',
      arc: -0.85,
      alpha: 0.3,
      latitudes: lineLat,
      longitudes: lineLong,
    };
    lines.push(line);

    const animation = {
      svgPath: PLANE_SVG,
      positionOnLine: 0,
      color: '#000000',
      alpha: 0.1,
      animateAlongLine: true,
      lineId: 'line1',
      flipDirection: false,
      loop: true,
      scale: 0.03,
      positionScale: 1.3,
    };
    animations.push(animation);

    for (let i = 0; i < this.locations.length; i++) {
      const location = this.locations[i];

      lineLat.push(location.latitude);
      lineLong.push(location.longitude);

      if (!firstLocation) {
        firstLocation = location;
      }

      const image = {
        svgPath: TARGET_SVG,
        title: location.city,
        latitude: location.latitude,
        longitude: location.longitude,
      };
      images.push(image);
    }

    if (firstLocation && lines && images && animations) {
      this.chart = AmCharts.makeChart('chartdiv', {
        type: 'map',
        theme: 'light',
        dataProvider: {
          map: 'worldLow',
          zoomLevel: 6,
          zoomLongitude: firstLocation.longitude,
          zoomLatitude: firstLocation.latitude,
          lines,
          images: images.concat(animations),
        },
        areasSettings: {
          unlistedAreasColor: '#8dd9ef',
        },
        imagesSettings: {
          color: '#585869',
          rollOverColor: '#585869',
          selectedColor: '#585869',
          pauseDuration: 0.2,
          animationDuration: 2.5,
          adjustAnimationSpeed: false,
        },
        linesSettings: {
          color: '#585869',
          alpha: 0.4,
        },
        export: {
          enabled: true,
        },
      });
    }
  }
}
