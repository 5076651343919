import { canActivateWithAccessGuard } from '@/app/core/auth/access-guard';
import { atLeastGroupAdmin, fullAdminOrMyself } from '@/app/core/auth/auth-pipes';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LeaderBoardPageComponent } from './leader-board-page.component';
import { LeaderBoardComponent } from './pages/leader-board.component';
import { LeaderBoardResolver } from './pages/leader-board.resolver';
import { StatusTransactionsResolver } from './pages/status-transactions.resolver';
import { TransactionsComponent } from './pages/transactions.component';

const routes: Routes = [
  {
    path: 'leaderboard',
    component: LeaderBoardPageComponent,
    ...canActivateWithAccessGuard(atLeastGroupAdmin),
    children: [
      {
        path: ':style',
        component: LeaderBoardComponent,
        resolve: {
          leaderboard: LeaderBoardResolver,
        },
      },
      { path: '', redirectTo: 'weekly', pathMatch: 'full' },
    ],
  },
  {
    path: 'user/:id/:name',
    component: TransactionsComponent,
    ...canActivateWithAccessGuard(fullAdminOrMyself),
    resolve: {
      transactions: StatusTransactionsResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [LeaderBoardResolver, StatusTransactionsResolver],
})
export class LeaderBoardPageRoutingModule {}
