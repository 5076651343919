<div *ngIf="image" class="photo-preview-card">
  <div class="card-image-container">
    <img class="card-image" [src]="image.srcString" />
  </div>
  <div class="card-info-container">
    <p *ngIf="image.fileName" class="card-info">{{ image.fileName }}</p>
    <p *ngIf="image.fileSizeInKb" class="card-info">{{ image.fileSizeInKb | number: '1.0-0' }}kb</p>
  </div>
  <button mat-button type="button" (click)="deleteClicked.emit(true)">DELETE</button>
</div>
