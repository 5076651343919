import { Component, Input } from '@angular/core';
import { IChatDetails } from '@/app/services/repositories/user-repository.service';

@Component({
  selector: 'app-chat-card',
  templateUrl: './chat-card.component.html',
  styleUrls: ['./chat-card.component.scss'],
})
export class ChatCardComponent {
  @Input()
  chat: IChatDetails;
}
