import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@/app/shared.module';
import { MatSelectModule } from '@angular/material/select';
import { BookingQueryComponent } from '@/app/pages/booking/booking-query.component';
import { BookingQueryRoutingModule } from '@/app/pages/booking/booking-query-routing.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [BookingQueryComponent],
  imports: [CommonModule, SharedModule, BookingQueryRoutingModule, MatSelectModule, MatToolbarModule, MatTableModule],
})
export class BookingQueryModule {}
