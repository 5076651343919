import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import { sortBy } from 'lodash';
import { IDataSeriesEntry, COLOUR_PALETTE } from '../constants';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnChanges {
  @Input() chartTitle?: string;
  @Input() chartSubTitle?: string;
  @Input() sortBy?: keyof IDataSeriesEntry = undefined;
  @Input() horizontal?: boolean = false;

  @Input() data: IDataSeriesEntry[];
  @Input() xLabel: string;
  @Input() yLabel: string;

  options: EChartsOption = {};

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.plotChart(this.preprocessData(changes.data.currentValue));
    }
  }

  preprocessData(data: IDataSeriesEntry[]) {
    if (this.sortBy) {
      return sortBy(data, [(o) => o[this.sortBy]]);
    }
    return data;
  }

  plotChart(data: any) {
    const categories = data.map((v) => v.name);
    const values = data.map((v, i) => ({
      value: v.value,
      itemStyle: { color: COLOUR_PALETTE[i % COLOUR_PALETTE.length] },
    }));

    const options: EChartsOption = {
      title: {
        text: this.chartTitle,
        subtext: this.chartSubTitle,
      },
      grid: {
        left: '2%',
        right: '2%',
        bottom: '2%',
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      series: [
        {
          type: 'bar',
          data: values,
          animationDelay: (idx) => idx * 10,
        },
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: (idx) => idx * 5,
    };

    const enableZoom = this.sortBy === 'value' && categories.length > 10;
    // zoom to first 10 if there are too many bars
    const zoomEnd = (10 / categories.length) * 100;

    if (this.horizontal) {
      // horizontal
      options.xAxis = {
        type: 'value',
        boundaryGap: [0, 0.01],
      };
      options.yAxis = {
        type: 'category',
        data: categories,
      };
      if (enableZoom) {
        options.grid = {
          left: '2%',
          right: 70,
          bottom: '2%',
          containLabel: true,
        };
        options.dataZoom = [
          {
            type: 'inside',
            show: true,
            yAxisIndex: [0],
            start: 100 - zoomEnd,
            end: 100,
          },
          {
            type: 'slider',
            show: true,
            yAxisIndex: [0],
          },
        ];
      }
    } else {
      options.xAxis = {
        type: 'category',
        data: categories,
      };
      options.yAxis = {
        type: 'value',
        boundaryGap: [0, 0.01],
      };
      if (enableZoom) {
        options.grid = {
          left: '2%',
          right: '2%',
          bottom: 70,
          containLabel: true,
        };

        options.dataZoom = [
          {
            type: 'inside',
            show: true,
            xAxisIndex: [0],
            start: 0,
            end: zoomEnd,
          },
          {
            type: 'slider',
            show: true,
            xAxisIndex: [0],
          },
        ];
      }
    }

    this.options = options;
  }
}
