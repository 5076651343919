import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@/app/shared.module';
import { ContactUsModalComponent } from './modals/contact-us-modal/contact-us-modal.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { SidenavComponent } from './sidenav/sidenav.component';

@NgModule({
  declarations: [ToolbarComponent, ContactUsModalComponent, SidenavComponent],
  imports: [CommonModule, SharedModule],
  exports: [ToolbarComponent, SidenavComponent],
})
export class ToolbarModule {}
