import { Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { Subject } from 'rxjs';
import { FeedsRepositoryService, IFeedComment } from '@/app/services/repositories/feeds-repository.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
@Component({
  selector: 'app-feed-comments-page',
  templateUrl: './comments-page.component.html',
  styleUrls: ['./comments-page.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FeedCommentsPageComponent implements OnInit {
  // comments: IFeedComment[] = [];
  displayedColumns = ['comment', 'postedByName', 'posted'];

  expandedElement: IFeedComment | null;

  loading = false;

  searchTextChanged = new Subject<string>();

  searchText: string | null;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource = new MatTableDataSource<IFeedComment>([]);

  constructor(
    public feedService: FeedsRepositoryService,
    public location: Location,
    public activatedRoute: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit() {
    this.sort.sort({ id: 'posted', start: 'desc', disableClear: false });
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.searchTextChanged
      .pipe(debounceTime(300))
      .pipe(distinctUntilChanged())
      .subscribe((val) => {
        if (typeof val === 'string' && val.length >= 3) {
          this.searchComments(val);
        }
      });

    this.searchTextChanged.pipe(distinctUntilChanged()).subscribe((val) => {
      this.changeSearchUrl(val);
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      this.searchText = params['text'] || null;
      this.searchTextChanged.next(this.searchText);
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  changeSearchText(input) {
    const value = input.currentTarget.value;
    // need type check to avoid passing an IUser object when user selected and causing an error
    if (typeof value === 'string') {
      this.searchTextChanged.next(value);
    } else {
      this.dataSource.data = [];
    }
  }

  changeSearchUrl(value: string) {
    const queryParams = value ? { text: value } : undefined;
    const url = this.router.createUrlTree([], { relativeTo: this.activatedRoute, queryParams }).toString();

    this.location.go(url);
  }

  searchComments(value: string) {
    this.loading = true;
    this.feedService
      .searchFeedComments({
        text: value,
      })
      .subscribe(
        (result) => {
          this.loading = false;
          this.dataSource.data = result;
        },
        () => {
          this.loading = false;
        }
      );
  }
}
