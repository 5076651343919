<mat-toolbar color="primary">
  <span>User</span>
</mat-toolbar>

<div class="page-content user-profile-container">
  <!-- https://toddmotto.com/angular-ngif-async-pipe -->
  <div *ngIf="user$ | async as user; else loading">
    <div class="profile-actions">
      <button mat-raised-button routerLink="/chat/{{ userId }}" color="primary">Message</button>
      <button *ngIf="!user.metaData.verified" mat-raised-button (click)="verifyUser(true)" color="primary">
        Verify User
      </button>
      <button *ngIf="user.metaData.verified" mat-raised-button (click)="verifyUser(false)" color="primary">
        Unverify User
      </button>
      <button *ngIf="!user.deactive" mat-raised-button (click)="deactivateUser(true)" color="primary">
        Deactivate User
      </button>
      <button *ngIf="user.deactive" mat-raised-button (click)="deactivateUser(false)" color="primary">
        Reactivate User
      </button>
      <button *ngIf="!user.deactive" mat-raised-button (click)="blacklistUser()" color="primary">Blacklist User</button>
      <button *ngIf="user.deactive" mat-raised-button (click)="removeAllPosts()" color="primary">
        Remove All Feed Items
      </button>
      <button *ngIf="!user.deactive" mat-raised-button (click)="reportSpammer()" color="primary">Report Spammer</button>
      <button mat-raised-button (click)="gdprRemove()" color="primary">GDPR Remove</button>
      <button
        *ngIf="user.admin !== undefined && !user.admin"
        mat-raised-button
        (click)="grantUserAdmin(true)"
        color="primary"
      >
        Grant Admin Access
      </button>
      <button
        *ngIf="user.admin !== undefined && user.admin"
        mat-raised-button
        (click)="grantUserAdmin(false)"
        color="primary"
      >
        Revoke Admin Access
      </button>
      <button mat-raised-button routerLink="/changeCredit/{{ userId }}" color="primary">ChangeCredit</button>

      <span class="invisible-spacer"></span>
      <span class="invisible-spacer"></span>
      <span class="invisible-spacer"></span>
      <!-- This is a hack to stop my layout getting fucked by the ng-star-inserted element that ngif inserts into page -->
      <span class="invisible-spacer"></span>
      <span class="invisible-spacer"></span>
      <span class="invisible-spacer"></span>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around start" fxLayoutGap="2em">
      <div class="profile-information box-shadow">
        <div class="profiler">

          <div *ngIf="user.blacklistDescription !== undefined">
            <img
              class="blacklisted-profile-pic"
              [src]="user.profileImage !== null ? user.profileImage : logoPng"
              alt="Profile Image"
            />
          </div>

          <div *ngIf="user.blacklistDescription === undefined">
            <img
              class="profile-pic"
              [src]="user.profileImage !== null ? user.profileImage : logoPng"
              alt="Profile Image"
            />
          </div>
          <div class="user-name">
            {{ user.userName }}
          </div>
        </div>
        <div class="information" fxLayout="column" fxLayoutAlign="space-around center">
          <div fxFlex class="information-block">
            <div *ngIf="user.dateOfBirth" class="piece-of-info">
              Date of Birth: {{ user.dateOfBirth | date: 'mediumDate' }}
            </div>
            <div *ngIf="user.gender" class="piece-of-info">Gender: {{ user.gender }}</div>
            <div *ngIf="user.currentCity" class="piece-of-info">Current City: {{ user.currentCity }}</div>
            <div *ngIf="user.currentCountry" class="piece-of-info">Current Country: {{ user.currentCountry }}</div>
            <div *ngIf="user.nationality" class="piece-of-info">Nationality: {{ user.nationality }}</div>
            <div *ngIf="user.email" class="piece-of-info">Email: {{ user.email }}</div>
            <div *ngIf="user.createdAt" class="piece-of-info">CreatedAt: {{ user.createdAt | slice:0:10 }}</div>
            <div *ngIf="creditSummary.getCreditPoints()" class="piece-of-info">CreditPoints: {{ creditSummary.getCreditPoints() }}</div>
            <div *ngIf="creditSummary.getCreditPointsIncPending()" class="piece-of-info">PendingCreditPoints: {{ creditSummary.getCreditPointsIncPending()  - creditSummary.getCreditPoints()}}</div>
            <div *ngIf="user.blacklistDescription !== undefined" class="piece-of-info" style="color: red">Blacklisted User: {{ user.blacklistDescription }}</div>
          </div>
          <div fxFlex class="user-stats">
            <div fxLayout="row" fxLayoutAlign="space-around center">
              <div class="user-stat" fxLayout="column">
                <div class="user-stat-number">{{ trips.length }}</div>
                <div class="user-stat-title">Trips</div>
              </div>
              <div class="user-stat" fxLayout="column">
                <div class="user-stat-number">{{ posts.length }}</div>
                <div class="user-stat-title">Posts</div>
              </div>
              <div class="user-stat" fxLayout="column">
                <div class="user-stat-number">{{ friendsTotal }}</div>
                <div class="user-stat-title">Friends</div>
              </div>
            </div>
          </div>
          <div fxFlex class="profile-interests" *ngIf="interests.length > 0">
            <span
              class="profile-interest-chip"
              [class.few-interests]="interests.length < 5"
              [class.medium-interests]="interests.length >= 5 && interests.length < 8"
              [class.so-many-interests]="interests.length >= 8"
              *ngFor="let interest of interests"
            >
              {{ interest.displayName }}
            </span>
          </div>
        </div>
      </div>
      <div fxFlex>
        <div class="profile-location-history box-shadow">
          <app-journey-map [locations]="filteredLocations"></app-journey-map>
        </div>
      </div>
    </div>

    <div class="profile-feed-posts">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>view_list</mat-icon>
            </mat-panel-title>
            <mat-panel-description> Feed History</mat-panel-description>
          </mat-expansion-panel-header>
          <div class="feed-posts">
            <div fxLayout="row">
              <div fxFlex></div>
              <div fxLayout="column" fxLayoutGap="0.5em">
                <app-feed-card *ngFor="let post of posts" [cardInfo]="post" (deletePost)="deletePost($event)">
                </app-feed-card>
              </div>
              <div fxFlex></div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="profile-chat-history">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>message</mat-icon>
            </mat-panel-title>
            <mat-panel-description> Messaging History</mat-panel-description>
          </mat-expansion-panel-header>
          <div class="messages">
            <div fxLayout="row">
              <div fxFlex></div>
              <div fxLayout="column" fxLayoutGap="0.5em">
                <ul>
                  <li class="no-bullet" *ngFor="let chat of chats">
                    <app-chat-card [chat]="chat"></app-chat-card>
                  </li>
                </ul>
              </div>
              <div fxFlex></div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="profile-trips">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>airplanemode_active</mat-icon>
            </mat-panel-title>
            <mat-panel-description> Trips</mat-panel-description>
          </mat-expansion-panel-header>
          <div class="trips">
            <div fxLayout="row">
              <div fxFlex></div>
              <div fxLayout="column" fxLayoutGap="0.5em">
                <div *ngFor="let trip of trips">
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div>
                      <span *ngIf="trip.city">{{ trip.city }}</span>
                      <span *ngIf="trip.country">{{ trip.country }}</span>
                    </div>
                    <div fxFlex="5"></div>
                    <div>
                      <span *ngIf="trip.fromDate">{{ trip.fromDate | date: 'mediumDate' }}</span>
                      <span *ngIf="trip.toDate">-{{ trip.toDate | date: 'mediumDate' }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div fxFlex></div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="profile-credit-point-history">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>credit_card</mat-icon>
            </mat-panel-title>
            <mat-panel-description> Credit Point History</mat-panel-description>
          </mat-expansion-panel-header>

      <table mat-table [dataSource]="creditPointRecords" class="mat-elevation-z8" width="100%">
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let creditCecord"> {{ creditCecord.getSubType() }}</td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>Value</th>
          <td mat-cell *matCellDef="let creditCecord"> {{ creditCecord.getValue() }}</td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef>CreatedAt</th>
          <td mat-cell *matCellDef="let creditCecord">
            <div *ngIf="creditCecord.getCreatedAt()">
              {{ creditCecord.getCreatedAt().getSeconds()*1000 | date: 'dd-MM-yyyy' }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="settlementDate">
          <th mat-header-cell *matHeaderCellDef>SettlementDate</th>
          <td mat-cell *matCellDef="let creditCecord"> {{ creditCecord.getSettlementDate() | date: 'dd-MM-yyyy' }}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let creditCecord"> {{ creditCecord.getDescription() }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="creditPointsColumnsToDisplay"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: creditPointsColumnsToDisplay"></tr>
      </table>
          <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>

        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="profile-referred-users">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>people</mat-icon>
            </mat-panel-title>
            <mat-panel-description>Referred Users</mat-panel-description>
          </mat-expansion-panel-header>

          <mat-action-row>
            <div fxLayout="column">
              <div fxLayout="row">
                <form [formGroup]="resetRewardFormGroup"
                      (ngSubmit)="resetRewardFormGroup.valid && resetRewardPointsSelectedUsers()"
                      fxLayout="row"
                      fxLayout.lt-md="column"
                      fxLayoutAlign="space-evenly center"
                      fxLayoutGap="1vw"
                >
                  <mat-form-field>
                    <textarea matInput placeholder="Type a description" formControlName="description"></textarea>
                  </mat-form-field>
                  <button style="width:200px" mat-raised-button  title="reset reward points to zero for selected users" color="primary" type="submit" [disabled]="!resetRewardFormGroup.valid">ResetRewardPoints</button>
                </form>
              </div>
              <div fxLayout="row">
                <form [formGroup]="blacklistUserFormGroup"
                      (ngSubmit)="blacklistUserFormGroup.valid && blackListSelectedUsers()"
                      fxLayout="row"
                      fxLayout.lt-md="column"
                      fxLayoutAlign="space-evenly center"
                      fxLayoutGap="1vw"
                >
                  <mat-form-field>
                    <textarea matInput placeholder="Type a description" formControlName="description"></textarea>
                  </mat-form-field>
                  <button style="width:200px" mat-raised-button title="blacklist selected users" color="primary" type="submit" [disabled]="!blacklistUserFormGroup.valid">BlackList</button>
                </form>
              </div>
            </div>
          </mat-action-row>

          <table mat-table [dataSource]="referredUsers" class="mat-elevation-z8" width="100%">
            <ng-container matColumnDef="selection">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                              [checked]="referredUsersSelection.hasValue() && isAllSelected()"
                              [indeterminate]="referredUsersSelection.hasValue() && !isAllSelected()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let referredUser">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? referredUsersSelection.toggle(referredUser) : null"
                              [checked]="referredUsersSelection.isSelected(referredUser)">
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="userName">
              <th mat-header-cell *matHeaderCellDef>User Name</th>
              <td mat-cell *matCellDef="let referredUser"> {{ referredUser.user.userName }}</td>
            </ng-container>

            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef>Email</th>
              <td mat-cell *matCellDef="let referredUser"> {{ referredUser.user.email }}</td>
            </ng-container>

            <ng-container matColumnDef="dateOfBirth">
              <th mat-header-cell *matHeaderCellDef>Date of Birth</th>
              <td mat-cell *matCellDef="let referredUser"> {{ referredUser.user.dateOfBirth }}</td>
            </ng-container>

            <ng-container matColumnDef="createdAt">
              <th mat-header-cell *matHeaderCellDef>Created At</th>
              <td mat-cell *matCellDef="let referredUser"> {{ referredUser.user.createdAt }}</td>
            </ng-container>

            <ng-container matColumnDef="deactive">
              <th mat-header-cell *matHeaderCellDef>Deactive</th>
              <td mat-cell *matCellDef="let referredUser"> {{ referredUser.user.deactive }}</td>
            </ng-container>

            <ng-container matColumnDef="spammer">
              <th mat-header-cell *matHeaderCellDef>Spammer</th>
              <td mat-cell *matCellDef="let referredUser"> {{ referredUser.user.spammer }}</td>
            </ng-container>

            <ng-container matColumnDef="creditPoints">
              <th mat-header-cell *matHeaderCellDef>Credit Points</th>
              <td mat-cell *matCellDef="let referredUser"> {{ referredUser.creditPoints }}</td>
            </ng-container>

            <ng-container matColumnDef="blacklisted">
              <th mat-header-cell *matHeaderCellDef>Blacklisted</th>
              <td mat-cell *matCellDef="let referredUser"> {{ referredUser.user.blacklistDescription !== undefined }}</td>
            </ng-container>

            <ng-container matColumnDef="blacklistReason">
              <th mat-header-cell *matHeaderCellDef>Blacklist Reason</th>
              <td mat-cell *matCellDef="let referredUser"> {{ referredUser.user.blacklistDescription }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="referredUsersColumnsToDisplay"></tr>
            <tr mat-row *matRowDef="let referredUser; columns: referredUsersColumnsToDisplay"  (click)="showUserInNewWindow(referredUser.user.id)" ></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>

        </mat-expansion-panel>
      </mat-accordion>
    </div>

  </div>

  <ng-template #loading>
    <div fxLayout="row">
      <div fxFlex></div>
      <div class="spinner-container">
        <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
      </div>
      <div fxFlex></div>
    </div>
  </ng-template>
</div>
