<google-map
  [width]="'100%'"
  [height]="500"
  [center]="{ lat: latitude, lng: longitude }"
  [options]="{ scrollwheel: true }"
  [zoom]="zoom"
>
  <map-circle [options]="circleOptions" [center]="{ lat: latitude, lng: longitude }" [radius]="radius"> </map-circle>
</google-map>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Close</button>
</mat-dialog-actions>
