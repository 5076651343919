import { Component, Input, OnInit } from '@angular/core';
import { IFeedComment } from '@/app/services/repositories/feeds-repository.service';

@Component({
  selector: 'app-comment-card',
  templateUrl: './comment-card.component.html',
  styleUrls: ['./comment-card.component.scss'],
})
export class CommentCardComponent implements OnInit {
  @Input()
  cardInfo: IFeedComment;

  constructor() {}

  ngOnInit() {}
}
