<br />
<br />
<div fxLayout="row" fxLayoutGap="3em" fxLayoutAlign="center center">
  <app-mobile-preview>
    <div
      *ngIf="!croppedPhoto"
      class="pic-button"
      fxLayout="column"
      fxLayoutAlign="center center"
      (click)="hiddenPhotoInput.click()"
    >
      <mat-icon class=" bigger-icon">add_photo_alternate</mat-icon>
      <p class="select-image">Select Image</p>
    </div>
    <div *ngIf="croppedPhoto">
      <img [src]="croppedPhoto.srcString" class="pic-button" (click)="hiddenPhotoInput.click()" />
    </div>
    <input
      #hiddenPhotoInput
      hidden
      [multiple]="false"
      name="file"
      type="file"
      accept=".jpg,.png"
      (change)="onPhotoChange($event)"
    />
    <h2 #title class="title editable" placeholder="Enter title here..." contentEditable="true"></h2>
    <p #text class="text editable" placeholder="Enter text here..." contentEditable="true"></p>
    <div [style]="buttonContainerStyle">
      <a
        [ngClass]="{ hidden: templateForm.get('hideButton').value === true }"
        #button
        class="editable"
        [style]="buttonStyle(color)"
        placeholder="Button text here..."
        contentEditable="true"
        onkeypress="return (this.innerText.length <= 20)"
      ></a>
    </div>
  </app-mobile-preview>
  <form [formGroup]="templateForm" (ngSubmit)="saveTemplate()">
    <mat-form-field class="fill-parent">
      <input type="text" formControlName="templateName" matInput placeholder="Template Name" maxlength="100" required />
      <mat-error>You must enter a name for your template</mat-error>
    </mat-form-field>
    <mat-form-field hintLabel="Entice your customers with short punchy message" class="fill-parent">
      <input type="text" formControlName="pushMessage" matInput placeholder="Push Message" maxlength="100" required />
      <mat-error>You must enter a push message</mat-error>
    </mat-form-field>
    <br />
    <br />
    <div fxLayout="row">
      <div fxFlex></div>
      <mat-checkbox (click)="fillDummyUrl()" formControlName="hideButton"></mat-checkbox
      ><span class="show-hide-label">Hide Button</span>
      <div fxFlex></div>
    </div>

    <div [ngClass]="{ hidden: templateForm.get('hideButton').value === true }">
      <div fxLayout="row" fxLayoutGap="0.8em">
        <mat-form-field
          hintLabel="When a customer presses the button they will be sent to this url e.g. http://www.google.com"
          class="fill-parent"
        >
          <input type="text" formControlName="url" matInput placeholder="Button Url" maxlength="100" required />
          <mat-error>You must enter a url</mat-error>
        </mat-form-field>
        <!-- https://github.com/zefoy/ngx-color-picker -->
        <input
          fxFlex="18"
          class="button-color-picker"
          [(colorPicker)]="color"
          [style.background]="color"
          [value]="color"
          [cpPosition]="'left'"
        />
      </div>
      <div class="urlTester" *ngIf="templateForm.get('url').value.length > 0">
        <h4 class="urlTesterHeading">Url Tester</h4>
        <p class="urlTesterText">
          You must enter a valid url or the button in the message wont work.
          <br />
          Tip: <b>Must start with http:// or https://</b>
          <br />
          Tip: Open your link in another tab and copy/paste it in to avoid typos
          <br />
          <br />
          Click below to test your url. This will open a new tab. If your URL is valid it will open. If your URL is
          incorrect it will just open the current page.
          <br />
        </p>
        <div class="urlTesterLink">
          <a [href]="templateForm.get('url').value" target="_blank">Click here to test your url</a>
        </div>
      </div>
    </div>

    <br />
    <br />
    <div fxLayout="row" fxLayoutGap="0.5em">
      <div fxFlex></div>
      <button
        mat-raised-button
        color="accent"
        type="submit"
        [disabled]="!templateForm.valid || !isTemplateValid() || loading"
        class="centred-button"
      >
        Save Template
      </button>
      <div class="spinner-container">
        <mat-spinner [diameter]="20" [strokeWidth]="4" *ngIf="loading"></mat-spinner>
      </div>
      <div fxFlex></div>
    </div>
  </form>
</div>
