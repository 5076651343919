import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@/app/shared.module';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { DemographicsComponent } from './demographics/demographics.component';
import { InsightsRoutingModule } from './insights-routing.mobule';
import { MessageAnalyticsComponent } from './message-analytics/message-analytics.component';
import { NearbyPageComponent } from './nearby-page/nearby-page.component';
import { DashboardCardComponent } from './dashboard-page/sub-sections/dashboard-card/dashboard-card.component';
import { MapSettingsDialogComponent } from './nearby-page/sub-sections/map-settings-dialog/map-settings-dialog.component';

@NgModule({
  declarations: [
    NearbyPageComponent,
    MessageAnalyticsComponent,
    DemographicsComponent,
    DashboardPageComponent,
    DashboardCardComponent,
    MapSettingsDialogComponent,
  ],
  imports: [CommonModule, InsightsRoutingModule, SharedModule],
})
export class InsightsModule {}
