import { Injectable, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SessionService } from '../core/auth/session.service';
import { FeedsRepositoryService } from './repositories/feeds-repository.service';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class NumberReportedService {
  private numReportedSrc = new BehaviorSubject<number>(0);

  get numReported() {
    return this.numReportedSrc.getValue();
  }

  overrideNumReported(newNumReported: number) {
    this.numReportedSrc.next(newNumReported);
  }

  decreaseNumReported() {
    this.numReportedSrc.next(this.numReported - 1);
  }

  constructor(private currentUser: SessionService, private feedsRepository: FeedsRepositoryService) {
    combineLatest([this.currentUser.currentAdminGroup$, this.currentUser.currentUser$])
      .pipe(
        switchMap(([{ groupId }, user]) => {
          if (user) {
            return this.feedsRepository
              .getAdminFeed({
                groupId,
                reportedOnly: true,
              })
              .pipe(map((res) => res.results.length));
          }
          return of(0);
        }),
        untilDestroyed(this)
      )
      .subscribe((n) => {
        this.numReportedSrc.next(n);
      });
  }
}
