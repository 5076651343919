import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@/app/shared.module';
import { FeedPageRoutingModule } from './feed-page-routing.module';
import { FeedPageComponent } from './feed-page.component';
import { PinToTopModalComponent } from './modals/pin-to-top-modal/pin-to-top-modal.component';

@NgModule({
  declarations: [FeedPageComponent, PinToTopModalComponent],
  imports: [CommonModule, FeedPageRoutingModule, SharedModule],
})
export class FeedPageModule {}
