<div class="search-card">
  <div>
    <div id="title">Address search</div>
  </div>
  <div id="search-card-container">
    <input
      placeholder="Search for address"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="off"
      type="text"
      id="search-card-input"
      #search
    />
  </div>
</div>
