import { Component } from '@angular/core';

@Component({
  selector: 'app-usersegments-page',
  templateUrl: './usersegments-page.component.html',
  styleUrls: ['./usersegments-page.component.scss'],
})
export class UsersegmentsPageComponent {
  navLinks: MenuItem[] = [
    { url: './list', label: 'Your User Segments' },
    { url: './create', label: 'Create User Segment' },
  ];

  constructor() {}
}
