import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ITrigger, MarketingRepositoryService } from '@/app/services/repositories/marketing-repository.service';
import { Router } from '@angular/router';
import { SessionService } from '@/app/core/auth/session.service';
import { PopupsService } from '@/app/services/popups.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-your-geo-triggers',
  templateUrl: './your-geo-triggers.component.html',
  styleUrls: ['./your-geo-triggers.component.scss'],
})
export class YourGeoTriggersComponent implements OnInit {
  triggers: ITrigger[] = [];

  displayedColumns = ['fence', 'userSegment', 'template', 'eventType', 'coolOff', 'actions'];

  loading = false;

  constructor(
    private router: Router,
    private marketingService: MarketingRepositoryService,
    private currentUser: SessionService,
    private toastr: ToastrService,
    private popups: PopupsService
  ) {}

  get currentAdminGroupId() {
    return this.currentUser.currentAdminGroup.groupId;
  }

  ngOnInit() {
    this.currentUser.currentAdminGroup$.pipe(untilDestroyed(this)).subscribe(() => {
      this.getGeoTriggers();
    });
    this.getGeoTriggers();
  }

  getGeoTriggers() {
    this.loading = true;
    this.marketingService
      .getGeoTriggers({
        groupId: this.currentAdminGroupId,
      })
      .subscribe(
        (result) => {
          this.loading = false;
          this.triggers = result.results.reverse();
        },
        () => {
          this.loading = false;
        }
      );
  }

  deleteTrigger(trigger: ITrigger) {
    this.marketingService.deleteGeoTrigger(trigger.id).subscribe(
      () => {
        this.toastr.success('Trigger deleted.');
        this.deleteRowFromTable(trigger);
      },
      () => this.toastr.error('Failed to delete trigger.')
    );
  }

  deleteRowFromTable(trigger: ITrigger) {
    const itemIndex = this.triggers.findIndex((obj) => obj.id === trigger.id);
    this.triggers.splice(itemIndex, 1);
    // https://stackoverflow.com/questions/48991210/angular-material-mat-table-data-not-refreshed-from-parent-changes?rq=1
    // https://stackoverflow.com/questions/7486085/copying-array-by-value-in-javascript
    this.triggers = this.triggers.slice();
  }

  async openConfirmationDialog(trigger: ITrigger) {
    if (await this.popups.confirm('Are you sure you want to delete this?')) {
      this.deleteTrigger(trigger);
    }
  }
}
