<h2 mat-dialog-title>Map Settings</h2>
<mat-dialog-content class="mat-typography">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <mat-form-field>
      <mat-select [(value)]="data.baseLayer" placeholder="Select Map">
        <mat-option *ngFor="let baseLayer of data.baseLayers" [value]="baseLayer">
          {{ baseLayer.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <span style="width: 3em"></span>
    <mat-form-field>
      <mat-select [(value)]="data.weightProperty" placeholder="Select Heatmap Weights">
        <mat-option *ngFor="let property of data.weightProperties" [value]="property">
          {{ property.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>Radius: {{ data.heatmapSettings.radius }}</div>
    <div>Blur: {{ data.heatmapSettings.blur }}</div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <mat-slider [max]="30" [min]="1" [step]="1" [tickInterval]="1" [(ngModel)]="data.heatmapSettings.radius">
      </mat-slider>
    </div>
    <div>
      <mat-slider [max]="50" [min]="1" [step]="1" [tickInterval]="1" [(ngModel)]="data.heatmapSettings.blur">
      </mat-slider>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>Dampening: {{ data.heatmapSettings.dampening }}</div>
    <div>Opacity: {{ data.heatmapSettings.opacity }}</div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <mat-slider [max]="1" [min]="0" [step]="0.05" [tickInterval]="0.05" [(ngModel)]="data.heatmapSettings.dampening">
      </mat-slider>
    </div>
    <div>
      <mat-slider [max]="1" [min]="0" [step]="0.05" [tickInterval]="0.05" [(ngModel)]="data.heatmapSettings.opacity">
      </mat-slider>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div>Show markers for users active within days: {{ data.activeWithin }}</div>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlexFill>
      <mat-slider [max]="365" [min]="1" [step]="1" [tickInterval]="1" [(ngModel)]="data.activeWithin"> </mat-slider>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="resetHeatmapSettings()" mat-raised-button>
    <mat-icon >refresh</mat-icon>
    Reset Sliders
  </button>
  <button mat-raised-button (click)="dialogRef.close(data)" color="accent">Save</button>
  <button mat-raised-button (click)="dialogRef.close(false)">Cancel</button>
</mat-dialog-actions>
