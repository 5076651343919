import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@/app/shared.module';
import { ChangeCreditPageRoutingModule } from '@/app/pages/rewards/pages/change-credit-page-routing.module';
import { ChangeCreditPageComponent } from '@/app/pages/rewards/pages/change-credit-page.component';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [ChangeCreditPageComponent],
  imports: [CommonModule, ChangeCreditPageRoutingModule, SharedModule, MatSelectModule],
})
export class ChangeCreditPageModule {}
