import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { ColorPickerModule } from 'ngx-color-picker';
import { ImageCropperModule } from 'ngx-img-cropper';
import { FileInputAccessorModule } from 'file-input-accessor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { FileUploadModule } from 'ng2-file-upload';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgPopupsModule } from 'ng-popups';
import { GoogleMapsModule } from '@angular/google-maps';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PhotoCropperModalComponent } from './components/modals/photo-cropper-modal/photo-cropper-modal.component';
import { DateSelectorComponent } from './components/shared/date-selector/date-selector.component';
import { MapAddressSearchBarComponent } from './components/shared/map-address-search-bar/map-address-search-bar.component';
import { PhotosUploaderComponent } from './components/shared/photos-uploader/photos-uploader.component';
import { MobilePreviewComponent } from './components/shared/mobile-preview/mobile-preview.component';
import { MockMobileTemplateComponent } from './components/shared/mock-mobile-template/mock-mobile-template.component';
import { PhotoCardComponent } from './components/shared/photo-card/photo-card.component';
import { ChatCardComponent } from './components/shared/chat-card/chat-card.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { TimestampToDatePipe } from './pipes/timestamp-to-date.pipe';
import { FeedCardComponent } from './pages/feed/feed-page/components/feed-card/feed-card.component';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { ChartsModule } from './components/charts/charts.module';
import { MatGridListModule } from '@angular/material/grid-list';
import {MatBadgeModule} from '@angular/material/badge';

const mm = [
  MatNativeDateModule,
  MatButtonModule,
  MatToolbarModule,
  MatSidenavModule,
  MatProgressSpinnerModule,
  MatListModule,
  MatIconModule,
  MatDialogModule,
  MatFormFieldModule,
  MatSelectModule,
  MatTableModule,
  MatPaginatorModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatTooltipModule,
  MatSortModule,
  MatInputModule,
  MatExpansionModule,
  MatCardModule,
  MatAutocompleteModule,
  MatStepperModule,
  MatRadioModule,
  MatSliderModule,
  MatChipsModule,
  MatTabsModule,
  MatMenuModule,
  MatGridListModule,
  MatBadgeModule,
  HttpClientModule,
  FileUploadModule,
  ToastrModule.forRoot({
    closeButton: true,
    positionClass: 'toast-top-right',
    timeOut: 3000,
  }),
  ReactiveFormsModule,
  FormsModule,
  FileInputAccessorModule,
  ImageCropperModule,
  ColorPickerModule,
  NgPopupsModule.forRoot({
    theme: 'material',
    color: '#0288d1',
  }),
  GoogleMapsModule,
  // causes errors in unit tests
  FlexLayoutModule,
  EditorModule,
  RouterModule,
  ChartsModule,
];

const components = [
  TimestampToDatePipe,
  PhotoCropperModalComponent,
  DateSelectorComponent,
  PhotosUploaderComponent,
  MapAddressSearchBarComponent,
  MockMobileTemplateComponent,
  MobilePreviewComponent,
  PhotoCardComponent,
  ChatCardComponent,
  FeedCardComponent,
];

@NgModule({
  imports: [CommonModule, ...mm],
  declarations: components,
  exports: [...components, ...(mm as any)],
  providers: [TitleCasePipe, { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }],
})
export class SharedModule {}
