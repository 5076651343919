import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-pin-to-top-modal',
  templateUrl: './pin-to-top-modal.component.html',
  styleUrls: ['./pin-to-top-modal.component.scss'],
})
export class PinToTopModalComponent implements OnInit {
  @ViewChild('numHours', { static: true })
  public numHours: ElementRef;

  constructor(public dialogRef: MatDialogRef<PinToTopModalComponent>) {}

  ngOnInit() {
    this.numHours.nativeElement.value = 1;
  }

  closeModal() {
    this.dialogRef.close(null);
  }

  pinForHours(hours: number) {
    this.dialogRef.close(hours);
  }

  pinIndefinitely() {
    this.dialogRef.close(0);
  }
}
