import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@/app/shared.module';
import { TriggersPageRoutingModule } from './triggers-page-routing.module';
import { CreateGeoTriggerComponent } from './pages/create-geo-trigger/create-geo-trigger.component';
import { YourGeoTriggersComponent } from './pages/your-geo-triggers/your-geo-triggers.component';
import { TriggersPageComponent } from './triggers-page.component';

@NgModule({
  declarations: [TriggersPageComponent, CreateGeoTriggerComponent, YourGeoTriggersComponent],
  imports: [CommonModule, TriggersPageRoutingModule, SharedModule],
})
export class TriggersPageModule {}
