import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@/app/shared.module';
import { UserProfilePageRoutingModule } from './user-profile-page-routing.module';
import { UserProfilePageComponent } from './user-profile-page.component';
import { JourneyMapComponent } from './components/journey-map/journey-map.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  declarations: [UserProfilePageComponent, JourneyMapComponent],
  imports: [CommonModule, UserProfilePageRoutingModule, SharedModule, ReactiveFormsModule, MatCardModule, FlexModule]
})
export class UserProfilePageModule {}
