import { AppConfigService } from '@/app/config/app-config.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss'],
})
export class PortalComponent implements OnInit {
  iframeSrc: SafeResourceUrl;

  constructor(private appConfig: AppConfigService, private route: ActivatedRoute, private sanitizer: DomSanitizer) {
    this.route.paramMap.pipe(untilDestroyed(this)).subscribe((paramMap) => {
      const portalSlug = paramMap.get('portalSlug');
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
        [
          this.appConfig.appConfig.portals?.[portalSlug]?.url?.replace(/\/+$/, ''),
          ...this.route.snapshot.url.map((o) => o.path),
        ].join('/')
      );
    });
  }

  ngOnInit(): void {}

  onLoadFunc(myIframe) {}
}
