import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { canActivateWithAccessGuard } from '@/app/core/auth/access-guard';
import { fullAdminOrMyself } from '@/app/core/auth/auth-pipes';
import { UserProfilePageComponent } from './user-profile-page.component';

const routes: Routes = [
  { path: ':id', component: UserProfilePageComponent, ...canActivateWithAccessGuard(fullAdminOrMyself) },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class UserProfilePageRoutingModule {}
