<ng-template #menuItem let-item="item">
  <a *ngIf="item.url" mat-list-item [routerLink]="item.url" routerLinkActive="item-selected">
    <mat-icon mat-list-icon>{{ item.icon }}</mat-icon>
    <span mat-line>{{ item.label }}</span>
  </a>
</ng-template>

<ng-template #menuItemItems let-item="item">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon >{{ item.icon }}</mat-icon>
      </mat-panel-title>
      <mat-panel-description>
        {{ item.label }}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-container
      *ngFor="let item of item.items"
      [ngTemplateOutlet]="!!item.items ? menuItemItems : menuItem"
      [ngTemplateOutletContext]="{ item: item }"
    ></ng-container>
  </mat-expansion-panel>
</ng-template>

<mat-sidenav-container>
  <mat-sidenav
    class="sidenav"
    [mode]="isScreenBig() ? 'side' : 'over'"
    [fixedInViewport]="true"
    [fixedTopGap]="64"
    mode="push"
    [opened]="shouldSideNavBeOpen()"
    #sidenav
  >
    <mat-nav-list>
      <div class="user-search" *ngIf="currentUser.isAdmin && appConfig.whiteLabelAppId === 'TRAVELLO'">
        <mat-form-field fxFlex appearance="outline">
          <mat-icon matPrefix >search</mat-icon>
          <mat-label>User Search</mat-label>
          <input
            type="text"
            #searchFormInputValue
            matInput
            (input)="autocompleteDropdown($event)"
            [matAutocomplete]="userAutoComplete"
          />
          <mat-autocomplete #userAutoComplete="matAutocomplete" [displayWith]="displayBlank">
            <mat-option (onSelectionChange)="navToUserProfile($event)" *ngFor="let user of users" [value]="user">
              <img class="search-profile-pic" [src]="user.profileImage !== null ? user.profileImage : logoPng" /><span
                class="search-profile-name"
                >{{ user.userName }}</span
              >
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <mat-accordion multi>
        <ng-container
          *ngFor="let item of navLinks"
          [ngTemplateOutlet]="!!item.items ? menuItemItems : menuItem"
          [ngTemplateOutletContext]="{ item: item }"
        ></ng-container>
      </mat-accordion>
    </mat-nav-list>
  </mat-sidenav>

  <div class="main-content">
    <router-outlet></router-outlet>
  </div>
</mat-sidenav-container>
