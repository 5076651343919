<mat-toolbar color="primary">
  <span>Status Transactions for {{ username }}</span>
</mat-toolbar>

<div class="page-content">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>Date / Time (GMT)</th>
      <td mat-cell *matCellDef="let element">{{ element.getCreatedAt() | timestampToDate | date: 'short' }}</td>
    </ng-container>

    <ng-container matColumnDef="icon">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <img class="icon" [src]="element.getIcon().getUrl()" alt="Icon" />
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="toFeedUrl(element.getId())">
          <a href="{{ toFeedUrl(element.getId()) }}" target="CWA">
            {{ element.getDescription() }}
          </a>
        </span>
        <span *ngIf="!toFeedUrl(element.getId())">
          {{ element.getDescription() }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="points">
      <th mat-header-cell *matHeaderCellDef>Points</th>
      <td mat-cell *matCellDef="let element">{{ element.getPoints() }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
</div>
