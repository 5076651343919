import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { LoginCheckComponent } from './pages/login-check/login-check.component';

export const routes: Routes = [
  { path: 'feed', loadChildren: () => import('./pages/feed/feed-page/feed-page.module').then((m) => m.FeedPageModule) },
  {
    path: 'comments',
    loadChildren: () => import('./pages/feed/comments-page/comments-page.module').then((m) => m.FeedCommentsModule),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./pages/user-profile-page/user-profile-page.module').then((m) => m.UserProfilePageModule),
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat-page/chat-page.module').then((m) => m.ChatPageModule),
  },
  {
    path: 'changeCredit',
    loadChildren: () => import('./pages/rewards/pages/change-credit-page.module').then((m) => m.ChangeCreditPageModule),
  },
  {
    path: 'userSegments',
    loadChildren: () =>
      import('./pages/engagement/usersegments-page/usersegments-page.module').then((m) => m.UsersegmentsPageModule),
  },
  {
    path: 'templates',
    loadChildren: () =>
      import('./pages/engagement/templates-page/templates-page.module').then((m) => m.TemplatesPageModule),
  },
  {
    path: 'triggers',
    loadChildren: () =>
      import('./pages/engagement/triggers-page/triggers-page.module').then((m) => m.TriggersPageModule),
  },
  {
    path: 'push',
    loadChildren: () =>
      import('./pages/engagement/push-notification-module/push-notification.module').then(
        (m) => m.PushNotificationModule
      ),
  },
  {
    path: 'group',
    loadChildren: () => import('./pages/group/group.module').then((m) => m.GroupModule),
  },
  {
    path: 'rewards',
    loadChildren: () => import('./pages/rewards/leader-board-page.module').then((m) => m.LeaderBoardPageModule),
  },
  { path: 'login', component: LoginPageComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'login-check', component: LoginCheckComponent },
  {
    path: 'geoFences',
    loadChildren: () => import('./pages/geo-fences/geo-fences.module').then((m) => m.GeoFencesModule),
  },
  {
    path: 'insights',
    loadChildren: () => import('./pages/analytics/insights.module').then((m) => m.InsightsModule),
  },
  { path: 'portal', loadChildren: () => import('./pages/portal/portal.module').then((m) => m.PortalModule) },
  {
    path: 'booking',
    loadChildren: () => import('./pages/booking/booking-query.module').then((m) => m.BookingQueryModule),
  },
  { path: '**', redirectTo: 'insights/dashboard' },
  { path: '', redirectTo: 'insights/dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
