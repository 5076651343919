<div class="form-inputs" [formGroup]="parentForm">
  <div fxLayout="column" fxLayoutAlign="center center">
    <div fxFlex="row" fxLayoutAlign="start center" fxLayoutGap="1vw" fxLayoutWrap="wrap" class="fill-parent">
      <div *ngFor="let photo of photosAdded" class="photo-preview-card">
        <app-photo-card [image]="photo" (deleteClicked)="deletePhoto(photo)"></app-photo-card>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1em" class="fill-parent">
      <button type="button" mat-raised-button color="accent" (click)="hiddenPhotoInput.click()">
        <mat-icon >camera_alt</mat-icon>Select
      </button>
      <!-- Have to hide this and trigger it with a button because angular material doesn't support input with type "file" -->
      <input
        #hiddenPhotoInput
        hidden
        name="file"
        type="file"
        accept=".jpg,.png"
        (change)="onNewPhotosUploaded($event)"
        [multiple]="true"
      />
      <mat-form-field
        fxFlex
        hintLabel="Hold shift key to add multiple photos in one go. JPEG and PNG images only."
        class="fill-parent"
      >
        <input matInput placeholder="Photos" formControlName="photosInput" [readonly]="true" />
      </mat-form-field>
    </div>
  </div>
</div>
