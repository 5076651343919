import { Injectable } from '@angular/core';
import { NgPopupsPromptResult, NgPopupsService } from 'ng-popups';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PopupsService {
  constructor(private ngPopups: NgPopupsService) {}
  async alert(message: string) {
    await this.ngPopups.alert(message).pipe(take(1)).toPromise();
    return;
  }
  async confirm(message: string) {
    return (await this.ngPopups.confirm(message).pipe(take(1)).toPromise()) as boolean;
  }
  async prompt(message: string) {
    return (await this.ngPopups.prompt(message).pipe(take(1)).toPromise()) as NgPopupsPromptResult;
  }
}
