import { Component, OnInit } from '@angular/core';
import {
  IGeosegment,
  ITemplate,
  IUserSegment,
  MarketingRepositoryService,
} from '@/app/services/repositories/marketing-repository.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TINYMCE_CONFIG } from '@/app/config/static-options';
import { SessionService } from '@/app/core/auth/session.service';
import { IMockTemplateMobile } from '@/app/components/shared/mock-mobile-template/mock-mobile-template.component';
import { PopupsService } from '@/app/services/popups.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss'],
})
export class PushNotificationComponent implements OnInit {
  pushForm: UntypedFormGroup;

  geofences: IGeosegment[] = [];
  userSegments: IUserSegment[] = [];
  templates: ITemplate[] = [];

  preview: IMockTemplateMobile = {
    marketingText: '<p></p>',
  };

  estimateNum = 0;
  loading = false;

  tinymceConfig = TINYMCE_CONFIG;

  constructor(
    public router: Router,
    public marketingService: MarketingRepositoryService,
    public currentUser: SessionService,
    public toastr: ToastrService,
    private popups: PopupsService
  ) {
    this.createForm();
    this.currentUser.currentAdminGroup$.pipe(untilDestroyed(this)).subscribe(() => {
      this.getGeofences();
      this.getUserSegments();
      this.getTemplates();
      this.getPushEstimate();
    });
    this.bindFormInputsToPreview();
  }

  get currentAdminGroupId() {
    return this.currentUser.currentAdminGroup.groupId;
  }

  ngOnInit() {
    this.getGeofences();
    this.getUserSegments();
    this.getTemplates();
  }

  createForm() {
    this.pushForm = new UntypedFormGroup({
      fence: new UntypedFormControl(''),
      userSegment: new UntypedFormControl(''),
      template: new UntypedFormControl(''),
      pushMessage: new UntypedFormControl(''),
      marketingText: new UntypedFormControl(''),
    });
  }

  alphabetSort(a, b) {
    if (!a.name) {
      return +1;
    }
    if (!b.name) {
      return -1;
    }
    return a.name.localeCompare(b.name);
  }

  getGeofences() {
    this.marketingService
      .getGeosegments({
        groupId: this.currentAdminGroupId,
      })
      .subscribe((result) => {
        this.geofences = result.results.sort(this.alphabetSort);
      });
  }

  getTemplates() {
    this.marketingService
      .getTemplates({
        groupId: this.currentAdminGroupId,
      })
      .subscribe((result) => {
        this.templates = result.results.sort(this.alphabetSort);
      });
  }

  getUserSegments() {
    this.marketingService
      .getUserSegments({
        groupId: this.currentAdminGroupId,
      })
      .subscribe((result) => {
        this.userSegments = result.results.sort(this.alphabetSort);
      });
  }

  // This is how I get the preview to update when user enters stuff in the WYSIWYG editor
  bindFormInputsToPreview() {
    this.pushForm.controls['marketingText'].valueChanges.subscribe((val) => {
      this.preview = {
        marketingText: val,
      };
    });
  }

  loadTemplateIntoForm(template: ITemplate) {
    this.pushForm.controls['marketingText'].setValue(template.marketingText);
    this.pushForm.controls['pushMessage'].setValue(template.message);
  }

  async openConfirmationDialog() {
    if (await this.popups.confirm('Are you sure you want to send a message to these people?')) {
      this.send();
    }
  }

  send() {
    this.marketingService
      .postPush({
        groupId: this.currentAdminGroupId,
        geoRegionId: this.pushForm.get('fence').value,
        marketingMessage: this.wrapInDiv(this.pushForm.get('marketingText').value),
        message: this.pushForm.get('pushMessage').value,
        userSegmentId: this.pushForm.get('userSegment').value,
      })
      .subscribe(
        () => {
          this.toastr.success('Push Messages Sent');
        },
        (err: Response) => {
          if (err.status === 501) {
            this.toastr.error('Push Messages Feature Currently Unavailable.');
          } else {
            this.toastr.error('Push Messages Sending Failed');
          }
        }
      );
  }

  // a little hack to get pushes that just contain text to display in the apps
  wrapInDiv(message: string): string {
    return '<div>' + message + '</div>';
  }

  getPushEstimate() {
    if (this.pushForm.get('fence').value) {
      this.loading = true;

      this.marketingService
        .getPushEstimate({
          geoRegionId: this.pushForm.get('fence').value,
          groupId: this.currentAdminGroupId,
          userSegmentId: this.pushForm.get('userSegment').value,
        })
        .subscribe(
          (result) => {
            this.estimateNum = result.count;
            this.loading = false;
          },
          () => {
            this.loading = false;
            this.toastr.error('Error retrieving estimate');
          }
        );
    }
  }
}
