import { Component, OnDestroy, OnInit } from '@angular/core';
import { IChatMessage, IUser, UserRepositoryService } from '@/app/services/repositories/user-repository.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SessionService } from '@/app/core/auth/session.service';
@Component({
  selector: 'app-chat-page',
  templateUrl: './chat-page.component.html',
  styleUrls: ['./chat-page.component.scss'],
})
export class ChatPageComponent implements OnInit, OnDestroy {
  chatMessages: IChatMessage[];
  otherParty: IUser;
  private sub: any;
  newMessageForm: UntypedFormGroup;

  constructor(
    public activatedRoute: ActivatedRoute,
    public userRepository: UserRepositoryService,
    public currentUser: SessionService,
    public formbuilder: UntypedFormBuilder,
    public toastr: ToastrService
  ) {}

  get me() {
    return this.currentUser.currentUser;
  }

  ngOnInit() {
    this.sub = this.activatedRoute.params.subscribe((params) => {
      const otherPartyId = params['id'];
      this.getOtherParty(otherPartyId);
      this.getChatMessages(otherPartyId);
    });
    this.createForm();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getOtherParty(otherPartyId: string) {
    this.userRepository.getUser(otherPartyId).subscribe(
      (result) => {
        this.otherParty = result;
      },
      () => {
        this.toastr.error('Failed to get other party details');
      }
    );
  }

  getChatMessages(otherPartyId: string) {
    this.userRepository.getChatMessages(otherPartyId).subscribe(
      (result) => {
        this.chatMessages = result.results;
        if (result.results && result.results.length > 0) {
          this.markChatAsRead(otherPartyId);
        }
      },
      () => {
        this.toastr.error('Failed to get chat messages');
      }
    );
  }

  markChatAsRead(otherPartyId: string) {
    this.userRepository.markChatAsRead(otherPartyId).subscribe(() => {
      this.currentUser.syncMessages(); // doing this forces the unread messages counter to update?
    });
  }

  createForm() {
    this.newMessageForm = this.formbuilder.group({
      newMessage: [''],
    });
  }

  saveMessage() {
    this.userRepository
      .postChatMessage(this.otherParty.id, { message: this.newMessageForm.get('newMessage').value })
      .subscribe(
        () => {
          this.toastr.success('Message sent');
          this.getChatMessages(this.otherParty.id);
          if (this.newMessageForm) {
            this.newMessageForm.reset();
          }
        },
        () => {
          this.toastr.error('Message send failed');
        }
      );
  }
}
