import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RewardsRepositoryService } from '@/app/services/repositories/rewards-repository.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { SessionService } from '@/app/core/auth/session.service';
import { IUser, UserRepositoryService } from '@/app/services/repositories/user-repository.service';
import { CreditTransactionResponseForAdmin } from '@travello/grpc-api/js/reward_pb';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-change-credit-page',
  templateUrl: './change-credit-page.component.html',
  styleUrls: ['./change-credit-page.scss'],
})
export class ChangeCreditPageComponent implements OnInit, OnDestroy {
  targetUser: IUser;
  private sub: any;
  newRecordForm: UntypedFormGroup;

  creditPointsColumnsToDisplay = ['type', 'value', 'createdAt', 'settlementDate', 'description'];

  creditPointRecords = new MatTableDataSource<CreditTransactionResponseForAdmin>();
  @ViewChild(MatPaginator, { static: true }) creditPointPaginator: MatPaginator;

  typeChoices = [
    { value: 'support', viewValue: 'Support' },
    { value: 'market', viewValue: 'Market' },
  ];

  defaultTypeValue = 'support';

  constructor(
    public activatedRoute: ActivatedRoute,
    public userRepository: UserRepositoryService,
    public rewardRepository: RewardsRepositoryService,
    public currentUser: SessionService,
    public formbuilder: UntypedFormBuilder,
    public toastr: ToastrService,
    private rewardsService: RewardsRepositoryService
  ) {}

  get me() {
    return this.currentUser.currentUser;
  }

  createForm() {
    this.newRecordForm = this.formbuilder.group({
      amount: new UntypedFormControl('100', [Validators.required, Validators.pattern('^\\-?[1-9]\\d{0,10}$')]),
      description: new UntypedFormControl(''),
      type: new UntypedFormControl(this.typeChoices[0].value, [Validators.required]),
    });
  }

  ngOnInit() {
    this.sub = this.activatedRoute.params.subscribe((params) => {
      const targetUserId = params['id'];
      this.loadTargetUser(targetUserId);
      this.getUserCreditPointRecords(targetUserId);
    });
    this.createForm();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  loadTargetUser(otherPartyId: string) {
    this.userRepository.getUser(otherPartyId).subscribe(
      (result) => {
        this.targetUser = result;
      },
      () => {
        this.toastr.error('Failed to get other party details');
      }
    );
  }

  saveCreditRecord() {
    this.rewardRepository
      .changeCredits(
        this.targetUser.id,
        this.me.userName,
        this.newRecordForm.get('amount').value,
        this.newRecordForm.get('description').value,
        this.newRecordForm.get('type').value
      )
      .then(
        () => {
          this.getUserCreditPointRecords(this.targetUser.id);
        },
        () => {
          this.toastr.error('failed to saveCreditRecord');
        }
      );
  }

  private getUserCreditPointRecords(userId: string) {
    this.rewardsService.getCreditTransactions(userId).subscribe(
      (result) => {
        this.creditPointRecords.data = result;
      },
      () => {
        this.toastr.error('Failed to getUserCreditPointRecords');
      }
    );
  }
}
