<div>
  <div
    class="dashboard-card"
    [ngClass]="{
      red: color === eDashCardColor.Red,
      blue: color === eDashCardColor.Blue,
      green: color === eDashCardColor.Green,
      purple: color === eDashCardColor.Purple
    }"
    fxLayout="row"
    fxLayoutAlign="space-around center"
  >
    <mat-icon class=" dashboard-card-icon">{{ icon }}</mat-icon>
    <div>
      <div class="stat-number">{{ data }}</div>
      <div class="stat-title">{{ statTitle }}</div>
    </div>
  </div>
</div>
