import { Injectable } from '@angular/core';
import { attachParametersToUrl } from './utils';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class NotificationsRepositoryService {
  constructor(private http: HttpClient) {}

  getStats(params: IStatParams): Observable<INotificationsStats> {
    const url = '/2/notifications/stats';
    return this.http.get<INotificationsStats>(attachParametersToUrl(url, params));
  }
}

export interface INotificationsStats {
  totalPushOpen: number;
  totalPushRead: number;
  totalPushSent: number;
}

export interface IStatParams {
  to: string;
  from: string;
  groupId?: string;
}
