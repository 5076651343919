import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CropperSettings, ImageCropperComponent } from 'ngx-img-cropper';
import { Bounds } from 'ngx-img-cropper/lib/image-cropper/model/bounds';

/* Input a FileList crop the images in it and output an array of base64 strings + metadata (ICroppedPhoto[]) */

@Component({
  selector: 'app-photo-cropper-modal',
  templateUrl: './photo-cropper-modal.component.html',
  styleUrls: ['./photo-cropper-modal.component.scss'],
})
export class PhotoCropperModalComponent {
  imageData: any;
  cropperSettings1: CropperSettings;
  croppedWidth = 400;
  croppedHeight = 300;
  previewRatio = 4 / 3;
  @ViewChild('myCropper', { static: true }) myCropper: ImageCropperComponent;
  @ViewChild('preview') preview;

  // I have to keep track of which file I'm on
  fileIndex = 0;

  // finished images to be returned from component
  croppedImages: ICroppedPhoto[] = [];

  constructor(
    public modal: MatDialog,
    public dialogRef: MatDialogRef<PhotoCropperModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IMyCropperSettings
  ) {
    if (data.width) {
      this.croppedWidth = data.width;
    }
    if (data.height) {
      this.croppedHeight = data.height;
    }
    if (data.height || data.width) {
      this.previewRatio = this.croppedWidth / this.croppedHeight;
    }
    this.cropperSettings1 = new CropperSettings();
    this.cropperSettings1.width = this.croppedWidth;
    this.cropperSettings1.height = this.croppedHeight;
    this.cropperSettings1.croppedWidth = this.croppedWidth;
    this.cropperSettings1.croppedHeight = this.croppedHeight;
    this.cropperSettings1.canvasWidth = this.croppedWidth;
    this.cropperSettings1.canvasHeight = this.croppedHeight;
    this.cropperSettings1.minWidth = 10;
    this.cropperSettings1.minHeight = 10;
    this.cropperSettings1.rounded = false;
    this.cropperSettings1.noFileInput = true;
    this.cropperSettings1.keepAspect = true;
    this.cropperSettings1.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
    this.cropperSettings1.cropperDrawSettings.strokeWidth = 2;

    this.loadPhotoIntoCropper(data.files[this.fileIndex]);
  }

  cropped(bounds: Bounds) {
    this.croppedHeight = bounds.bottom - bounds.top;
    this.croppedWidth = bounds.right - bounds.left;
  }

  loadPhotoIntoCropper(file) {
    this.imageData = file;
    const image: any = new Image();
    const myReader: FileReader = new FileReader();

    // had to change "function() {}" to "() => {}" to access correct "this"
    // this function runs asynchronously
    myReader.onload = (loadEvent: any) => {
      image.src = loadEvent.target.result;
      this.myCropper.setImage(image);
    };

    myReader.onerror = function(error) {
      console.log('Error: ', error);
    };

    myReader.readAsDataURL(file);
  }

  /*
      When finished cropping create a ICroppedPhoto and add it to the croppedImages array
      Then Check if it was the last photo.
      If last photo close modal else do next photo
   */
  public onDoneCropping() {
    const croppedImage: ICroppedPhoto = {
      srcString: this.imageData.image,
      fileName: this.data.files[this.fileIndex].name,
      fileSizeInKb: this.data.files[this.fileIndex].size / 1000,
      fileType: this.data.files[this.fileIndex].type,
    };
    this.croppedImages.push(croppedImage);

    this.fileIndex++;
    if (this.fileIndex < this.data.files.length) {
      this.loadPhotoIntoCropper(this.data.files[this.fileIndex]);
    } else {
      const returnData: ICropperReturnData = { data: this.croppedImages };
      this.dialogRef.close(returnData);
    }
  }
}

export interface ICroppedPhoto {
  srcString: string; // This can be used in img tags src attribute. Can be a URL or a base64 encoded image string
  fileName?: string;
  fileSizeInKb?: number;
  fileType?: string;
  id?: string; // this is an id assigned to the photo in the backend
}

export interface IMyCropperSettings {
  files: FileList;
  width?: number;
  height?: number;
}

export interface ICropperReturnData {
  data: ICroppedPhoto[];
}
