import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ITemplate, MarketingRepositoryService } from '@/app/services/repositories/marketing-repository.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditTemplateService } from '../../../../../services/edit-template.service';
import { ETemplatePageMode } from '../edit-template-module/edit-template-page.component';
import { SessionService } from '@/app/core/auth/session.service';
import { IMockTemplateMobile } from '@/app/components/shared/mock-mobile-template/mock-mobile-template.component';
import { PreviewTemplateModalComponent } from '../../modals/preview-template-modal/preview-template-modal.component';
import { PopupsService } from '@/app/services/popups.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-your-templates-page',
  templateUrl: './your-templates-page.component.html',
  styleUrls: ['./your-templates-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class YourTemplatesPageComponent implements OnInit {
  templates: ITemplate[] = [];
  displayedColumns = ['name', 'message', 'actions'];

  loading = false;

  constructor(
    private marketingService: MarketingRepositoryService,
    private currentUser: SessionService,
    private toastr: ToastrService,
    private router: Router,
    private dialog: MatDialog,
    private editTemplateService: EditTemplateService,
    private popups: PopupsService
  ) {}

  get currentAdminGroupId() {
    return this.currentUser.currentAdminGroup.groupId;
  }

  ngOnInit() {
    this.currentUser.currentAdminGroup$.pipe(untilDestroyed(this)).subscribe(() => {
      this.getTemplates();
    });
    this.getTemplates();
  }

  getTemplates() {
    this.loading = true;
    this.marketingService
      .getTemplates({
        groupId: this.currentAdminGroupId,
      })
      .subscribe(
        (result) => {
          this.templates = result.results.reverse();
          this.loading = false;
        },
        (error) => {
          console.log('Error getting templates');
          console.log(error);
          this.loading = false;
        }
      );
  }

  deleteTemplate(template: ITemplate) {
    this.marketingService.deleteTemplate(template.id).subscribe(
      () => {
        this.toastr.success('Template deleted.');
        this.deleteRowFromTable(template);
      },
      () => this.toastr.error('Failed to delete template.')
    );
  }

  deleteRowFromTable(template: ITemplate) {
    const itemIndex = this.templates.findIndex((obj) => obj.id === template.id);
    this.templates.splice(itemIndex, 1);
    // https://stackoverflow.com/questions/48991210/angular-material-mat-table-data-not-refreshed-from-parent-changes?rq=1
    // https://stackoverflow.com/questions/7486085/copying-array-by-value-in-javascript
    this.templates = this.templates.slice();
  }

  viewTemplate(template: ITemplate) {
    const mockMobile: IMockTemplateMobile = {
      marketingText: template.marketingText,
    };

    this.dialog.open(PreviewTemplateModalComponent, <MatDialogConfig>{ data: <IMockTemplateMobile>mockMobile });
  }

  editTemplate(template: ITemplate) {
    this.editTemplateService.$template.next(template);

    this.router.navigate(['/templates/edit'], { queryParams: { mode: ETemplatePageMode.Edit } });
  }

  async openConfirmationDialog(template: ITemplate) {
    if (await this.popups.confirm('Are you sure you want to delete this?')) {
      this.deleteTemplate(template);
    }
  }
}
