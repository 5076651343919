<div>
  <br />
  <br />
  <form
    [formGroup]="userSegmentForm"
    (ngSubmit)="userSegmentForm.valid && save()"
    fxLayout="column"
    fxLayoutGap="1em"
    fxFlex="70"
  >
    <mat-form-field>
      <input matInput placeholder="Name" formControlName="name" />
      <mat-error> Name is <strong>required</strong> </mat-error>
    </mat-form-field>

    <p>Gender</p>
    <mat-radio-group formControlName="gender">
      <mat-radio-button value="">Both</mat-radio-button>
      <mat-radio-button value="M">Male</mat-radio-button>
      <mat-radio-button value="F">Female</mat-radio-button>
    </mat-radio-group>
    <br />

    Age
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex="5">
        {{ userSegmentForm.value.age[0] }}
      </div>
      <div fxFlex="85">
        <nouislider [connect]="true" [min]="18" [max]="99" [step]="1" [formControl]="userSegmentForm.controls.age">
        </nouislider>
      </div>
      <div fxFlex="5">
        {{ userSegmentForm.value.age[1] }}
      </div>
    </div>

    Last Active within X Days
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex="90">
        <nouislider
          [connect]="true"
          [min]="1"
          [max]="365"
          [step]="1"
          [formControl]="userSegmentForm.controls.lastActive"
        >
        </nouislider>
      </div>
      <div fxFlex="5">
        {{ userSegmentForm.value.lastActive }}
      </div>
    </div>

    Account Created within X Days
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex="90">
        <nouislider
          [connect]="true"
          [min]="1"
          [max]="3650"
          [step]="1"
          [formControl]="userSegmentForm.controls.accountCreated"
        >
        </nouislider>
      </div>
      <div fxFlex="5">
        {{ userSegmentForm.value.accountCreated }}
      </div>
    </div>

    <mat-form-field class="fill-parent">
      <mat-select formControlName="interests" placeholder="Interests" multiple>
        <mat-option value="Surfing">Surfing</mat-option>
        <mat-option value="Sporting Events">Sporting Events</mat-option>
        <mat-option value="Skiing">Skiing</mat-option>
        <mat-option value="Sightseeing">Sightseeing</mat-option>
        <mat-option value="Shopping">Shopping</mat-option>
        <mat-option value="Scuba and Snorkelling">Scuba and Snorkelling</mat-option>
        <mat-option value="Photography">Photography</mat-option>
        <mat-option value="Party">Party</mat-option>
        <mat-option value="Outdoors">Outdoors</mat-option>
        <mat-option value="Meet Locals">Meet Locals</mat-option>
        <mat-option value="History">History</mat-option>
        <mat-option value="Food and Cuisine">Food and Cuisine</mat-option>
        <mat-option value="Festivals">Festivals</mat-option>
        <mat-option value="Exercise">Exercise</mat-option>
        <mat-option value="Adventure">Adventure</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="fill-parent">
      <mat-select formControlName="nationality" placeholder="Nationality">
        <mat-option value="">Nationality</mat-option>
        <mat-option value="AF">Afghanistan</mat-option>
        <mat-option value="AL">Albania</mat-option>
        <mat-option value="DZ">Algeria</mat-option>
        <mat-option value="AS">American Samoa</mat-option>
        <mat-option value="AD">Andorra</mat-option>
        <mat-option value="AO">Angola</mat-option>
        <mat-option value="AI">Anguilla</mat-option>
        <mat-option value="AR">Argentina</mat-option>
        <mat-option value="AM">Armenia</mat-option>
        <mat-option value="AW">Aruba</mat-option>
        <mat-option value="AU">Australia</mat-option>
        <mat-option value="AT">Austria</mat-option>
        <mat-option value="AZ">Azerbaijan</mat-option>
        <mat-option value="BS">Bahamas</mat-option>
        <mat-option value="BH">Bahrain</mat-option>
        <mat-option value="BD">Bangladesh</mat-option>
        <mat-option value="BB">Barbados</mat-option>
        <mat-option value="BY">Belarus</mat-option>
        <mat-option value="BE">Belgium</mat-option>
        <mat-option value="BZ">Belize</mat-option>
        <mat-option value="BJ">Benin</mat-option>
        <mat-option value="BM">Bermuda</mat-option>
        <mat-option value="BT">Bhutan</mat-option>
        <mat-option value="BO">Bolivia</mat-option>
        <mat-option value="BA">Bosnia and Herzegowina</mat-option>
        <mat-option value="BW">Botswana</mat-option>
        <mat-option value="BV">Bouvet Island</mat-option>
        <mat-option value="BR">Brazil</mat-option>
        <mat-option value="IO">British Indian Ocean Territory</mat-option>
        <mat-option value="BN">Brunei Darussalam</mat-option>
        <mat-option value="BG">Bulgaria</mat-option>
        <mat-option value="BF">Burkina Faso</mat-option>
        <mat-option value="BI">Burundi</mat-option>
        <mat-option value="KH">Cambodia</mat-option>
        <mat-option value="CM">Cameroon</mat-option>
        <mat-option value="CA">Canada</mat-option>
        <mat-option value="CV">Cape Verde</mat-option>
        <mat-option value="KY">Cayman Islands</mat-option>
        <mat-option value="CF">Central African Republic</mat-option>
        <mat-option value="TD">Chad</mat-option>
        <mat-option value="CL">Chile</mat-option>
        <mat-option value="CN">China</mat-option>
        <mat-option value="CX">Christmas Island</mat-option>
        <mat-option value="CC">Cocos (Keeling) Islands</mat-option>
        <mat-option value="CO">Colombia</mat-option>
        <mat-option value="KM">Comoros</mat-option>
        <mat-option value="CG">Congo</mat-option>
        <mat-option value="CD">Congo, the Democratic Republic of the</mat-option>
        <mat-option value="CK">Cook Islands</mat-option>
        <mat-option value="CR">Costa Rica</mat-option>
        <mat-option value="CI">Cote d'Ivoire</mat-option>
        <mat-option value="HR">Croatia (Hrvatska)</mat-option>
        <mat-option value="CU">Cuba</mat-option>
        <mat-option value="CY">Cyprus</mat-option>
        <mat-option value="CZ">Czech Republic</mat-option>
        <mat-option value="DK">Denmark</mat-option>
        <mat-option value="DJ">Djibouti</mat-option>
        <mat-option value="DM">Dominica</mat-option>
        <mat-option value="DO">Dominican Republic</mat-option>
        <mat-option value="EC">Ecuador</mat-option>
        <mat-option value="EG">Egypt</mat-option>
        <mat-option value="SV">El Salvador</mat-option>
        <mat-option value="GQ">Equatorial Guinea</mat-option>
        <mat-option value="ER">Eritrea</mat-option>
        <mat-option value="EE">Estonia</mat-option>
        <mat-option value="ET">Ethiopia</mat-option>
        <mat-option value="FK">Falkland Islands (Malvinas)</mat-option>
        <mat-option value="FO">Faroe Islands</mat-option>
        <mat-option value="FJ">Fiji</mat-option>
        <mat-option value="FI">Finland</mat-option>
        <mat-option value="FR">France</mat-option>
        <mat-option value="GF">French Guiana</mat-option>
        <mat-option value="PF">French Polynesia</mat-option>
        <mat-option value="TF">French Southern Territories</mat-option>
        <mat-option value="GA">Gabon</mat-option>
        <mat-option value="GM">Gambia</mat-option>
        <mat-option value="GE">Georgia</mat-option>
        <mat-option value="DE">Germany</mat-option>
        <mat-option value="GH">Ghana</mat-option>
        <mat-option value="GI">Gibraltar</mat-option>
        <mat-option value="GR">Greece</mat-option>
        <mat-option value="GL">Greenland</mat-option>
        <mat-option value="GD">Grenada</mat-option>
        <mat-option value="GP">Guadeloupe</mat-option>
        <mat-option value="GU">Guam</mat-option>
        <mat-option value="GT">Guatemala</mat-option>
        <mat-option value="GN">Guinea</mat-option>
        <mat-option value="GW">Guinea-Bissau</mat-option>
        <mat-option value="GY">Guyana</mat-option>
        <mat-option value="HT">Haiti</mat-option>
        <mat-option value="HM">Heard and Mc Donald Islands</mat-option>
        <mat-option value="VA">Holy See (Vatican City State)</mat-option>
        <mat-option value="HN">Honduras</mat-option>
        <mat-option value="HK">Hong Kong</mat-option>
        <mat-option value="HU">Hungary</mat-option>
        <mat-option value="IS">Iceland</mat-option>
        <mat-option value="IN">India</mat-option>
        <mat-option value="ID">Indonesia</mat-option>
        <mat-option value="IR">Iran (Islamic Republic of)</mat-option>
        <mat-option value="IQ">Iraq</mat-option>
        <mat-option value="IE">Ireland</mat-option>
        <mat-option value="IL">Israel</mat-option>
        <mat-option value="IT">Italy</mat-option>
        <mat-option value="JM">Jamaica</mat-option>
        <mat-option value="JP">Japan</mat-option>
        <mat-option value="JO">Jordan</mat-option>
        <mat-option value="KZ">Kazakhstan</mat-option>
        <mat-option value="KE">Kenya</mat-option>
        <mat-option value="KI">Kiribati</mat-option>
        <mat-option value="KP">Korea, Democratic People's Republic of</mat-option>
        <mat-option value="KR">Korea, Republic of</mat-option>
        <mat-option value="KW">Kuwait</mat-option>
        <mat-option value="KG">Kyrgyzstan</mat-option>
        <mat-option value="LA">Lao People's Democratic Republic</mat-option>
        <mat-option value="LV">Latvia</mat-option>
        <mat-option value="LB">Lebanon</mat-option>
        <mat-option value="LS">Lesotho</mat-option>
        <mat-option value="LR">Liberia</mat-option>
        <mat-option value="LY">Libyan Arab Jamahiriya</mat-option>
        <mat-option value="LI">Liechtenstein</mat-option>
        <mat-option value="LT">Lithuania</mat-option>
        <mat-option value="LU">Luxembourg</mat-option>
        <mat-option value="MO">Macau</mat-option>
        <mat-option value="MK">Macedonia, The Former Yugoslav Republic of</mat-option>
        <mat-option value="MG">Madagascar</mat-option>
        <mat-option value="MW">Malawi</mat-option>
        <mat-option value="MY">Malaysia</mat-option>
        <mat-option value="MV">Maldives</mat-option>
        <mat-option value="ML">Mali</mat-option>
        <mat-option value="MT">Malta</mat-option>
        <mat-option value="MH">Marshall Islands</mat-option>
        <mat-option value="MQ">Martinique</mat-option>
        <mat-option value="MR">Mauritania</mat-option>
        <mat-option value="MU">Mauritius</mat-option>
        <mat-option value="YT">Mayotte</mat-option>
        <mat-option value="MX">Mexico</mat-option>
        <mat-option value="FM">Micronesia, Federated States of</mat-option>
        <mat-option value="MD">Moldova, Republic of</mat-option>
        <mat-option value="MC">Monaco</mat-option>
        <mat-option value="MN">Mongolia</mat-option>
        <mat-option value="MS">Montserrat</mat-option>
        <mat-option value="MA">Morocco</mat-option>
        <mat-option value="MZ">Mozambique</mat-option>
        <mat-option value="MM">Myanmar</mat-option>
        <mat-option value="NA">Namibia</mat-option>
        <mat-option value="NR">Nauru</mat-option>
        <mat-option value="NP">Nepal</mat-option>
        <mat-option value="NL">Netherlands</mat-option>
        <mat-option value="AN">Netherlands Antilles</mat-option>
        <mat-option value="NC">New Caledonia</mat-option>
        <mat-option value="NZ">New Zealand</mat-option>
        <mat-option value="NI">Nicaragua</mat-option>
        <mat-option value="NE">Niger</mat-option>
        <mat-option value="NG">Nigeria</mat-option>
        <mat-option value="NU">Niue</mat-option>
        <mat-option value="NF">Norfolk Island</mat-option>
        <mat-option value="MP">Northern Mariana Islands</mat-option>
        <mat-option value="NO">Norway</mat-option>
        <mat-option value="OM">Oman</mat-option>
        <mat-option value="PK">Pakistan</mat-option>
        <mat-option value="PW">Palau</mat-option>
        <mat-option value="PA">Panama</mat-option>
        <mat-option value="PG">Papua New Guinea</mat-option>
        <mat-option value="PY">Paraguay</mat-option>
        <mat-option value="PE">Peru</mat-option>
        <mat-option value="PH">Philippines</mat-option>
        <mat-option value="PN">Pitcairn</mat-option>
        <mat-option value="PL">Poland</mat-option>
        <mat-option value="PT">Portugal</mat-option>
        <mat-option value="PR">Puerto Rico</mat-option>
        <mat-option value="QA">Qatar</mat-option>
        <mat-option value="RE">Reunion</mat-option>
        <mat-option value="RO">Romania</mat-option>
        <mat-option value="RU">Russian Federation</mat-option>
        <mat-option value="RW">Rwanda</mat-option>
        <mat-option value="KN">Saint Kitts and Nevis</mat-option>
        <mat-option value="LC">Saint LUCIA</mat-option>
        <mat-option value="VC">Saint Vincent and the Grenadines</mat-option>
        <mat-option value="WS">Samoa</mat-option>
        <mat-option value="SM">San Marino</mat-option>
        <mat-option value="ST">Sao Tome and Principe</mat-option>
        <mat-option value="SA">Saudi Arabia</mat-option>
        <mat-option value="SN">Senegal</mat-option>
        <mat-option value="SC">Seychelles</mat-option>
        <mat-option value="SL">Sierra Leone</mat-option>
        <mat-option value="SG">Singapore</mat-option>
        <mat-option value="SK">Slovakia (Slovak Republic)</mat-option>
        <mat-option value="SI">Slovenia</mat-option>
        <mat-option value="SB">Solomon Islands</mat-option>
        <mat-option value="SO">Somalia</mat-option>
        <mat-option value="ZA">South Africa</mat-option>
        <mat-option value="GS">South Georgia and the South Sandwich Islands</mat-option>
        <mat-option value="ES">Spain</mat-option>
        <mat-option value="LK">Sri Lanka</mat-option>
        <mat-option value="SH">St. Helena</mat-option>
        <mat-option value="PM">St. Pierre and Miquelon</mat-option>
        <mat-option value="SD">Sudan</mat-option>
        <mat-option value="SR">Suriname</mat-option>
        <mat-option value="SJ">Svalbard and Jan Mayen Islands</mat-option>
        <mat-option value="SZ">Swaziland</mat-option>
        <mat-option value="SE">Sweden</mat-option>
        <mat-option value="CH">Switzerland</mat-option>
        <mat-option value="SY">Syrian Arab Republic</mat-option>
        <mat-option value="TW">Taiwan, Province of China</mat-option>
        <mat-option value="TJ">Tajikistan</mat-option>
        <mat-option value="TZ">Tanzania, United Republic of</mat-option>
        <mat-option value="TH">Thailand</mat-option>
        <mat-option value="TG">Togo</mat-option>
        <mat-option value="TK">Tokelau</mat-option>
        <mat-option value="TO">Tonga</mat-option>
        <mat-option value="TT">Trinidad and Tobago</mat-option>
        <mat-option value="TN">Tunisia</mat-option>
        <mat-option value="TR">Turkey</mat-option>
        <mat-option value="TM">Turkmenistan</mat-option>
        <mat-option value="TC">Turks and Caicos Islands</mat-option>
        <mat-option value="TV">Tuvalu</mat-option>
        <mat-option value="UG">Uganda</mat-option>
        <mat-option value="UA">Ukraine</mat-option>
        <mat-option value="AE">United Arab Emirates</mat-option>
        <mat-option value="GB">United Kingdom</mat-option>
        <mat-option value="US">United States</mat-option>
        <mat-option value="UM">United States Minor Outlying Islands</mat-option>
        <mat-option value="UY">Uruguay</mat-option>
        <mat-option value="UZ">Uzbekistan</mat-option>
        <mat-option value="VU">Vanuatu</mat-option>
        <mat-option value="VE">Venezuela</mat-option>
        <mat-option value="VN">Viet Nam</mat-option>
        <mat-option value="VG">Virgin Islands (British)</mat-option>
        <mat-option value="VI">Virgin Islands (U.S.)</mat-option>
        <mat-option value="WF">Wallis and Futuna Islands</mat-option>
        <mat-option value="EH">Western Sahara</mat-option>
        <mat-option value="YE">Yemen</mat-option>
        <mat-option value="ZM">Zambia</mat-option>
        <mat-option value="ZW">Zimbabwe</mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-raised-button color="primary" type="submit">Save</button>
  </form>
</div>
