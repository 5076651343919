<mat-toolbar color="primary">
  <span>Instant Push Stats</span>
</mat-toolbar>

<div class="page-content" *ngIf="groupSelected">
  <div class="mat-elevation-z8 table-div">
    <mat-table #table [dataSource]="stats">
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      <ng-container matColumnDef="date">
        <mat-header-cell fxFlex *matHeaderCellDef> Date </mat-header-cell>
        <mat-cell fxFlex *matCellDef="let element">
          <div *ngIf="element.createdAt">
            {{ element.createdAt | date: 'mediumDate' }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="message">
        <mat-header-cell fxFlex="30" *matHeaderCellDef> Message </mat-header-cell>
        <mat-cell fxFlex="30" *matCellDef="let element">
          <div *ngIf="element.message">
            {{ element.message }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="fence">
        <mat-header-cell fxFlex *matHeaderCellDef> Geo Fence </mat-header-cell>
        <mat-cell fxFlex *matCellDef="let element">
          <div *ngIf="element.geoLocationName">
            {{ element.geoLocationName }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="userSegment">
        <mat-header-cell fxFlex *matHeaderCellDef> User Segment </mat-header-cell>
        <mat-cell fxFlex *matCellDef="let element">
          <div *ngIf="element.userSegmentName">
            {{ element.userSegmentName }}
          </div>
          <div *ngIf="!element.userSegmentName">All</div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="opened">
        <mat-header-cell fxFlex="8" *matHeaderCellDef> Opened </mat-header-cell>
        <mat-cell fxFlex="8" *matCellDef="let element">
          <div *ngIf="element.stats">
            <div *ngIf="element.stats.pushOpen">
              {{ element.stats.pushOpen }}
            </div>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="read">
        <mat-header-cell fxFlex="8" *matHeaderCellDef> Read </mat-header-cell>
        <mat-cell fxFlex="8" *matCellDef="let element">
          <div *ngIf="element.stats">
            <div *ngIf="element.stats.pushRead">
              {{ element.stats.pushRead }}
            </div>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="sent">
        <mat-header-cell fxFlex="8" *matHeaderCellDef> Sent </mat-header-cell>
        <mat-cell fxFlex="8" *matCellDef="let element">
          <div *ngIf="element.stats">
            <div *ngIf="element.stats.pushSent">
              {{ element.stats.pushSent }}
            </div>
          </div>
        </mat-cell>
      </ng-container>
    </mat-table>
  </div>

  <br />
  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
    <p>Loading...</p>
    <div class="spinner-container">
      <mat-spinner [diameter]="20" [strokeWidth]="4"></mat-spinner>
    </div>
  </div>
  <div *ngIf="stats.length === 0 && !loading" fxLayout="row" fxLayoutAlign="center center">
    <p>You have not sent any instant push notifications yet.</p>
  </div>
  <br />
</div>

<div class="page-content" *ngIf="!groupSelected">Please select a group to display statistics for.</div>
