import { Component } from '@angular/core';

@Component({
  selector: 'app-templates-page',
  templateUrl: './templates-page.component.html',
  styleUrls: ['./templates-page.component.scss'],
})
export class TemplatesPageComponent {
  navLinks: MenuItem[] = [
    { url: './list', label: 'Your Templates' },
    { url: './create', label: 'Create Template' },
  ];

  constructor() {}
}
