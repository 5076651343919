import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GeoFencesRoutingModule } from './geo-fences-routing.module';
import { GeoFencesComponent } from './geo-fences.component';
import { SharedModule } from '@/app/shared.module';
import { ViewGeofenceModalComponent } from './modals/view-geofence-modal/view-geofence-modal.component';
import { CreateGeoFencesPageComponent } from './pages/create-geofences-page/create-geofences-page.component';
import { YourGeoFencesPageComponent } from './pages/your-geofences-page/your-geofences-page.component';

@NgModule({
  declarations: [
    GeoFencesComponent,
    ViewGeofenceModalComponent,
    CreateGeoFencesPageComponent,
    YourGeoFencesPageComponent,
  ],
  imports: [CommonModule, GeoFencesRoutingModule, SharedModule],
})
export class GeoFencesModule {}
