import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { attachParametersToUrl } from './utils';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FeedsRepositoryService {
  constructor(private http: HttpClient) {}

  getStats(params: IStatInput): Observable<IFeedStats> {
    const url = '/2/feeds/stats';
    return this.http.get<IFeedStats>(attachParametersToUrl(url, params));
  }

  getAdminFeed(params: IFeedInput): Observable<IFeedPosts> {
    const url = '/2/feeds/admin';
    return this.http.get<IFeedPosts>(attachParametersToUrl(url, params));
  }

  getFeaturedPosts(): Observable<IFeedPosts> {
    const url = '/2/feeds/featured';
    return this.http.get<IFeedPosts>(url);
  }

  deleteFeedItem(id: string) {
    const url = '/2/feeds/admin/' + id;
    return this.http.delete<any>(url);
  }

  postComment(postId: string, body: { comment: string }): Observable<null> {
    const url = '/2/feeds/' + postId + '/comments';
    return this.http.post<any>(url, body);
  }

  getFeedItem(postId: string): Observable<IFeedPost> {
    const url = '/2/feeds/' + postId;
    return this.http.get<IFeedPost>(url);
  }

  // I think the design of this endpoint isn't RESTful. PUT should have a body rather than URL parameters.
  pinPostHours(postId: string, params: { hours: number }): Observable<any> {
    const url = '/2/feeds/admin/' + postId + '/stayontopforhours';
    return this.http.put<any>(attachParametersToUrl(url, params), {});
  }

  approveReportedPost(postId: string): Observable<any> {
    const url = '/2/feeds/admin/' + postId + '/approve';
    return this.http.put<any>(url, {});
  }

  pinPostForever(postId: string) {
    const url = '/2/feeds/admin/' + postId + '/stayontopforever';
    return this.http.put<any>(url, {});
  }

  unPinFeedItem(id: string) {
    const url = '/2/feeds/admin/' + id + '/stayontop';
    return this.http.delete<any>(url);
  }

  deleteUserPosts(id: string) {
    const url = '/2/feeds/removeuser/' + id;
    return this.http.delete<any>(url);
  }

  makeEditorsPick(postId: string) {
    const url = '/2/feeds/' + postId + '/feature';
    return this.http.put<any>(url, {});
  }

  removeEditorsPick(postId: string) {
    const url = '/2/feeds/' + postId + '/feature';
    return this.http.delete<any>(url);
  }

  searchFeedComments(params: IFeedCommentSearchInput) {
    const url = '/2/feeds/comments/search';
    return this.http.get<IFeedComment[]>(attachParametersToUrl(url, params));
  }
}

export interface IFeedStats {
  newComments: number;
  newPosts: number;
  postViews: number;
}

export interface IStatInput {
  to: string;
  from: string;
  groupid?: string;
}

export interface IFeedInput {
  stayOnTop?: boolean; // filters pinned to top posts only
  groupId?: string;
  reportedOnly?: boolean;
  cursor?: string; // for getting more posts
  userId?: string;
  limit?: number;
}

export interface IFeedPosts {
  cursor: string;
  metaData: any;
  results: IFeedPost[];
}

export interface IFeedPost {
  comments: IFeedComment[];
  endDate?: string; // is this to show when the post will stop being featured?
  featured: boolean;
  feedUrls: any[];
  globalFeedId: string;
  going: boolean;
  groupRepresentative: boolean;
  hasDetail: boolean;
  id: string;
  image: string;
  imageRatio: number;
  liked: boolean;
  location: ILatLong;
  mentions: any[];
  media: IFeedMedia[];
  metaData: IFeedPostMeta;
  posted: string;
  postedBy: string;
  postedByName: string;
  rank: number;
  startDate: string;
  stayOnTop: number;
  text: string;
  thumbnailImage: string;
  type: string;
  userImage: boolean;
  version: string;
}

export interface IFeedMedia {
  objectId: string;
  previewUrl: string;
  ratio: number;
  type: string;
  url: string;
}

export interface ILatLong {
  longitude: number;
  latitude: number;
}

export interface IFeedPostMeta {
  comments: number;
  likes: number;
  reports: number;
  flagged: boolean;
  canfollow: boolean;
  verified: boolean;
  following: boolean;
}

export interface IFeedComment {
  comment?: string;
  feedUrls?: any[];
  id?: string;
  liked?: false;
  metaData?: IFeedPostMeta;
  posted?: string;
  postedBy?: string;
  postedByName?: string;
  thumbnailImage?: string;
  version?: string;
}

export interface IFeedCommentSearchInput {
  text: string;
  limit?: number;
}
