import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BookingRepositoryService } from '@/app/services/repositories/booking-repository.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { Money } from '@travello/grpc-api/js/google/type/money_pb';
import { BookingPayment, BookingResponse } from '@travello/grpc-api/js/product_pb';

export interface RowElement {
  key: string;
  value: string;
}

@Component({
  selector: 'app-booking-query',
  templateUrl: './booking-query.component.html',
  styleUrls: ['./booking-query.component.scss'],
})
export class BookingQueryComponent implements OnInit {
  queryForm: UntypedFormGroup;
  dataSource = new MatTableDataSource<RowElement>();

  columnsToDisplay = ['key', 'value'];

  constructor(
    public formBuilder: UntypedFormBuilder,
    public bookingRepositoryService: BookingRepositoryService,
    public toastr: ToastrService
  ) {}

  createForm() {
    this.queryForm = this.formBuilder.group({
      bookingId: new UntypedFormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.createForm();
  }

  private toMoneyString(money: Money): string {
    return money.getUnits().toString() + '.' + money.getNanos() + ' ' + money.getCurrencyCode();
  }

  private toPaymentStatusString(status: BookingPayment.Status): string {
    switch (status) {
      case BookingPayment.Status.PAID:
        return 'PAID';
      case BookingPayment.Status.PENDING:
        return 'PENDING';
      case BookingPayment.Status.DECLINED:
        return 'DECLINED';
    }
    return 'UNKNOWN';
  }

  private toBookingStatusString(status: BookingResponse.Status): string {
    switch (status) {
      case BookingResponse.Status.BOOKED:
        return 'BOOKED';
      case BookingResponse.Status.UNKNOWN:
        return 'UNKNOWN';
      case BookingResponse.Status.CANCELLED:
        return 'CANCELLED';
    }
    return 'UNKNOWN';
  }

  queryBooking(): void {
    this.bookingRepositoryService.queryBooking(this.queryForm.get('bookingId').value).then((result) => {
      this.dataSource = new MatTableDataSource<RowElement>();
      this.dataSource.data.push({ key: 'Product Name', value: result.getProductName() });
      this.dataSource.data.push({ key: 'Booking Id', value: result.getBookingId() });
      if (result.getPayment()) {
        this.dataSource.data.push({ key: 'Payment Reference', value: result.getPayment().getPaymentReference() });
        this.dataSource.data.push({
          key: 'Payment Status',
          value: this.toPaymentStatusString(result.getPayment().getStatus()),
        });
        this.dataSource.data.push({ key: 'Payment PromoCode', value: result.getPayment().getPromoCode() });
        this.dataSource.data.push({
          key: 'Payment Breakdown Points Value',
          value: this.toMoneyString(result.getPayment().getPriceBreakdown().getPointsValue()),
        });
        this.dataSource.data.push({
          key: 'Payment Breakdown Final Value',
          value: this.toMoneyString(result.getPayment().getPriceBreakdown().getFinalPrice()),
        });
        this.dataSource.data.push({
          key: 'Payment Breakdown Standard Price',
          value: this.toMoneyString(result.getPayment().getPriceBreakdown().getStandardPrice()),
        });
        this.dataSource.data.push({
          key: 'Payment Breakdown Points Count',
          value: result.getPayment().getPriceBreakdown().getPointsCount().toString(),
        });
      }
      this.dataSource.data.push({ key: 'Duration Minutes', value: result.getDurationMins().toString() });
      this.dataSource.data.push({ key: 'Status', value: this.toBookingStatusString(result.getStatus()) });
      this.dataSource.data.push({ key: 'External System Id', value: result.getExternalSystemId().toString() });
      this.dataSource.data.push({
        key: 'External System Reference Id',
        value: result.getExternalSystemReferenceId().toString(),
      });
      this.dataSource.data.push({ key: 'Number Of Tickets', value: result.getNumberOfTickets().toString() });
      this.dataSource.data.push({ key: 'Start Date', value: result.getStartDate().toString() });
      this.dataSource.data.push({ key: 'Start Time', value: result.getStartTime().toString() });
      this.dataSource.data.push({ key: 'User Id', value: result.getUserId().toString() });
      this.dataSource.data.push({ key: 'Passengers List', value: result.getPassengersList().toString() });
    },
      (result) => {
        this.toastr.error('Booking Number is Invalid and no booking can be found');
      });
  }

  getCurrentUserId() {
    return this.dataSource.data.find(element => element.key === 'User Id').value;
  }
}
