<div>
  <form
    (ngSubmit)="nameFormControl.valid && saveGeoFence()"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="1em"
  >
    <mat-form-field fxFlex="40">
      <input matInput placeholder="Name" [formControl]="nameFormControl" />
      <mat-hint>Enter a name to identify your new GeoFence</mat-hint>
      <mat-error> Name is <strong>required</strong> </mat-error>
    </mat-form-field>
    <button mat-raised-button color="primary" type="submit" [disabled]="!valid">Save</button>
  </form>

  <br />

  <div class="create-geofence-map">
    <div id="search-bar">
      <app-map-address-search-bar (addressSelected)="moveToAddress($event)"></app-map-address-search-bar>
    </div>

    <div fxLayout="row">
      <div fxFlex="4"></div>
      <div class="fence-info">
        <div *ngIf="valid">
          Fence Radius: {{ circles[0]?.getRadius() / 1000 | number: '1.0-1' }}km, Centre:
          {{ circles[0]?.getCenter()?.lat() }},{{ circles[0]?.getCenter()?.lng() }}
        </div>
        <div *ngIf="!valid">Click map to create a fence</div>
      </div>
      <div fxFlex></div>
    </div>

    <google-map
      #map
      [width]="'100%'"
      [center]="{ lat: latitude, lng: longitude }"
      [zoom]="zoom"
      (mapClick)="mapClicked($event)"
      (zoomChanged)="updateZoomLevel(map)"
    >
      <map-circle
        *ngFor="let circle of circles; let i = index"
        #mapCircle="mapCircle"
        [options]="circleOptions"
        [center]="circle.getCenter()"
        [radius]="circle.getRadius()"
        (centerChanged)="circleChange(mapCircle, i)"
        (radiusChanged)="circleChange(mapCircle, i)"
      >
      </map-circle>
    </google-map>
  </div>
</div>
