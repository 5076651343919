import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { lastValueFrom, UnaryFunction } from 'rxjs';
import { IUser } from '@/app/services/repositories/user-repository.service';
import { ToastrService } from 'ngx-toastr';
import { SessionService, getAccessLevel } from './session.service';
import { AccessLevel } from './session.store';
import { IGroupInfo } from '@/app/services/repositories/groups-repository.service';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';

export type AccessPipeGenerator = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => AccessPipe;
export type AccessPipe = UnaryFunction<
  { currentUser: IUser | null; accessLevel: AccessLevel; currentAdminGroup: IGroupInfo },
  Promise<boolean | any[]>
>;

@Injectable({
  providedIn: 'any',
})
export class AccessGuard implements CanActivate {
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private session: SessionService,
    private autoLoginPartialRouteGuard: AutoLoginPartialRoutesGuard
  ) {}

  // eslint-disable-next-line arrow-body-style
  canActivate = async (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const can = await lastValueFrom(this.autoLoginPartialRouteGuard.canActivate(next, state));

    if (can !== true) {
      return can;
    }

    const newState = await this.session.syncOnce();
    const accessLevel = getAccessLevel(newState);
    const accessGuardPipe = next.data.accessGuardPipe as AccessPipeGenerator;
    const canAccess = await accessGuardPipe(
      next,
      state
    )({ currentUser: newState.currentUser, accessLevel, currentAdminGroup: newState.currentAdminGroup });
    if ((newState.currentUser && !canAccess) || (canAccess !== true && !!canAccess)) {
      this.toastr.error(`Sorry, looks like you don't have sufficient access for this page.`);
    }
      return typeof canAccess === 'boolean' ? canAccess : this.router.createUrlTree(canAccess as any[]);
  };
}

export const canActivateWithAccessGuard = (p: AccessPipeGenerator) => ({
  canActivate: [AccessGuard],
  data: { accessGuardPipe: p },
});
