import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum EDashCardColor {
  Red = 'red',
  Blue = 'blue',
  Purple = 'purple',
  Green = 'green',
}

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
})
export class DashboardCardComponent {
  eDashCardColor = EDashCardColor;

  // initialize a private variable _data, it's a BehaviorSubject
  private _data = new BehaviorSubject<number>(0);

  @Input()
  set data(value) {
    // set the latest value for _data BehaviorSubject
    this._data.next(value);
  }

  get data() {
    // get the latest value from _data BehaviorSubject
    return this._data.getValue();
  }

  @Input()
  statTitle: string;
  @Input()
  color: EDashCardColor = EDashCardColor.Red;
  @Input()
  icon = 'insert_chart';
}
