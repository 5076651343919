<mat-toolbar color="primary">
  <span>Group Info</span>
</mat-toolbar>

<div class="page-content" style="min-height: 400px">
  <div fxLayout="row">
    <div fxFlex="40">
      <h2 class="group-name" *ngIf="groupInfo">{{ groupInfo.name }}</h2>
      <div class="group-info-coverpic">
        <img class="fit-parent" *ngIf="groupInfo" [src]="groupInfo.backgroundURL" />
      </div>
      <div class="group-info-userpic">
        <img class="fit-parent" *ngIf="groupInfo" [src]="groupInfo.imageURL" />
      </div>
    </div>
    <div fxFlex="60">
      <mat-tab-group>
        <mat-tab label="Group Info">
          <h3 class="tab-header">Group Info</h3>
          <hr />
          <p class="info-label">
            <span class="info-label-title">Group Name:</span>
            <span *ngIf="groupInfo">{{ groupInfo.name }}</span>
          </p>
          <p class="info-label">
            <span class="info-label-title">Website:</span>
            <span *ngIf="groupInfo">{{ groupInfo.websiteUrl }}</span>
          </p>
          <p class="info-label">
            <span class="info-label-title">Group Type:</span>
            <span *ngIf="groupInfo">{{ groupInfo.type }}</span>
          </p>
          <p class="info-label">
            <span class="info-label-title">Public:</span>
            <span *ngIf="groupInfo">
              <span *ngIf="groupInfo.groupPublic">Yes</span>
              <span *ngIf="!groupInfo.groupPublic">No</span>
            </span>
          </p>
          <p class="info-label">
            <span class="info-label-title">Share-URL:</span>
            <span *ngIf="groupInfo">{{ groupInfo.publicUrl }}</span>
          </p>
          <p class="info-label">
            <span class="info-label-title">Description:</span>
            <br />
            <span *ngIf="groupInfo">{{ groupInfo.description }}</span>
          </p>
          <br />
        </mat-tab>

        <mat-tab label="Edit Info">
          <h3 class="tab-header">Edit Info</h3>
          <hr />
          <form [formGroup]="infoForm" (ngSubmit)="infoForm.valid && saveInfo()" fxLayout="column" fxLayoutGap="1em">
            <mat-form-field>
              <input matInput placeholder="Group Name" type="text" formControlName="name" required />
              <mat-error> This is <strong>required</strong> </mat-error>
            </mat-form-field>
            <mat-form-field>
              <input matInput placeholder="Website" type="text" formControlName="website" required />
              <mat-error> This is <strong>required</strong> </mat-error>
            </mat-form-field>
            <mat-form-field class="fill-parent">
              <mat-select formControlName="type" placeholder="Group Type" required>
                <mat-option value="INTEREST">Interest</mat-option>
                <mat-option value="AGENCY">Agency</mat-option>
              </mat-select>
              <mat-error> This is <strong>required</strong> </mat-error>
            </mat-form-field>
            <mat-form-field class="fill-parent">
              <mat-select formControlName="isPublic" placeholder="Public" required>
                <mat-option value="true">Yes</mat-option>
                <mat-option value="false">No</mat-option>
              </mat-select>
              <mat-error> This is <strong>required</strong> </mat-error>
            </mat-form-field>
            <button mat-raised-button color="primary" type="submit" [disabled]="editInfoSpinner">Save Info</button>
            <div *ngIf="editInfoSpinner" class="spinner-container">
              <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
            </div>
          </form>
        </mat-tab>

        <mat-tab label="Edit Description">
          <h3 class="tab-header">Edit Description</h3>
          <p><em>Editing this may not do anything as it may get overwritten by translated copy</em></p>
          <hr />
          <form
            [formGroup]="descriptionForm"
            (ngSubmit)="descriptionForm.valid && saveDescription()"
            fxLayout="column"
            fxLayoutGap="1em"
          >
            <mat-form-field>
              <textarea
                matInput
                placeholder="Description"
                type="text"
                rows="5"
                formControlName="description"
                required
              ></textarea>
              <mat-error> This is <strong>required</strong> </mat-error>
            </mat-form-field>

            <button [disabled]="editDescSpinner" mat-raised-button color="primary" type="submit">Save Info</button>
            <div *ngIf="editDescSpinner" class="spinner-container">
              <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
            </div>
          </form>
        </mat-tab>

        <mat-tab label="Upload Logo">
          <h3 class="tab-header">Upload Logo</h3>
          <hr />
          <div class="form-inputs" [formGroup]="logoPhotoForm">
            <div fxLayout="column" fxLayoutAlign="center center">
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1em" class="fill-parent">
                <button type="button" mat-raised-button color="accent" (click)="hiddenLogoPhotoInput.click()">
                  <mat-icon >camera_alt</mat-icon>Select
                </button>
                <input
                  #hiddenLogoPhotoInput
                  hidden
                  [multiple]="false"
                  name="file"
                  type="file"
                  accept=".jpg,.png"
                  (change)="onLogoPhotoChange($event)"
                />
                <mat-form-field
                  fxFlex
                  hintLabel="Upload your company logo here. JPEG and PNG images only."
                  class="fill-parent"
                >
                  <input matInput placeholder="Logo Photo" formControlName="logo" [readonly]="true" required />
                </mat-form-field>
              </div>
            </div>
          </div>
          <br />
          <div fxLayout="row" fxLayoutAlign="space-evenly center" *ngIf="croppedLogoPhoto">
            <app-photo-card [image]="croppedLogoPhoto" (deleteClicked)="deleteLogo()"></app-photo-card>
            <button [disabled]="logoSpinner" mat-raised-button color="primary" type="button" (click)="saveLogo()">
              Save
            </button>
            <div *ngIf="logoSpinner" class="spinner-container">
              <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Upload Cover Photo">
          <h3 class="tab-header">Upload Cover Photo</h3>
          <hr />
          <div class="form-inputs" [formGroup]="coverPhotoForm">
            <div fxLayout="column" fxLayoutAlign="center center">
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1em" class="fill-parent">
                <button type="button" mat-raised-button color="accent" (click)="hiddenCoverPhotoInput.click()">
                  <mat-icon >camera_alt</mat-icon>Select
                </button>
                <input
                  #hiddenCoverPhotoInput
                  hidden
                  [multiple]="false"
                  name="file"
                  type="file"
                  accept=".jpg,.png"
                  (change)="onCoverPhotoChange($event)"
                />
                <mat-form-field
                  fxFlex
                  hintLabel="Upload a cover phot here. JPEG and PNG images only."
                  class="fill-parent"
                >
                  <input matInput placeholder="Cover Photo" formControlName="cover" [readonly]="true" required />
                </mat-form-field>
              </div>
            </div>
          </div>
          <br />
          <div fxLayout="row" fxLayoutAlign="space-evenly center" *ngIf="croppedCoverPhoto">
            <app-photo-card [image]="croppedCoverPhoto" (deleteClicked)="deleteCover()"></app-photo-card>
            <button mat-raised-button color="primary" type="button" [disabled]="covPhotoSpinner" (click)="saveCover()">
              Save
            </button>
            <div *ngIf="covPhotoSpinner" class="spinner-container">
              <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Group Admins">
          <h3 class="tab-header">Group Admins</h3>
          <hr />
          <form #newAdminForm="ngForm">
            <div fxLayout fxLayoutAlign="center center" fxLayoutGap="1vw">
              <mat-form-field hintLabel="Enter new admin here">
                <input matInput placeholder="New Admin Email" [(ngModel)]="newAdminEmail" name="newAdminEmail" />
              </mat-form-field>
              <div class="button-div">
                <button
                  mat-raised-button
                  color="primary"
                  [disabled]="newGroupAdminSpinner"
                  type="button"
                  (click)="addNewAdmin(newAdminForm); newAdminForm.reset()"
                >
                  Save
                </button>
              </div>
              <div *ngIf="newGroupAdminSpinner" class="spinner-container">
                <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
              </div>
            </div>
          </form>
          <br />
          <div class="mat-elevation-z8 table-div">
            <mat-table #table [dataSource]="groupAdmins">
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
              <ng-container matColumnDef="name">
                <mat-header-cell fxFlex="30" *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell fxFlex="30" *matCellDef="let element">
                  <div *ngIf="element.userName">
                    {{ element.userName }}
                  </div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="email">
                <mat-header-cell fxFlex="30" *matHeaderCellDef> Email </mat-header-cell>
                <mat-cell fxFlex="30" *matCellDef="let element">
                  <div *ngIf="element.email">
                    {{ element.email }}
                  </div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="actions">
                <mat-header-cell fxFlex="40" *matHeaderCellDef> Actions </mat-header-cell>
                <mat-cell fxFlex="40" *matCellDef="let element">
                  <div fxLayout="row" fxLayoutGap="2em">
                    <button mat-raised-button color="warn" (click)="deleteAdmin(element)">Delete</button>
                  </div>
                </mat-cell>
              </ng-container>
            </mat-table>
          </div>
        </mat-tab>

        <mat-tab label="Marketing User">
          <h3 class="tab-header">Marketing User</h3>
          <hr />
          <form #newMarketUserForm="ngForm">
            <div fxLayout fxLayoutAlign="center center" fxLayoutGap="1vw">
              <mat-form-field hintLabel="Enter new marketing user here">
                <input matInput placeholder="Email" [(ngModel)]="newMarketingUserEmail" name="newMarketingUserEmail" />
              </mat-form-field>
              <div class="button-div">
                <button
                  mat-raised-button
                  color="primary"
                  [disabled]="newMarketingUserSpinner"
                  type="button"
                  (click)="addNewMarketUser(newMarketUserForm); newMarketUserForm.reset()"
                >
                  Save
                </button>
              </div>
              <div *ngIf="newMarketingUserSpinner" class="spinner-container">
                <mat-spinner [diameter]="30" [strokeWidth]="5"></mat-spinner>
              </div>
            </div>
          </form>
          <br />
          <div class="mat-elevation-z8 table-div">
            <mat-table #table [dataSource]="marketingUser">
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
              <ng-container matColumnDef="name">
                <mat-header-cell fxFlex="30" *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell fxFlex="30" *matCellDef="let element">
                  <div *ngIf="element.userName">
                    {{ element.userName }}
                  </div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="email">
                <mat-header-cell fxFlex="30" *matHeaderCellDef> Email </mat-header-cell>
                <mat-cell fxFlex="30" *matCellDef="let element">
                  <div *ngIf="element.email">
                    {{ element.email }}
                  </div>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="actions">
                <mat-header-cell fxFlex="40" *matHeaderCellDef> Actions </mat-header-cell>
                <mat-cell fxFlex="40" *matCellDef="let element">
                  <div fxLayout="row" fxLayoutGap="2em">
                    <button mat-raised-button color="warn" (click)="deleteMarketUser(element)">Delete</button>
                  </div>
                </mat-cell>
              </ng-container>
            </mat-table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
