import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IGeosegment, MarketingRepositoryService } from '@/app/services/repositories/marketing-repository.service';
import { IUser, UserRepositoryService } from '@/app/services/repositories/user-repository.service';

import { Map as OlMap, View } from 'ol';
import { OSM, Stamen, TileJSON } from 'ol/source';
import { addCommon, fromLonLat, getPointResolution, toLonLat, transform } from 'ol/proj';
import { METERS_PER_UNIT } from 'ol/proj/Units';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import { Heatmap as HeatmapLayer, Layer, Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { defaults as defaultControls } from 'ol/control';

import VectorSource from 'ol/source/Vector.js';
import Feature from 'ol/Feature.js';
import { Circle, Point } from 'ol/geom';
import GeoJSON from 'ol/format/GeoJSON.js';
import { bbox as bboxStrategy } from 'ol/loadingstrategy.js';
import {
  LabelledProperty,
  ManagedLayer,
  MapSettings,
  MapSettingsDialogComponent,
} from './sub-sections/map-settings-dialog/map-settings-dialog.component';
import { getDistance } from 'ol/sphere.js';
import { getBottomLeft, getTopRight } from 'ol/extent.js';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { SessionService } from '@/app/core/auth/session.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GeoserverRepositoryService } from '@/app/services/repositories/geoserver-repository.service';
import { BehaviorSubject } from 'rxjs';
import { GeolocationService } from '@/app/services/geolocation.service';
import { take } from 'rxjs/operators';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';

addCommon(); // workaround for bug in OpenLayers: https://github.com/openlayers/openlayers/issues/8815

const iconStyle = new Style({
  image: new Icon({
    anchor: [0.5, 1],
    anchorXUnits: IconAnchorUnits.FRACTION,
    anchorYUnits: IconAnchorUnits.FRACTION,
    src: 'assets/marker_32.png',
  }),
});
const fenceStyle = new Style({
  fill: new Fill({
    color: '#fc8d8d44',
  }),
  stroke: new Stroke({
    color: 'red',
  }),
});
const markerMaxResolution = 150;

@UntilDestroy()
@Component({
  selector: 'app-nearby-page',
  templateUrl: './nearby-page.component.html',
  styleUrls: ['./nearby-page.component.scss'],
})
export class NearbyPageComponent implements OnInit {
  users: IUser[] = [];
  geoFences: IGeosegment[] = [];
  totalUsersNearHere = 0;

  // default search parameters for user search
  extended = true;
  limit = 2500;
  fenceSelected = false;

  map: OlMap;
  markers: VectorSource = new VectorSource();
  fences: VectorSource = new VectorSource();

  selectedGroupSrc = new BehaviorSubject(this.currentUser.defaultAdminGroup);

  get selectedGroup() {
    return this.selectedGroupSrc.getValue();
  }

  selectedGroup$ = this.selectedGroupSrc.asObservable();

  stamenLines: Layer = new TileLayer({
    source: new Stamen({
      layer: 'toner-lines',
    }),
  });
  stamenLabels: Layer = new TileLayer({
    source: new Stamen({
      layer: 'toner-labels',
    }),
  });

  baseLayers: ManagedLayer[] = [
    {
      name: 'Toner Lite',
      layer: new TileLayer({
        source: new Stamen({
          layer: 'toner-lite',
        }),
      }),
      usesOverlays: true,
    },
    {
      name: 'Toner',
      layer: new TileLayer({
        source: new Stamen({
          layer: 'toner',
        }),
        visible: false,
      }),
      usesOverlays: true,
    },
    {
      name: 'Stamen Terrain',
      layer: new TileLayer({
        source: new Stamen({
          layer: 'terrain',
        }),
        visible: false,
      }),
      usesOverlays: false,
    },
    {
      name: 'Open Street Map',
      layer: new TileLayer({
        source: new OSM(),
        visible: false,
      }),
      usesOverlays: false,
    },
    {
      name: 'MapTiler Basic',
      layer: new TileLayer({
        source: new TileJSON({
          url: 'https://api.maptiler.com/maps/basic/256/tiles.json?key=sj7q6rAaj6D5X07OuGbI',
          crossOrigin: 'anonymous',
        }),
        visible: false,
      }),
      usesOverlays: false,
    },
    {
      name: 'MapTiler Bright',
      layer: new TileLayer({
        source: new TileJSON({
          url: 'https://api.maptiler.com/maps/bright/256/tiles.json?key=sj7q6rAaj6D5X07OuGbI',
          crossOrigin: 'anonymous',
        }),
        visible: false,
      }),
      usesOverlays: false,
    },
    {
      name: 'MapTiler Dark Matter',
      layer: new TileLayer({
        source: new TileJSON({
          url: 'https://api.maptiler.com/maps/darkmatter/256/tiles.json?key=sj7q6rAaj6D5X07OuGbI',
          crossOrigin: 'anonymous',
        }),
        visible: false,
      }),
      usesOverlays: false,
    },
    {
      name: 'MapTiler Satellite Hybrid',
      layer: new TileLayer({
        source: new TileJSON({
          url: 'https://api.maptiler.com/maps/hybrid/256/tiles.json?key=sj7q6rAaj6D5X07OuGbI',
          crossOrigin: 'anonymous',
        }),
        visible: false,
      }),
      usesOverlays: false,
    },
    {
      name: 'MapTiler Pastel',
      layer: new TileLayer({
        source: new TileJSON({
          url: 'https://api.maptiler.com/maps/pastel/256/tiles.json?key=sj7q6rAaj6D5X07OuGbI',
          crossOrigin: 'anonymous',
        }),
        visible: false,
      }),
      usesOverlays: false,
    },
    {
      name: 'MapTiler Positron',
      layer: new TileLayer({
        source: new TileJSON({
          url: 'https://api.maptiler.com/maps/positron/256/tiles.json?key=sj7q6rAaj6D5X07OuGbI',
          crossOrigin: 'anonymous',
        }),
        visible: false,
      }),
      usesOverlays: false,
    },
    {
      name: 'MapTiler Streets',
      layer: new TileLayer({
        source: new TileJSON({
          url: 'https://api.maptiler.com/maps/streets/256/tiles.json?key=sj7q6rAaj6D5X07OuGbI',
          crossOrigin: 'anonymous',
        }),
        visible: false,
      }),
      usesOverlays: false,
    },
    {
      name: 'MapTiler Topo',
      layer: new TileLayer({
        source: new TileJSON({
          url: 'https://api.maptiler.com/maps/topo/256/tiles.json?key=sj7q6rAaj6D5X07OuGbI',
          crossOrigin: 'anonymous',
        }),
        visible: false,
      }),
      usesOverlays: false,
    },
    {
      name: 'MapTiler Topographique',
      layer: new TileLayer({
        source: new TileJSON({
          url: 'https://api.maptiler.com/maps/topographique/256/tiles.json?key=sj7q6rAaj6D5X07OuGbI',
          crossOrigin: 'anonymous',
        }),
        visible: false,
      }),
      usesOverlays: false,
    },
    {
      name: 'MapTiler Voyager',
      layer: new TileLayer({
        source: new TileJSON({
          url: 'https://api.maptiler.com/maps/voyager/256/tiles.json?key=sj7q6rAaj6D5X07OuGbI',
          crossOrigin: 'anonymous',
        }),
        visible: false,
      }),
      usesOverlays: false,
    },
  ];

  weightProperties: LabelledProperty[] = [
    {
      name: 'total_time_spent',
      label: 'Time Spent',
    },
    {
      name: 'num_users',
      label: '#Users (7d)',
    },
    {
      name: 'num_users_recent',
      label: '#Users (1d)',
    },
  ];

  heatLayers: Map<string, HeatmapLayer> = new Map();
  maxHeatWeights: Map<string, Map<string, number>> = new Map();

  mapSettings: MapSettings = MapSettingsDialogComponent.loadSettings(this.baseLayers, this.weightProperties);
  loading = 0;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public userService: UserRepositoryService,
    public currentUser: SessionService,
    public marketingService: MarketingRepositoryService,
    public router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private geoserverRepository: GeoserverRepositoryService,
    private geolocation: GeolocationService
  ) {}

  get currentAdminGroupId() {
    return this.currentUser.currentAdminGroup?.groupId;
  }

  ngOnInit() {
    const layers = this.baseLayers
      .map((v) => v.layer)
      .concat(
        new VectorLayer({
          source: this.fences,
          style: fenceStyle,
        }),
        this.createHeatLayer('geohash_grouped_l4', 80000, 10000),
        this.createHeatLayer('geohash_grouped_l5', 10000, 1000),
        this.createHeatLayer('geohash_grouped_l6', 1000, 100),
        this.createHeatLayer('geohash_grouped_l7', 100, 10),
        this.stamenLines,
        this.stamenLabels,
        new VectorLayer({
          source: this.markers,
          maxResolution: markerMaxResolution,
        })
      );
    this.setBaseLayer(this.mapSettings.baseLayer);

    this.map = new OlMap({
      controls: defaultControls(),
      target: 'map',
      layers,
      view: new View({
        center: [0, 0],
        zoom: 5,
        minZoom: 1,
      }),
    });

    this.geolocation.position$.pipe(take(1)).subscribe((pos) => {
      const coords = fromLonLat([pos.coords.longitude, pos.coords.latitude]);
      this.map.getView().animate({ center: coords, zoom: 5 });
    });

    this.map.on('moveend', () => this.getUsers());
    this.map.getView().on('change:resolution', () => this.updateHeatLayers());
    this.updateHeatLayers();

    this.getGeoFences();

    this.route.paramMap.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateGroup();
    });

    this.currentUser.currentAdminGroup$.pipe(untilDestroyed(this)).subscribe((g) => {
      this.updateGroup();
      this.getGeoFences();
    });

    this.selectedGroup$.pipe(untilDestroyed(this)).subscribe(() => {
      this.heatLayers.forEach((layer, layerName) => {
        layer.setSource(this.getHeatmapSource(layerName));
      });
      this.getUsers();
    });
  }

  private updateGroup() {
    const style = this.route.snapshot.paramMap.get('style');
    this.selectedGroupSrc.next(
      style === 'all' ? this.currentUser.defaultAdminGroup : this.currentUser.currentAdminGroup
    );
  }

  private createHeatLayer(layerName, maxResolution, minResolution) {
    const vectorSource = this.getHeatmapSource(layerName);
    const heatLayer = new HeatmapLayer({
      source: vectorSource,
      opacity: this.mapSettings.heatmapSettings.opacity,
      radius: this.mapSettings.heatmapSettings.radius,
      blur: this.mapSettings.heatmapSettings.blur,
      gradient: ['#0288d1', '#039be5', '#A39691', '#E89B14', '#E9531B'],
      maxResolution,
      minResolution,
      weight: (feature) => {
        const max = this.maxHeatWeights.get(layerName).get(this.mapSettings.weightProperty.name);
        const number = feature.get(this.mapSettings.weightProperty.name) / max;
        if (!(number >= 0 && number <= 1)) {
          console.log('Invalid heatmap weight: ' + number);
        }
        return number;
      },
    });
    this.heatLayers.set(layerName, heatLayer);
    return heatLayer;
  }

  private getHeatmapSource(layerName) {
    const vectorSource = new VectorSource({
      format: new GeoJSON(),
      loader: (extent, resolution, projection) => {
        this.loadHeatmapSource(projection, layerName, extent, vectorSource);
      },
      strategy: bboxStrategy,
    });
    return vectorSource;
  }

  private async loadHeatmapSource(projection: any, layerName: any, extent: any, vectorSource: any) {
    this.loading++;
    const proj = projection.getCode();

    try {
      const res = await this.geoserverRepository.loadHeatmapSource(layerName, proj, extent, this.selectedGroup.groupId);
      this.loading--;
      const features = vectorSource.getFormat().readFeatures(res);
      const maxWeights = new Map();
      for (let i = 0; i < this.weightProperties.length; i++) {
        const weightProperty = this.weightProperties[i];
        let maxWeight = Number.MIN_VALUE;
        for (const feature of features) {
          const numUsers = feature.get(weightProperty.name);
          if (numUsers > maxWeight) {
            maxWeight = numUsers;
          }
        }
        maxWeights.set(weightProperty.name, maxWeight);
      }
      this.maxHeatWeights.set(layerName, maxWeights);
      vectorSource.addFeatures(features);
    } catch {
      this.loading--;
      vectorSource.removeLoadedExtent(extent);
    }
  }

  updateHeatLayers() {
    const resolution = this.map.getView().getResolution();
    this.heatLayers.forEach((heatLayer) => {
      heatLayer.setRadius(this.mapSettings.heatmapSettings.radius);
      heatLayer.setBlur(this.mapSettings.heatmapSettings.blur);
      heatLayer.setOpacity(this.mapSettings.heatmapSettings.opacity);
      if (resolution < heatLayer.getMaxResolution()) {
        const factor = Math.pow(heatLayer.getMaxResolution() / resolution, this.mapSettings.heatmapSettings.dampening);
        heatLayer.setRadius(this.mapSettings.heatmapSettings.radius * factor);
        heatLayer.setBlur(this.mapSettings.heatmapSettings.blur * factor);
      }
    });
  }

  getUsers() {
    if (this.map.getView().getResolution() >= markerMaxResolution) {
      return [];
    }
    this.loading++;
    return this.getUsersInRadius(this.getDiagonalInMeters() / 2);
  }

  getUsersInRadius(radiusInMeter) {
    const loc = toLonLat(this.map.getView().getCenter());
    this.userService
      .getUsers({
        activeWithin: this.mapSettings.activeWithin,
        extended: this.extended,
        latLong: loc[1] + ',' + loc[0],
        limit: this.limit,
        radius: radiusInMeter / 1000, // radius for user service seems to be km not m...
        radiusStrict: true,
        groupId: this.selectedGroup.groupId,
      })
      .subscribe((result) => {
        this.loading--;
        this.users = result.results;
        this.totalUsersNearHere = result.metaData.total;
        this.updateMarkers();
        this.changeDetectorRef.detectChanges();
      });
  }

  private updateMarkers() {
    if (!this.users) {
      return;
    }
    this.markers.clear();
    const features = [];
    this.users.forEach((u) => {
      if (u.metaData.currentLocation) {
        const point = new Point(
          fromLonLat([u.metaData.currentLocation.longitude, u.metaData.currentLocation.latitude])
        );
        const feature = new Feature({
          geometry: point,
        });
        feature.setStyle(iconStyle);
        features.push(feature);
      }
    });
    this.markers.addFeatures(features);
    this.map.render();
  }

  getGeoFences() {
    if (!this.currentUser.isAdmin && !this.currentAdminGroupId) {
      // group admin can only see their own fences
      return;
    }
    this.marketingService
      .getGeosegments({
        groupId: this.currentAdminGroupId,
      })
      .subscribe(
        (result) =>
          (this.geoFences = result.results.sort(function(a, b) {
            return a.name.localeCompare(b.name); // alphabet sort
          }))
      );
  }

  selectGeoFence(event: MatSelectChange) {
    this.fences.clear();
    this.fenceSelected = true;
    const geoFence: IGeosegment = event.value;
    this.moveMapToLocation(geoFence.location.latitude, geoFence.location.longitude);
    this.displayCircle(geoFence.radius);
    this.getUsers();
  }

  private setBaseLayer(selected: ManagedLayer) {
    for (const layer of this.baseLayers) {
      layer.layer.setVisible(layer === selected);
    }
    this.stamenLines.setVisible(selected.usesOverlays);
    this.stamenLabels.setVisible(selected.usesOverlays);
  }

  moveMapToLocation(lat, long) {
    const coords = fromLonLat([long, lat]);
    this.map.getView().setCenter(coords);
  }

  displayCircle(radius: number) {
    let zoom;
    if (radius <= 500) {
      zoom = 16;
    } else if (radius <= 1000) {
      zoom = 15;
    } else if (radius <= 2000) {
      zoom = 14;
    } else if (radius <= 4000) {
      zoom = 13;
    } else if (radius <= 8000) {
      zoom = 12;
    } else if (radius <= 16000) {
      zoom = 11;
    } else if (radius <= 32000) {
      zoom = 10;
    } else if (radius <= 64000) {
      zoom = 9;
    } else if (radius <= 128000) {
      zoom = 8;
    } else if (radius <= 256000) {
      zoom = 7;
    } else if (radius <= 512000) {
      zoom = 6;
    } else if (radius <= 1028000) {
      zoom = 5;
    } else {
      zoom = 4;
    }
    this.map.getView().setZoom(zoom);
    this.drawCircleInMeter(radius);
  }

  drawCircleInMeter(radius) {
    const view = this.map.getView();
    const projection = view.getProjection();
    const resolutionAtEquator = view.getResolution();
    const center = view.getCenter();
    const pointResolution = getPointResolution(projection, resolutionAtEquator, center);
    const resolutionFactor = resolutionAtEquator / pointResolution;
    const pixelRadius = (radius / METERS_PER_UNIT.m) * resolutionFactor;

    const circle = new Circle(center, pixelRadius);
    const circleFeature = new Feature(circle);

    this.fences.addFeature(circleFeature);
  }

  openMapSettings() {
    const data: any = this.mapSettings;
    data.baseLayers = this.baseLayers;
    data.weightProperties = this.weightProperties;
    const dialogRef = this.dialog.open(MapSettingsDialogComponent, {
      data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.mapSettings = result;
        MapSettingsDialogComponent.saveSettings(this.mapSettings);
        this.setBaseLayer(this.mapSettings.baseLayer);
        this.updateHeatLayers();
        this.getUsers();
      }
    });
  }

  private getDiagonalInMeters() {
    const extent = this.map.getView().calculateExtent();
    return getDistance(
      transform(getBottomLeft(extent), 'EPSG:3857', 'EPSG:4326'),
      transform(getTopRight(extent), 'EPSG:3857', 'EPSG:4326')
    );
  }
}
