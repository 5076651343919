<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef>Rank</th>
    <td mat-cell *matCellDef="let element">{{ element.getRank() }}</td>
  </ng-container>

  <ng-container matColumnDef="points">
    <th mat-header-cell *matHeaderCellDef>Points</th>
    <td mat-cell *matCellDef="let element">{{ element.getPoints() }}</td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">{{ element.getUser().getUserName() }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: columnsToDisplay"
    routerLink="/rewards/user/{{ row.getUser().getId() }}/{{ row.getUser().getUserName() }}"
  ></tr>
</table>
<mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
