import { OnChanges } from '@angular/core';
import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { EChartsOption } from 'echarts';
import { capitalize } from 'lodash';
import { IDataSeriesEntry, COLOUR_PALETTE } from '../constants';
@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnChanges {
  @Input() chartTitle?: string;
  @Input() chartSubTitle?: string;

  @Input() data: IDataSeriesEntry[];

  options: EChartsOption = {};

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.plotChart(this.preprocessData(changes.data.currentValue));
    }
  }

  preprocessData(data: IDataSeriesEntry[]) {
    return data.map((o) => ({ ...o, name: capitalize(o.name) }));
  }

  plotChart(data: any) {
    const total = data.reduce((p, c) => p + c.value, 0);
    const options: EChartsOption = {
      title: {
        text: this.chartTitle,
        subtext: this.chartSubTitle,
      },
      color: COLOUR_PALETTE,
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c} ({d}%)',
      },
      grid: {
        left: 0,
        right: 0,
        bottom: 0,
        containLabel: false,
      },
      graphic: [
        {
          type: 'text',
          left: 'center',
          top: 'center',
          style: {
            font: '26px Roboto,sans-serif',
            text: total ? ['Total', `${total}`].join('\n') : undefined,
            align: 'center'
          },
        },
      ],
      series: [
        {
          type: 'pie',
          radius: ['55%', '80%'],
          data,
          label: {
            formatter: '{b}: {c} ({d}%)',
          },
        },
      ],
    };

    this.options = options;
  }
}
