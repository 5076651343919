<div *ngIf="fromMe" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="end center">
  <div fxFlex></div>
  <div fxFlex="60" class="chat-message-bubble my-message-bubble box-shadow">
    {{ message.message }}
  </div>
  <img class="chat-message-profiler box-shadow" [src]="message.from.profileImage" />
</div>

<div *ngIf="!fromMe" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
  <img class="chat-message-profiler box-shadow" [src]="message.from.profileImage" />
  <div fxFlex="60" class="chat-message-bubble other-party-message-bubble box-shadow">
    {{ message.message }}
  </div>
  <div fxFlex></div>
</div>
