import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FeedsRepositoryService, IFeedComment, IFeedPost } from '@/app/services/repositories/feeds-repository.service';
import { UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SessionService } from '@/app/core/auth/session.service';
import { NumberReportedService } from '@/app/services/number-reported.service';
import { PinToTopModalComponent } from '../../modals/pin-to-top-modal/pin-to-top-modal.component';
@Component({
  selector: 'app-feed-card',
  templateUrl: './feed-card.component.html',
  styleUrls: ['./feed-card.component.scss'],
})
export class FeedCardComponent implements OnInit {
  posted = 'Loading...'; // date
  postedByName = 'Loading...'; // name
  text = 'Loading...'; // caption
  thumbnailImage = null; // profiler
  image = null; // main image
  likes = 0;

  numComments = 0;
  showComments = false;
  comments: IFeedComment[] = [];

  commentForm: UntypedFormGroup;

  @Input()
  cardInfo: IFeedPost;

  // nothing in card info to indicate if its reported?
  @Input()
  reportedPost: boolean;

  @Output() deletePost = new EventEmitter<string>();
  @Output() unPinPost = new EventEmitter<true>();

  @ViewChild(FormGroupDirective, { static: true }) myForm;

  pinToTopModalConfig: MatDialogConfig = new MatDialogConfig();
  dialogRef: MatDialogRef<PinToTopModalComponent>;

  approved = false;
  loading = false;

  constructor(
    public formbuilder: UntypedFormBuilder,
    public feedService: FeedsRepositoryService,
    public dialog: MatDialog,
    public numberReportedService: NumberReportedService,
    public toastr: ToastrService,
    public currentUser: SessionService
  ) {}

  ngOnInit() {
    this.fillInCard(this.cardInfo);
    this.createForm();
  }

  makeEditorsPick() {
    this.feedService.makeEditorsPick(this.cardInfo.id).subscribe(
      () => {
        this.toastr.success('Added to editors picks');
        this.cardInfo.featured = true;
      },
      () => {
        this.toastr.error('Failed to add to editors picks');
      }
    );
  }

  removeEditorsPick() {
    this.feedService.removeEditorsPick(this.cardInfo.id).subscribe(
      () => {
        this.toastr.success('Removed from editors picks');
        this.cardInfo.featured = false;
      },
      () => {
        this.toastr.error('Failed to remove from editors picks');
      }
    );
  }

  fillInCard(info: IFeedPost) {
    this.posted = info.posted;
    this.postedByName = info.postedByName;
    this.text = info.text;
    this.thumbnailImage = info.thumbnailImage;
    this.image = info.image;
    this.likes = info.metaData.likes;
    this.numComments = info.metaData.comments;
  }

  deleteClicked() {
    this.deletePost.emit(this.cardInfo.id);
  }

  createForm() {
    this.commentForm = this.formbuilder.group({
      comment: ['', Validators.required],
    });
  }

  saveComment() {
    this.feedService.postComment(this.cardInfo.id, { comment: this.commentForm.get('comment').value }).subscribe(
      (result) => {
        this.toastr.success('Comment saved');
        this.hideComments();
        this.displayComments();
        // this.commentForm.reset();
        if (this.myForm) {
          this.myForm.resetForm();
        }
      },
      () => {
        this.toastr.error('Comment save failed');
      }
    );
  }

  displayComments() {
    this.showComments = true;
    this.loading = true;
    this.feedService.getFeedItem(this.cardInfo.id).subscribe(
      (result) => {
        this.toastr.success('Retrieved comments');
        this.comments = result.comments.reverse();
        this.numComments = result.metaData.comments;
        this.loading = false;
      },
      () => {
        this.toastr.error('Failed to retrieve comments');
        this.showComments = false;
        this.loading = false;
      }
    );
  }

  hideComments() {
    this.showComments = false;
  }

  openPinModal() {
    this.dialogRef = this.dialog.open(PinToTopModalComponent, this.pinToTopModalConfig);

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 0) {
        this.pinItem();
      }
      if (result > 0) {
        this.pinItemForHours(result);
      }
      this.dialogRef = null;
    });
  }

  unPinItem() {
    this.feedService.unPinFeedItem(this.cardInfo.id).subscribe(
      () => {
        this.toastr.success('Unpinned post');
        this.unPinPost.emit(true);
        this.cardInfo.stayOnTop = undefined;
      },
      () => {
        this.toastr.error('Failed to unpin post');
      }
    );
  }

  pinItem() {
    this.feedService.pinPostForever(this.cardInfo.id).subscribe(
      () => {
        this.toastr.success('Pinned post indefinitely');
        this.cardInfo.stayOnTop = 0;
      },
      () => {
        this.toastr.error('Failed to pin post');
      }
    );
  }

  pinItemForHours(hours: number) {
    this.feedService.pinPostHours(this.cardInfo.id, { hours }).subscribe(
      () => {
        this.toastr.success('Pinned post for ' + hours + ' hours');
        this.cardInfo.stayOnTop = hours;
      },
      () => {
        this.toastr.error('Failed to pin post');
      }
    );
  }

  approvePost() {
    this.feedService.approveReportedPost(this.cardInfo.id).subscribe(
      () => {
        this.toastr.success('Post approved');
        this.approved = true;
        this.numberReportedService.decreaseNumReported();
      },
      () => this.toastr.error('Post approval failed')
    );
  }

  getUserProfileUrl(userName: string) {
    if (this.currentUser.isAdmin) {
      return `/user/${userName}`;
    }
  }
}
