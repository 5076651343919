<div mat-card class="comment-card">
  <dl class="inline-flex">
    <dt>id</dt>
    <dd>{{ cardInfo.id }}</dd>
    <dt>Liked</dt>
    <dd>{{ cardInfo.liked }}</dd>
    <dt>Version</dt>
    <dd>{{ cardInfo.version }}</dd>
  </dl>
</div>
