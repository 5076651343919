import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import sub from 'date-fns/sub';
import parseISO from 'date-fns/parseISO';

export interface IDateRange {
  // Back ends seem to be expect a Date.toIsoString string for dates
  // e.g. from=2017-10-31T14:00:00.000Z&to=2017-12-01T13:59:59.999Z
  from: string;
  to: string;
}

export const DEFAULT_DATE_RANGE: IDateRange = {
  from: startOfDay(new Date()).toISOString(),
  to: endOfDay(new Date()).toISOString(),
};

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
})

/* This is sub group in two form so I've made it it's own component */
/* @output emits an object with two dates as UTC formatted strings */
/* Will emit when any of the three form fields are changed */
export class DateSelectorComponent implements OnInit {
  @Output() dateRangeChanged = new EventEmitter<IDateRange>();

  datePickerGroup: UntypedFormGroup;

  startDate = parseISO(DEFAULT_DATE_RANGE.from);
  endDate = parseISO(DEFAULT_DATE_RANGE.to);

  @Input()
  public parentForm: UntypedFormGroup;

  @Input()
  public hideRangeSelect: boolean;

  @Input()
  public hideSearchButton: boolean;

  rangeChoices = [
    { value: 99999999, viewValue: '' },
    { value: 1, viewValue: 'Last Day' },
    { value: 7, viewValue: 'Last 7 Days' },
    { value: 30, viewValue: 'Last 30 Days' },
    { value: 365, viewValue: 'Last 365 Days' },
  ];

  // 1st create the form group
  constructor() {
    this.createFormGroup();
  }

  // 2nd attach it to parent form
  ngOnInit() {
    this.emitDateRange(); // want to emit an initial daterange when component created.
  }

  createFormGroup() {
    this.datePickerGroup = new UntypedFormGroup({
      dateRange: new UntypedFormControl(''),
      startDate: new UntypedFormControl(this.startDate),
      endDate: new UntypedFormControl(this.endDate),
    });
  }

  setDateRange(days) {
    // this if should stop unintentionally retriggering this function when clearing 'dateRange' input
    if (days !== 99999999) {
      const fromDate = sub(startOfDay(new Date()), { days });
      const toDate = endOfDay(new Date());

      this.datePickerGroup.get('startDate').setValue(fromDate); // set this to update the ui
      this.startDate = fromDate; // set this for emitting to components parent form

      this.datePickerGroup.get('endDate').setValue(toDate);
      this.endDate = toDate;

      this.datePickerGroup.get('dateRange').setValue(''); // clear input
    }
  }

  setStartDate(e: Date) {
    this.startDate = e;
  }

  setEndDate(e: Date) {
    this.endDate = e;
  }

  public emitDateRange() {
    this.dateRangeChanged.emit(<IDateRange>{
      from: this.startDate.toISOString(),
      to: endOfDay(this.endDate).toISOString(),
    });
  }
}
