import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICroppedPhoto } from '../../modals/photo-cropper-modal/photo-cropper-modal.component';

@Component({
  selector: 'app-photo-card',
  templateUrl: './photo-card.component.html',
  styleUrls: ['./photo-card.component.scss'],
})
export class PhotoCardComponent implements OnInit {
  @Input()
  public image: ICroppedPhoto;
  @Output()
  deleteClicked = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}
}
