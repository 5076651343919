import { Component, OnInit } from '@angular/core';
import {
  IGeosegment,
  ITemplate,
  ITimeZone,
  IUserSegment,
  MarketingRepositoryService,
} from '@/app/services/repositories/marketing-repository.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { SessionService } from '@/app/core/auth/session.service';
@Component({
  selector: 'app-create-geo-trigger',
  templateUrl: './create-geo-trigger.component.html',
  styleUrls: ['./create-geo-trigger.component.scss'],
})
export class CreateGeoTriggerComponent implements OnInit {
  triggerForm: UntypedFormGroup;

  geofences: IGeosegment[] = [];
  userSegments: IUserSegment[] = [];
  templates: ITemplate[] = [];
  timeZones: ITimeZone[] = [];

  hideScheduleForm = true;

  constructor(
    public router: Router,
    public marketingService: MarketingRepositoryService,
    public currentUser: SessionService,
    public toastr: ToastrService,
    public formbuilder: UntypedFormBuilder
  ) {
    this.createForm();
  }

  get currentAdminGroupId() {
    return this.currentUser.currentAdminGroup.groupId;
  }

  ngOnInit() {
    this.getGeofences();
    this.getUserSegments();
    this.getTemplates();
    this.getTimeZones();
  }

  createForm() {
    this.triggerForm = this.formbuilder.group({
      fence: ['', Validators.required],
      userSegment: ['', Validators.required],
      template: ['', Validators.required],
      eventType: ['', Validators.required],
      timeZone: ['', Validators.required], // had to set this default later as these are loaded async from backend
      hour: ['12', Validators.required],
      minute: ['00', Validators.required],
      monday: [''],
      tuesday: [''],
      wednesday: [''],
      thursday: [''],
      friday: [''],
      saturday: [''],
      sunday: [''],
      coolOff: [100, Validators.required],
    });
  }

  getGeofences() {
    this.marketingService
      .getGeosegments({
        groupId: this.currentAdminGroupId,
      })
      .subscribe((result) => {
        this.geofences = result.results;
      });
  }

  getTemplates() {
    this.marketingService
      .getTemplates({
        groupId: this.currentAdminGroupId,
      })
      .subscribe((result) => {
        this.templates = result.results;
      });
  }

  getTemplate(id?: string): ITemplate | undefined {
    return this.templates.filter((t) => t.id === id)?.[0];
  }

  getUserSegments() {
    this.marketingService
      .getUserSegments({
        groupId: this.currentAdminGroupId,
      })
      .subscribe((result) => (this.userSegments = result.results));
  }

  getTimeZones() {
    this.marketingService
      .getTimeZones({
        hint: new Date().toString(),
      })
      .pipe(finalize(() => this.setDefaultTimezone()))
      .subscribe((result) => (this.timeZones = result));
  }

  setDefaultTimezone() {
    const toSelect = this.timeZones.find((c) => c.recommened === true);
    this.triggerForm.get('timeZone').setValue(toSelect.name);
  }

  save() {
    this.marketingService
      .postGeoTrigger({
        group: this.currentAdminGroupId,
        coolOffDays: this.triggerForm.get('coolOff').value,
        eventType: this.triggerForm.get('eventType').value,
        marketingLocationId: this.triggerForm.get('fence').value,
        marketingMessageTemplateId: this.triggerForm.get('template').value,
        marketingUserSegmentId: this.triggerForm.get('userSegment').value,
        timeZone: this.triggerForm.get('timeZone').value,
        hours: this.triggerForm.get('hour').value,
        minutes: this.triggerForm.get('minute').value,
        monday: this.triggerForm.get('monday').value,
        tuesday: this.triggerForm.get('tuesday').value,
        wednesday: this.triggerForm.get('wednesday').value,
        thursday: this.triggerForm.get('thursday').value,
        friday: this.triggerForm.get('friday').value,
        saturday: this.triggerForm.get('saturday').value,
        sunday: this.triggerForm.get('sunday').value,
      })
      .subscribe(
        () => {
          this.toastr.success('Geo Trigger Saved');
          this.router.navigateByUrl('/triggers');
        },
        () => {
          this.toastr.error('Geo Trigger Save Failed');
        }
      );
  }

  showHideSubform() {
    this.hideScheduleForm = this.triggerForm.get('eventType').value !== 'TIMED';
  }

  // SCHEDULE example
  /*
    "monday":true,
    "tuesday":false,
    "wednesday":true,
    "thursday":false,
    "friday":false,
    "saturday":false,
    "sunday":true,
    "marketingLocationId":"875f4d22317f4fa197f065c7d04e25a8",
    "marketingUserSegmentId":"daa5aa0f17dd43fe8524af609a6cc73c",
    "marketingMessageTemplateId":"63593c376c204ab7bde7c10ac1fed7ac",
    "eventType":"TIMED",
    "timeZone":"Universal",
    "coolOffDays":5,
    "minutes":15,
    "hours":1
   */

  // ENTER example
  /*
    "monday":true,
    "tuesday":false,
    "wednesday":true,
    "thursday":false,
    "friday":false,
    "saturday":false,
    "sunday":true,
    "marketingLocationId":"875f4d22317f4fa197f065c7d04e25a8",
    "marketingUserSegmentId":"daa5aa0f17dd43fe8524af609a6cc73c",
    "marketingMessageTemplateId":"63593c376c204ab7bde7c10ac1fed7ac",
    "eventType":"ENTER",
    "timeZone":"Universal",
    "coolOffDays":5,
    "minutes":15,
    "hours":1
   */

  // just hide the extra fields. not ngIf
}
