import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@/app/shared.module';
import { CreateUserSegmentPageComponent } from './pages/create-usersegment-module/create-usersegment-page.component';
import { YourUserSegmentsPageComponent } from './pages/your-usersegments-page/your-usersegments-page.component';
import { UsersegmentsPageRoutingModule } from './usersegments-page-routing.module';
import { UsersegmentsPageComponent } from './usersegments-page.component';
import { NouisliderModule } from 'ng2-nouislider';

@NgModule({
  declarations: [UsersegmentsPageComponent, CreateUserSegmentPageComponent, YourUserSegmentsPageComponent],
  imports: [CommonModule, UsersegmentsPageRoutingModule, SharedModule, NouisliderModule],
})
export class UsersegmentsPageModule {}
