import { IMockTemplateMobile } from '@/app/components/shared/mock-mobile-template/mock-mobile-template.component';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-preview-template-modal',
  templateUrl: './preview-template-modal.component.html',
  styleUrls: ['./preview-template-modal.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PreviewTemplateModalComponent {
  constructor(
    public dialogRef: MatDialogRef<PreviewTemplateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IMockTemplateMobile
  ) {}

  closeModal() {
    this.dialogRef.close();
  }
}
