<mat-toolbar color="primary">
  <span>Instant Push</span>
</mat-toolbar>

<div class="page-content">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxLayoutGap="1vw">
    <div fxFlex="60">
      <form [formGroup]="pushForm" (ngSubmit)="openConfirmationDialog()">
        <mat-form-field class="fill-parent">
          <mat-select formControlName="fence" placeholder="Select Geo Fence" (selectionChange)="getPushEstimate()">
            <mat-option *ngFor="let geofence of geofences" [value]="geofence.id">{{ geofence.name }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="fill-parent">
          <mat-select
            formControlName="userSegment"
            placeholder="Select User Segment"
            (selectionChange)="getPushEstimate()"
          >
            <mat-option value="">All</mat-option>
            <mat-option *ngFor="let userSegment of userSegments" [value]="userSegment.id">{{
              userSegment.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field hintLabel="You can use one of your templates to get started." class="fill-parent">
          <mat-select
            formControlName="template"
            placeholder="Select Template"
            (selectionChange)="this.loadTemplateIntoForm(pushForm.get('template').value)"
          >
            <mat-option *ngFor="let template of templates" [value]="template">{{ template.name }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field hintLabel="Entice your customers with short punchy message" class="fill-parent">
          <input
            type="text"
            formControlName="pushMessage"
            matInput
            placeholder="Push Message"
            maxlength="100"
            required
          />
          <mat-error>You must enter a push message</mat-error>
        </mat-form-field>

        <br />
        <br />
        <br />
        <editor [init]="tinymceConfig" [formControl]="pushForm.controls.marketingText"></editor>
        <br />

        <div fxLayout="row" fxLayoutGap="8px" class="maxHeight">
          <div fxFlex></div>
          <button mat-raised-button color="accent" type="submit" [disabled]="!pushForm.valid" class="centred-button">
            Send Push Notification
          </button>
          <div class="maxHeight" *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
            <p>Estimating users reached</p>
            <div class="spinner-container">
              <mat-spinner [diameter]="20" [strokeWidth]="4"></mat-spinner>
            </div>
          </div>
          <div class="maxHeight" *ngIf="!loading" fxLayout="row" fxLayoutAlign="center center">
            <p>{{ estimateNum }} Users</p>
          </div>
          <div fxFlex></div>
        </div>
      </form>
    </div>

    <app-mock-mobile-template [templateHtml]="preview.marketingText"></app-mock-mobile-template>
  </div>
</div>
