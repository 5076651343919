import { Injectable } from '@angular/core';
import { IMarketingLocation } from './marketing-repository.service';
import { attachParametersToUrl, ExtraHttpHeaders } from './utils';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

/*
    Rest action definitions
    Using ngx-resource
*/
@Injectable({ providedIn: 'root' })
export class UserRepositoryService {
  constructor(private http: HttpClient) {}

  getUser(userId: string, params?: { extended?: boolean }, headers?: ExtraHttpHeaders): Observable<IUser> {
    const url = '/2/users/' + userId;
    return this.http.get<IUser>(attachParametersToUrl(url, params), { headers });
  }

  updateUser(userId: string, body: IUserUpdate): Observable<IUser> {
    const url = '/2/users/' + userId;
    return this.http.put<IUser>(url, body);
  }

  blacklistUser(userId: string, body: IUserUpdate): Observable<IUser> {
    const url = '/2/users/' + userId + '/blacklist';
    return this.http.put<IUser>(url, body);
  }

  blacklistUsers(blacklistUsers: BlacklistUsersRequest): Observable<any> {
    const url = '/2/users/blacklist';
    return this.http.post<IUser>(url, blacklistUsers);
  }

  setSpammer(userId: string): Observable<any> {
    const url = '/2/users/' + userId + '/spammer';
    return this.http.put<any>(url, {});
  }

  getUsers(params: IGetUsersParams): Observable<IUsersResponse> {
    const url = '/2/users';
    return this.http.get<IUsersResponse>(attachParametersToUrl(url, params));
  }

  registerUser(body: IRegUser): Observable<any> {
    const url = '/2/users';
    return this.http.post<any>(url, body);
  }

  getReferredUsers(userId: string): Observable<IUser[]> {
    const url = '/2/users/' + userId + '/referredusers';
    return this.http.get<IUser[]>(url, {});
  }

  getNewUserStats(params: IReportParams): Observable<INewUserStat[]> {
    const url = '/2/users/report/newusers';
    return this.http.get<INewUserStat[]>(attachParametersToUrl(url, params));
  }

  getNationalityStats(params: IReportParams): Observable<IReportStat[]> {
    const url = '/2/users/report/newusers/nationality';
    return this.http.get<IReportStat[]>(attachParametersToUrl(url, params));
  }

  getCountryStats(params: IReportParams): Observable<IReportStat[]> {
    const url = '/2/users/report/newusers/country';
    return this.http.get<IReportStat[]>(attachParametersToUrl(url, params));
  }

  getGenderStats(params: IReportParams): Observable<IReportStat[]> {
    const url = '/2/users/report/newusers/gender';
    return this.http.get<IReportStat[]>(attachParametersToUrl(url, params));
  }

  getAgeStats(params: IReportParams): Observable<IReportStat[]> {
    const url = '/2/users/report/newusers/age';
    return this.http.get<IReportStat[]>(attachParametersToUrl(url, params));
  }

  deleteUserGdpr(userId: string) {
    const url = '/2/users/' + userId + '/gdpr';
    return this.http.delete<any>(url);
  }

  getLocations(userId: string): Observable<IUserLocations> {
    const url = '/2/users/' + userId + '/locations';
    return this.http.get<IUserLocations>(url);
  }

  getInterests(userId: string): Observable<IUserInterest[]> {
    const url = '/2/users/' + userId + '/intrests';
    return this.http.get<IUserInterest[]>(url);
  }

  getTrips(userId: string): Observable<IUserTrips> {
    const url = '/2/users/' + userId + '/trips';
    return this.http.get<IUserTrips>(url);
  }

  getChats(params: IChatsParams, headers?: ExtraHttpHeaders): Observable<IChatsResponse> {
    const url = '/2/users/me/chats';
    return this.http.get<IChatsResponse>(attachParametersToUrl(url, params), { headers });
  }

  getFriends(userId: string): Observable<IFriendsResponse> {
    const url = '/2/users/' + userId + '/friends';
    return this.http.get<IFriendsResponse>(url);
  }

  getChatMessages(otherPartyUserId: string): Observable<IChatMessagesResponse> {
    const url = '/2/users/me/chats/users/' + otherPartyUserId;
    return this.http.get<IChatMessagesResponse>(url);
  }

  markChatAsRead(otherPartyUserId: string): any {
    const url = '/2/users/me/chats/users/' + otherPartyUserId;
    return this.http.put<any>(url, {});
  }

  postChatMessage(otherPartyUserId: string, body: { message: string }): any {
    const url = '/2/users/me/chats/users/' + otherPartyUserId;
    return this.http.post<any>(url, body);
  }

  getChatHistory(userId: string): Observable<IChatsResponse> {
    const url = '/2/users/' + userId + '/chats';
    return this.http.get<IChatsResponse>(url);
  }
}

/*
    Interfaces defining types for sending and receiving information from backend
    Any type I'm not sure about I just put as "any".
    ? signify optional fields
*/

export interface IFriendsResponse {
  metaData: IFriendsMeta;
  results: any[];
}

export interface IFriendsMeta {
  total: number;
}

export interface IChatsParams {
  limit?: number;
}

export interface IChatsResponse {
  cursor: string;
  metaData: IChatsResponseMeta;
  results: IChatDetails[];
}

export interface IChatsResponseMeta {
  count: number;
  unreadCount: number;
}

export interface IChatDetails {
  hasUnread: boolean;
  lastMessageDate: string;
  lastMessageText: string;
  otherParty: IUser;
  threadId: string;
}

export interface IChatMessagesResponse {
  cursor: string;
  metaData: any;
  results: IChatMessage[];
}

export interface IChatMessage {
  date: string;
  from: IUser;
  id: string;
  isRead: boolean;
  message: string;
}

export interface IUserLocations {
  cursor: any;
  metaData: IUsersMeta;
  results: IMarketingLocation[];
}

export interface IUserInterest {
  code: string;
  displayName: string;
  icon: string;
  id: string;
  image: string;
  name: string;
}

export interface IUserCreditPointRecords {
  cursor: string;
  metaData: any;
  results: IUserCreditPointRecord[];
}

export interface IUserCreditPointRecord {
  type: string;
  subType: string;
  description: string;
  value: number;
  settlementDate: string;
  targetId: string;
  creditTransactionId: string;
  createdAt: string;
}

export interface IUserTrips {
  cursor: string;
  metaData: any;
  results: IUserTrip[];
}

export interface IUserTrip {
  city: string;
  country: string;
  countryCode: string;
  createdAt: string;
  fromDate: string;
  id: string;
  location: IMarketingLocation;
  metaData: IUserTripMetadata;
  photo: string;
  placeId: string;
  toDate: string;
  updatedAt: string;
  user: IUser;
  version: string;
}

export interface IUserTripMetadata {
  canFollow: boolean;
  canFriend: boolean;
  canMessage: boolean;
  commonFriends: number;
  commonInterests: number;
  commonInterestsList: any;
  currentLocation: IMarketingLocation;
  distance: number;
  followable: boolean;
  following: any;
  friendshipStatus: any;
  isFacebookFriend: any;
  isFriend: any;
  justIn: boolean;
  myInterestsList: any;
  otherUserInterestList: any;
  verified: boolean;
}

export interface IReportParams {
  from: string;
  to: string;
  groupId?: string;
}

export interface INewUserStat {
  date: string;
  value: string;
}

export interface IReportStat {
  field: string;
  value: string;
}

export interface IGetUsersParams {
  activeWithin?: number;
  extended?: boolean;
  latLong?: string; // e.g. latLong=-27.456866297553404,153.03736728168587
  limit?: number;
  radiusStrict?: boolean;
  radius?: number;
  groupId?: string;
  search?: string;
  showDeactive?: boolean;
}

export interface IUsersResponse {
  cursor: any;
  metaData: IUsersMeta;
  results: IUser[];
}

export interface IUsersMeta {
  total: number;
}

export interface BlacklistUsersRequest {
  userIds: string[];
  description: string;
}

export interface IRegUser {
  dateOfBirth: string;
  dob: string;
  email: string;
  gender: string;
  nationality: string;
  password: string;
  passwordconfirm: string;
  userName: string;
}

export interface IGroupRole {
  role: string;
  groupId: string;
  name: string;
}

export interface IUser {
  authorizationToken?: string;
  countryCode?: string;
  createdAt?: string;
  currentCity?: string;
  currentCountry?: string;
  currentLocation?: IMarketingLocation;
  dateOfBirth?: string;
  deactive?: boolean;
  email?: string;
  followable?: boolean;
  gender?: string;
  groupRoles?: IGroupRole[];
  groupsJoined?: string[];
  guest?: boolean;
  id?: string;
  isLocationPublic?: boolean;
  lastLocationUpdated?: string;
  lastLocationUpdatedAt?: string;
  lastUpdatedAt?: string;
  metaData?: IUserMeta;
  nationality?: string;
  objectId?: string;
  profileImage?: string;
  role?: string;
  sessionTokens?: any[];
  spammer?: boolean;
  updatedAt?: string;
  userName?: string;
  admin?: boolean;
  blacklistDescription?: string;
}

export interface IUserMeta {
  canFollow: boolean;
  canFriend: boolean;
  canMessage: boolean;
  commonFriends: number;
  commonInterests: number;
  commonInterestsList?: any[];
  currentLocation?: IMarketingLocation;
  distance: number;
  followable: boolean;
  following?: any;
  friendshipStatus?: string;
  isFacebookFriend?: boolean;
  isFriend?: boolean;
  justIn: boolean;
  myInterestsList?: any[];
  otherUserInterestList?: any[];
  verified: boolean;
}

export interface IUserUpdate {
  setFollowable?: boolean;
  deactive?: boolean;
  admin?: boolean;
}
