import { Component, Input } from '@angular/core';
import { IChatMessage } from '@/app/services/repositories/user-repository.service';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent {
  @Input()
  message: IChatMessage;

  @Input()
  fromMe: boolean;
}
