import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { GroupsRepositoryService, IGroupInfo } from '@/app/services/repositories/groups-repository.service';
import { SessionService } from '@/app/core/auth/session.service';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss'],
})
export class CreateGroupComponent {
  groupForm: UntypedFormGroup;

  constructor(
    public router: Router,
    public toastr: ToastrService,
    public formbuilder: UntypedFormBuilder,
    public groupsService: GroupsRepositoryService,
    private currentUser: SessionService
  ) {
    this.createForm();
  }

  createForm() {
    this.groupForm = this.formbuilder.group({
      name: ['', Validators.required],
      website: ['', Validators.required],
      type: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  save() {
    const newGroup = this.prepareGroup();
    const newGroupName = newGroup.name;

    this.groupsService.postGroup(newGroup).subscribe(
      () => {
        this.toastr.success(`"${newGroupName}" created`);
        this.router.navigateByUrl('/group');
        this.currentUser.syncOnce();
      },
      () => {
        this.toastr.error('Group Save Failed');
      }
    );
  }

  prepareGroup(): IGroupInfo {
    return {
      name: this.groupForm.get('name').value,
      websiteUrl: this.groupForm.get('website').value,
      type: this.groupForm.get('type').value,
      description: this.groupForm.get('description').value,
    };
  }
}
