import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

@Pipe({ name: 'timestampToDate' })
export class TimestampToDatePipe implements PipeTransform {
  transform(value: Timestamp): Date {
    const epochMillis = value.getSeconds() * 1000 + value.getNanos() / 1000000;
    return new Date(epochMillis);
  }
}
