import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MarketingRepositoryService } from '@/app/services/repositories/marketing-repository.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import PlaceResult = google.maps.places.PlaceResult;
import { GoogleMap, MapCircle } from '@angular/google-maps';
import { MAP_CIRCLE_OPTIONS } from '@/app/config/static-options';
import { SessionService } from '@/app/core/auth/session.service';
import { GeolocationService } from '@/app/services/geolocation.service';
import { take } from 'rxjs/operators';
const DEFAULT_CIRCLE_RADIUS = 2000;

@Component({
  selector: 'app-create-geofences-page',
  templateUrl: './create-geofences-page.component.html',
  styleUrls: ['./create-geofences-page.component.scss'],
})
export class CreateGeoFencesPageComponent implements OnInit {
  // default settings for the map
  latitude = -27.47;
  longitude = 153.02;
  zoom = 11;

  circles: google.maps.Circle[] = [];
  nameFormControl = new UntypedFormControl('', [Validators.required]);

  circleOptions: google.maps.CircleOptions = { ...MAP_CIRCLE_OPTIONS, draggable: true, editable: true };

  constructor(
    private marketingService: MarketingRepositoryService,
    private toastr: ToastrService,
    private currentUser: SessionService,
    private router: Router,
    private geolocation: GeolocationService
  ) {
    this.geolocation.position$.pipe(take(1)).subscribe((position) => {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
    });
  }

  get valid() {
    return this.circles.length > 0;
  }

  get currentAdminGroupId() {
    return this.currentUser.currentAdminGroup.groupId;
  }

  ngOnInit() {}

  circleChange(c: MapCircle, i: number) {
    this.circles[i].setCenter(c.getCenter());
    this.circles[i].setRadius(c.getRadius());
  }

  mapClicked(e: google.maps.MouseEvent) {
    if (this.circles.length === 0) {
      this.circles.push(new google.maps.Circle({ center: e.latLng, radius: DEFAULT_CIRCLE_RADIUS }));
    } else {
      this.circles = [];
    }
  }

  saveGeoFence() {
    const c = this.circles?.[0];
    this.marketingService
      .postGeosegment({
        group: this.currentAdminGroupId,
        location: {
          longitude: c.getCenter().lng(),
          latitude: c.getCenter().lat(),
        },
        name: this.nameFormControl.value,
        radius: Math.round(c.getRadius()),
      })
      .subscribe(
        () => {
          this.toastr.success('GeoFence Saved');
          this.router.navigateByUrl('/geoFences');
        },
        () => {
          this.toastr.error('GeoFence Save Failed');
        }
      );
  }

  updateZoomLevel(m: GoogleMap) {
    this.zoom = m.getZoom();
  }

  moveToAddress(e: PlaceResult) {
    this.latitude = e.geometry.location.lat();
    this.longitude = e.geometry.location.lng();
    this.circles = [];
  }
}
