<mat-toolbar color="primary">
  <span>Groups List</span>
</mat-toolbar>

<div class="page-content">
  <div class="mat-elevation-z8 table-div">
    <mat-table #table [dataSource]="groups">
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      <ng-container matColumnDef="name">
        <mat-header-cell fxFlex="70" *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell fxFlex="70" *matCellDef="let element">
          <div *ngIf="element.name">
            {{ element.name }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell fxFlex="30" *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell fxFlex="30" *matCellDef="let element">
          <div fxLayout="row" fxLayoutGap="2em">
            <button mat-raised-button color="primary" (click)="selectAdminGroup(element)">Switch</button>
            <button
              mat-raised-button
              color="warn"
              (click)="deleteGroup(element)"
              [disabled]="element.groupId === currentGroup.groupId"
            >
              Delete
            </button>
          </div>
        </mat-cell>
      </ng-container>
    </mat-table>
  </div>
</div>
