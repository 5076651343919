<div class="chat-card" fxLayout="row" fxLayoutGap="12px">
  <div>
    <img *ngIf="chat.otherParty.profileImage" class="chat-profiler" [src]="chat.otherParty.profileImage" />
    <span *ngIf="!chat.otherParty.profileImage" class="chat-profiler">
      <mat-icon class="missing-profile-pic-icon">person</mat-icon>
    </span>
  </div>
  <div fxFlex fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div class="chat-user-name">
        <mat-icon *ngIf="chat.hasUnread" color="primary" class="unread-message-icon">email</mat-icon>
        <mat-icon *ngIf="!chat.hasUnread" class="unread-message-icon">drafts</mat-icon>
        {{ chat.otherParty.userName }}
      </div>
      <div class="chat-last-message-date">
        {{ chat.lastMessageDate | date: 'shortDate' }}
      </div>
    </div>
    <div class="chat-message-text">
      {{ chat.lastMessageText }}
    </div>
  </div>
</div>
