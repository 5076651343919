import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { SessionStore, SessionState } from './session.store';

@Injectable({ providedIn: 'root' })
export class SessionQuery extends Query<SessionState> {
  readonly currentUser$ = this.select((state) => state.currentUser);
  readonly currentAdminGroup$ = this.select((state) => state.currentAdminGroup);

  constructor(protected store: SessionStore) {
    super(store);
  }
}
