import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@/app/shared.module';
import { TemplatesPageComponent } from './templates-page.component';
import { YourTemplatesPageComponent } from './pages/your-templates-page/your-templates-page.component';
import { SimplifiedTemplatePageComponent } from './pages/simplified-template-page/simplified-template-page.component';
import { EditTemplatePageComponent } from './pages/edit-template-module/edit-template-page.component';
import { TemplatesPageRoutingModule } from './templates-page-routing.module';
import { PreviewTemplateModalComponent } from './modals/preview-template-modal/preview-template-modal.component';

@NgModule({
  declarations: [
    TemplatesPageComponent,
    YourTemplatesPageComponent,
    SimplifiedTemplatePageComponent,
    EditTemplatePageComponent,
    PreviewTemplateModalComponent,
  ],
  imports: [CommonModule, TemplatesPageRoutingModule, SharedModule],
})
export class TemplatesPageModule {}
