import { Component, OnInit } from '@angular/core';
import { IDateRange, DEFAULT_DATE_RANGE } from '@/app/components/shared/date-selector/date-selector.component';
import { INewUserStat, UserRepositoryService } from '@/app/services/repositories/user-repository.service';
import { IDataSeriesEntry } from '@/app/components/charts/constants';
import { ToggleSideNavService } from '../../../services/toggle-side-nav.service';
import { UntypedFormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SessionService } from '@/app/core/auth/session.service';

@UntilDestroy()
@Component({
  selector: 'app-demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.scss'],
})
export class DemographicsComponent implements OnInit {
  dateRange = DEFAULT_DATE_RANGE;
  chartsLoaded = false; // have to wait until we have data before toggling this boolean with *ngIf to render graphs
  reportsForm: UntypedFormGroup;

  newUsersGraphData: IDataSeriesEntry[] = [];
  newUserXLabel = 'Date';
  newUserYLabel = 'New Users';

  nationalityGraphData: IDataSeriesEntry[] = [];
  nationalityXLabel = 'Nationality';
  nationalityYLabel = 'Users';

  currentCountryGraphData: IDataSeriesEntry[] = [];
  currentCountryXLabel = 'Current Country';
  currentCountryYLabel = 'Users';

  genderGraphData: IDataSeriesEntry[] = [];

  ageGraphData: IDataSeriesEntry[] = [];
  ageXLabel = 'Age';
  ageYLabel = 'Users';

  constructor(
    public userService: UserRepositoryService,
    public currentUser: SessionService,
    private toggleSideNavService: ToggleSideNavService
  ) {
    this.createForm();
  }

  get currentAdminGroupId() {
    return this.currentUser.currentAdminGroup.groupId;
  }

  createForm() {
    // only contains an app-date-selector
    this.reportsForm = new UntypedFormGroup({});
  }

  ngOnInit() {
    this.currentUser.currentAdminGroup$.pipe(untilDestroyed(this)).subscribe(() => {
      this.getData();
    });
    this.toggleSideNavService.toggleSideNav();
  }

  // this is listening for changes emitted by the date range component
  setDateRange(dateRange: IDateRange) {
    this.dateRange = dateRange;
    this.getData();
  }

  getData() {
    this.chartsLoaded = false;

    this.getGraphData(
      this.userService.getNewUserStats({
        from: this.dateRange.from,
        to: this.dateRange.to,
        groupId: this.currentAdminGroupId,
      }),
      'newUsersGraphData',
      'New Users'
    );

    this.getGraphData(
      this.userService.getGenderStats({
        from: this.dateRange.from,
        to: this.dateRange.to,
        groupId: this.currentAdminGroupId,
      }),
      'genderGraphData',
      null
    );

    this.getGraphData(
      this.userService.getNationalityStats({
        from: this.dateRange.from,
        to: this.dateRange.to,
        groupId: this.currentAdminGroupId,
      }),
      'nationalityGraphData',
      null
    );

    this.getGraphData(
      this.userService.getCountryStats({
        from: this.dateRange.from,
        to: this.dateRange.to,
        groupId: this.currentAdminGroupId,
      }),
      'currentCountryGraphData',
      null
    );

    this.getGraphData(
      this.userService.getAgeStats({
        from: this.dateRange.from,
        to: this.dateRange.to,
        groupId: this.currentAdminGroupId,
      }),
      'ageGraphData',
      null
    );
  }

  // Passing function as parameters to avoid code duplication
  getGraphData(apiRequestFunction, graphDataStoreVariable, seriesName) {
    apiRequestFunction.pipe(finalize(() => (this.chartsLoaded = true))).subscribe(
      (response) => {
        if (seriesName) {
          this[graphDataStoreVariable] = this.setLineGraphData(seriesName, response);
        } else {
          this[graphDataStoreVariable] = this.prepareDataForGraph(response, false);
        }
      },
      (error) => {
        console.log('getGraphData returned error.');
        console.log(error);
      }
    );
  }

  // TODO waiting on Barge to fix new user endpoint date range
  // line graphs need to have a name added to the json
  setLineGraphData(seriesName: string, data: INewUserStat[]): IDataSeriesEntry[] {
    return this.prepareDataForGraph(data, true);
  }

  // graphing library needs the data to be in this form
  prepareDataForGraph(userStats: any[], dateSeries: boolean): IDataSeriesEntry[] {
    const dataSeries: IDataSeriesEntry[] = [];

    if (userStats) {
      for (let i = 0; i < userStats.length; i++) {
        let dataPoint: IDataSeriesEntry;
        if (dateSeries) {
          dataPoint = {
            name: userStats[i].date,
            value: userStats[i].value,
          };
        } else {
          dataPoint = {
            name: userStats[i].field,
            value: userStats[i].value,
          };
        }

        dataSeries.push(dataPoint);
      }
    }
    return dataSeries;
  }
}
