<div>
  <br />
  <div *ngIf="!loading" class="mat-elevation-z8 table-div">
    <mat-table #table [dataSource]="templates">
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      <ng-container matColumnDef="name">
        <mat-header-cell fxFlex="25" *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell fxFlex="25" *matCellDef="let element">
          <div *ngIf="element.name">
            {{ element.name }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="message">
        <mat-header-cell fxFlex="25" *matHeaderCellDef> Message </mat-header-cell>
        <mat-cell fxFlex="25" *matCellDef="let element">
          <div *ngIf="element.message">
            {{ element.message }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell fxFlex="50" *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell fxFlex="50" *matCellDef="let element">
          <div fxLayout="row" fxLayoutGap="2em">
            <button mat-raised-button color="primary" (click)="viewTemplate(element)">View</button>
            <button mat-raised-button (click)="editTemplate(element)">Edit</button>
            <button mat-raised-button color="warn" (click)="openConfirmationDialog(element)">Delete</button>
          </div>
        </mat-cell>
      </ng-container>
    </mat-table>
  </div>

  <br />
  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
    <p>Loading...</p>
    <div class="spinner-container">
      <mat-spinner [diameter]="20" [strokeWidth]="4"></mat-spinner>
    </div>
  </div>
  <div *ngIf="templates.length === 0 && !loading" fxLayout="row" fxLayoutAlign="center center">
    <p>You don't have any templates set up.</p>
  </div>
  <br />
</div>
