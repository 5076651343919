<h2 mat-dialog-title>Pin Post to Top of Feed</h2>
<mat-dialog-content class="mat-typography">
  <mat-form-field>
    <input matInput #numHours type="number" min="1" id="hours" name="hours" step="1" />
    <button matSuffix mat-raised-button color="primary" (click)="pinForHours(numHours.value)">Pin for Hours</button>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="pinIndefinitely()">Pin Indefinitely</button>
</mat-dialog-actions>
