import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BlacklistUsersRequest,
  IChatDetails,
  IUser,
  IUserInterest,
  IUserTrip,
  UserRepositoryService
} from '@/app/services/repositories/user-repository.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { FeedsRepositoryService, IFeedPost } from '@/app/services/repositories/feeds-repository.service';
import { IMarketingLocation } from '@/app/services/repositories/marketing-repository.service';
import { PopupsService } from '@/app/services/popups.service';
import { RewardsRepositoryService } from '@/app/services/repositories/rewards-repository.service';
import { CreditTransactionResponseForAdmin, HomeScreenResponse } from '@travello/grpc-api/js/reward_pb';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { SessionService } from '@/app/core/auth/session.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

function getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
}

class IUserWithCreditPoints {
  constructor(public user: IUser, public creditPoints: number) {
  }
}


@Component({
  selector: 'app-user-profile-page',
  templateUrl: './user-profile-page.component.html',
  styleUrls: ['./user-profile-page.component.scss']
})
export class UserProfilePageComponent implements OnInit {
  constructor(
    private userService: UserRepositoryService,
    private rewardsService: RewardsRepositoryService,
    private feedsService: FeedsRepositoryService,
    public currentUser: SessionService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private popups: PopupsService,
    private formBuilder: UntypedFormBuilder
  ) {
  }

  logoPng = 'assets/marker.png'; // for cases where a person doesn't have a profile pic

  userId = '';
  user$: Observable<IUser>;
  creditSummary: HomeScreenResponse;
  user: IUser;
  locations: IMarketingLocation[] = [];
  interests: IUserInterest[] = [];
  posts: IFeedPost[] = [];
  trips: IUserTrip[] = [];
  filteredLocations: IMarketingLocation[] = [];
  chats: IChatDetails[] = [];
  friendsTotal = 0;

  creditPointsColumnsToDisplay = ['type', 'value', 'createdAt', 'settlementDate', 'description'];
  creditPointRecords = new MatTableDataSource<CreditTransactionResponseForAdmin>();
  @ViewChild(MatPaginator, { static: true }) creditPointPaginator: MatPaginator;

  referredUsersColumnsToDisplay = ['selection', 'userName', 'email', 'dateOfBirth', 'createdAt', 'deactive', 'spammer', 'creditPoints', 'blacklisted', 'blacklistReason'];
  referredUsersSelection = new SelectionModel<IUserWithCreditPoints>(true, []);
  referredUsers = new MatTableDataSource<IUserWithCreditPoints>();
  @ViewChild(MatPaginator, { static: true }) referredUsersPaginator: MatPaginator;

  resetRewardFormGroup: UntypedFormGroup;
  blacklistUserFormGroup: UntypedFormGroup;

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const targetUserId = params['id'];
      this.userId = targetUserId;
      this.getUserInfo(targetUserId);
      this.getUserInterests(targetUserId);
      this.getUserPosts(targetUserId);
      this.getUserTrips(targetUserId);
      this.getUserCreditPointRecords(targetUserId);
      this.getReferredUsers(targetUserId);
      this.getUserChats(targetUserId);
      this.getUserFriends(targetUserId);
      this.loadLocationsMap(targetUserId);
      this.getCreditSummary(targetUserId);
      this.createResetRewardForm();
      this.createBlacklistUserForm();
    });

    this.creditPointRecords.paginator = this.creditPointPaginator;
    this.referredUsers.paginator = this.referredUsersPaginator;
  }

  createResetRewardForm() {
    this.resetRewardFormGroup = this.formBuilder.group({
        description: new UntypedFormControl(''),
    });
  }

  createBlacklistUserForm() {
    this.blacklistUserFormGroup = this.formBuilder.group({
      description: new UntypedFormControl(''),
    });
  }

  get me() {
    return this.currentUser.currentUser;
  }

  private getUserInfo(id: string) {
    this.user$ = this.userService.getUser(id, { extended: true });

    this.user$.subscribe(
      (result) => {
        this.user = result;
      },
      () => {
        this.toastr.error('Failed to retrieve user data');
      }
    );
  }

  private getUserInterests(id: string) {
    this.userService.getInterests(id).subscribe(
      (result) => {
        this.interests = result;
      },
      () => {
        this.toastr.error('Failed to retrieve user interests');
      }
    );
  }

  private getUserFriends(id: string) {
    this.userService.getFriends(id).subscribe(
      (result) => {
        this.friendsTotal = result.metaData.total;
      },
      () => {
        this.toastr.error('Failed to retrieve user friends');
      }
    );
  }

  private getUserPosts(id: string) {
    this.feedsService.getAdminFeed({ limit: 300, userId: id }).subscribe(
      (result) => {
        this.posts = result.results;
      },
      () => {
        this.toastr.error('Failed to retrieve user posts');
      }
    );
  }

  private getUserTrips(id: string) {
    this.userService.getTrips(id).subscribe(
      (result) => {
        this.trips = result.results;
      },
      () => {
        this.toastr.error('Failed to retrieve user trips');
      }
    );
  }

  private getUserCreditPointRecords(id: string) {
    this.rewardsService.getCreditTransactions(id).subscribe(
      (result) => {
        this.creditPointRecords.data = result;
        this.creditPointRecords.data.push(new CreditTransactionResponseForAdmin());
        console.log(this.creditPointRecords.data[0].getSubType());
      },
      () => {
        this.toastr.error('Failed to retrieve user trips');
      }
    );
  }

  private getReferredUsers(targetUserId: string) {
    this.userService.getReferredUsers(targetUserId).subscribe(
      (result) => {
        const data: IUserWithCreditPoints[] = [];
        result.forEach(user => {
          this.rewardsService.getCreditSummary(user.id).then(
            (creditResponse) => {
              data.push(new IUserWithCreditPoints(user, creditResponse.getCreditPointsIncPending()));
              if (data.length === result.length) {
                data.sort((user1, user2) => -1 * user1.user.createdAt.localeCompare(user2.user.createdAt));
                this.referredUsers.data = data;
              }
            },
            () => {
              this.toastr.error('Failed to getCreditSummary');
            }
          );
        });
      },
      (result) => {
        this.toastr.error('Failed to getReferredUsers');
      }
    );

  }

  private getUserChats(id: string) {
    this.userService.getChatHistory(id).subscribe(
      (result) => {
        this.chats = result.results;
      },
      () => {
        this.toastr.error('Failed to retrieve user chat history');
      }
    );
  }

  private loadLocationsMap(id: string) {
    this.userService.getLocations(id).subscribe(
      (result) => {
        this.locations = result.results;
        this.filteredLocations = [];
        this.filterLocations();
        // this.prepareAndLoadChart();
      },
      () => {
        this.toastr.error('Failed to retrieve user locations');
      }
    );
  }

  private filterLocations() {
    let lastAddedLocation: IMarketingLocation;
    for (let i = 0; i < this.locations.length; i++) {
      const location = this.locations[i];

      if (i === 0) {
        this.filteredLocations.push(location);
        lastAddedLocation = location;
      } else {
        const distance = getDistanceFromLatLonInKm(
          lastAddedLocation.latitude,
          lastAddedLocation.longitude,
          location.latitude,
          location.longitude
        );
        if (distance > 40) {
          this.filteredLocations.push(location);
          lastAddedLocation = location;
        }
      }
    }
  }

  verifyUser(verify: boolean) {
    this.userService.updateUser(this.user.id, { setFollowable: verify }).subscribe(
      () => {
        if (verify) {
          this.toastr.success('User verified');
          this.getUserInfo(this.user.id);
        } else {
          this.toastr.success('User unverified');
          this.getUserInfo(this.user.id);
        }
      },
      () => {
        this.toastr.error('Failed to change user verification status');
      }
    );
  }

  deactivateUser(deactivate: boolean) {
    this.userService.updateUser(this.user.id, { deactive: deactivate }).subscribe(
      () => {
        if (deactivate) {
          this.toastr.success('User deactivated');
          this.getUserInfo(this.user.id);
        } else {
          this.toastr.success('User reactivated');
          this.getUserInfo(this.user.id);
        }
      },
      () => {
        this.toastr.error('Failed to change user activation status');
      }
    );
  }

  async grantUserAdmin(admin: boolean) {
    const action = admin ? 'grant' : 'revoke';
    const actioned = admin ? 'granted' : 'revoked';

    if (!(await this.popups.confirm(`Are you sure you want to ${action} FULL ADMIN access for this user?`))) {
      return;
    }
    this.userService.updateUser(this.user.id, { admin }).subscribe(
      () => {
        this.toastr.success(`User has been ${actioned} admin access`);
        this.getUserInfo(this.user.id);
      },
      () => {
        this.toastr.error('Failed to change user admin access');
      }
    );
  }

  async blacklistUser() {
    if (!(await this.popups.confirm(`Are you sure you want to blacklist this user?`))) {
      return;
    }
    this.userService.blacklistUser(this.user.id, { deactive: true }).subscribe(
      () => {
        this.toastr.success('User black listed');
        this.getUserInfo(this.user.id);
      },
      () => {
        this.toastr.error('Failed to blacklist user');
      }
    );
  }

  async removeAllPosts() {
    if (!(await this.popups.confirm(`Are you sure you want to remove all feed items for this user?`))) {
      return;
    }
    this.feedsService.deleteUserPosts(this.user.id).subscribe(
      () => {
        this.toastr.success('Posts removed');
        this.getUserInfo(this.user.id);
      },
      () => {
        this.toastr.error('Failed to remove posts');
      }
    );
  }

  async reportSpammer() {
    if (!(await this.popups.confirm(`Are you sure you want to flag this user as spammer?`))) {
      return;
    }
    this.userService.setSpammer(this.user.id).subscribe(
      () => {
        this.toastr.success('Set to spammer');
        this.getUserInfo(this.user.id);
      },
      () => {
        this.toastr.error('Failed to set spammer');
      }
    );
  }

  async gdprRemove() {
    if (!(await this.popups.confirm(`Are you sure you want to remove GDPR for this user?`))) {
      return;
    }
    this.userService.deleteUserGdpr(this.user.id).subscribe(
      () => {
        this.toastr.success('User Deleted');
        this.router.navigateByUrl('/');
      },
      () => {
        this.toastr.error('Failed to delete user');
      }
    );
  }

  deletePost(postId: string) {
    this.feedsService.deleteFeedItem(postId).subscribe(
      () => {
        for (let i = 0; i < this.posts.length; i++) {
          if (this.posts[i].id === postId) {
            this.posts.splice(i, 1);
            break;
          }
        }
        this.toastr.success('Post deleted');
      },
      () => this.toastr.error('Delete failed')
    );
  }

  private getCreditSummary(userId: string) {
    this.rewardsService.getCreditSummary(userId).then(
      (result) => {
        this.creditSummary = result;
      },
      () => {
        this.toastr.error('failed to getCreditSummary');
      }
    );
  }

  private isAllSelected() {
    const numSelected = this.referredUsersSelection.selected.length;
    const numRows = this.referredUsers.data.length;
    return numSelected === numRows;
  }

  private isSelectedPage() {
    const numSelected = this.referredUsersSelection.selected.length;
    let endIndex: number;
    if (this.referredUsers.data.length > (this.referredUsers.paginator.pageIndex + 1) * this.referredUsers.paginator.pageSize) {
      endIndex = (this.referredUsers.paginator.pageIndex + 1) * this.referredUsers.paginator.pageSize;
    } else {
      endIndex = this.referredUsers.data.length - (this.referredUsers.paginator.pageIndex * this.referredUsers.paginator.pageSize);
    }
    return numSelected === endIndex;
  }

  private masterToggle() {
    if (this.isAllSelected()) {
      this.referredUsersSelection.clear();
    } else {
      this.referredUsers.data.forEach(row => this.referredUsersSelection.select(row));
    }
  }

  selectRows() {
    let endIndex: number;
    if (this.referredUsers.data.length > (this.referredUsers.paginator.pageIndex + 1) * this.referredUsers.paginator.pageSize) {
      endIndex = (this.referredUsers.paginator.pageIndex + 1) * this.referredUsers.paginator.pageSize;
    } else {
      endIndex = this.referredUsers.data.length;
    }

    for (let index = (this.referredUsers.paginator.pageIndex * this.referredUsers.paginator.pageSize); index < endIndex; index++) {
      this.referredUsersSelection.select(this.referredUsers.data[index]);
    }
  }

  logSelection() {
    this.referredUsersSelection.selected.forEach(s => console.log(s.user.email));
  }

  blackListSelectedUsers() {
    const selectUserIds = [];
    this.referredUsersSelection.selected.forEach(s => selectUserIds.push(s.user.objectId));
    const blacklistUsers: BlacklistUsersRequest = {userIds: selectUserIds, description: this.blacklistUserFormGroup.get('description').value};
    this.userService.blacklistUsers(blacklistUsers).subscribe(
      () => {
        this.toastr.success('Blacklist users succeeded');
        this.getReferredUsers(this.userId);
      },
      () => {
        this.toastr.error('Blacklist users failed');
      }
    );
  }

  resetRewardPointsSelectedUsers() {
    const selectUserIds = [];
    this.referredUsersSelection.selected.forEach(s => selectUserIds.push(s.user.objectId));
    this.rewardsService.clearCredits(selectUserIds, this.me.userName, this.resetRewardFormGroup.get('description').value, 'support').then(
        (result) => {
          this.toastr.success('Reset reward points succeeded');
          this.getReferredUsers(this.userId);
        },
        (result) => {
          this.toastr.error('Reset reward points failed');
        }
      );
  }

  showUserInNewWindow(userId: string) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/user/${userId}`])
    );
    window.open(url, '_blank');
  }
}
