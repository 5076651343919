import { Injectable } from '@angular/core';
import { ILatLong } from './feeds-repository.service';
import { attachParametersToUrl } from './utils';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MarketingRepositoryService {
  constructor(private http: HttpClient) {}

  /*@ResourceAction({
      method: RequestMethod.Get,
      path: '/2/marketing/stats',
      auth: true
  })
  getStats: ResourceMethod<IMarketingStatInput, IMarketingStats>; */

  getStats(params: IMarketingStatInput): Observable<IMarketingStats> {
    const url = '/2/marketing/stats';
    return this.http.get<IMarketingStats>(attachParametersToUrl(url, params));
  }

  /*@ResourceAction({
    method: RequestMethod.Get,
    path: '/2/marketing/push/estimate',
    auth: true
  })
  getEstimate: ResourceMethod<{geoFenceId: string}, {count: number}>;*/

  getEstimate(params: { geoFenceId: string }): Observable<{ count: number }> {
    const url = '/2/marketing/push/estimate';
    return this.http.get<{ count: number }>(attachParametersToUrl(url, params));
  }

  /*@ResourceAction({
    method: RequestMethod.Get,
    path: '/2/marketing/push/estimate',
    auth: true
  })
  getPushEstimate: ResourceMethod<IPushEstimate, {count: number}>;*/

  getPushEstimate(params: IPushEstimate): Observable<{ count: number }> {
    const url = '/2/marketing/push/estimate';
    return this.http.get<{ count: number }>(attachParametersToUrl(url, params));
  }

  /*@ResourceAction({
    method: RequestMethod.Get,
    path: '/2/marketing/push',
    auth: true
  })
  getPushAnalytics: ResourceMethod<IPushAnalyticsRequest, IPushAnalyticsResponse>;*/

  getPushAnalytics(params: IPushAnalyticsRequest): Observable<IPushAnalyticsResponse> {
    const url = '/2/marketing/push';
    return this.http.get<IPushAnalyticsResponse>(attachParametersToUrl(url, params));
  }

  /*@ResourceAction({
    method: RequestMethod.Post,
    path: '/2/marketing/push',
    auth: true,
    contentTypeJson: true
  })
  postPush: ResourceMethod<IPush, null>;*/

  postPush(body: IPush): Observable<null> {
    const url = '/2/marketing/push';
    return this.http.post<null>(url, body);
  }

  /*@ResourceAction({
    method: RequestMethod.Get,
    path: '/2/marketing/segments',
    auth: true
  })
  getUserSegments: ResourceMethod<{groupId?: string}, ISegmentsResponse>;*/

  getUserSegments(params: { groupId?: string }): Observable<ISegmentsResponse> {
    const url = '/2/marketing/segments';
    return this.http.get<ISegmentsResponse>(attachParametersToUrl(url, params));
  }

  /*@ResourceAction({
    method: RequestMethod.Post,
    path: '/2/marketing/segments',
    auth: true,
    contentTypeJson: true
  })
  postUserSegment: ResourceMethod<IUserSegment, null>;*/

  postUserSegment(body: IUserSegment): Observable<null> {
    const url = '/2/marketing/segments';
    return this.http.post<null>(url, body);
  }

  /*@ResourceAction({
    method: RequestMethod.Delete,
    path: '/2/marketing/segments/{!segmentId}',
    auth: true
  })
  deleteUserSegment: ResourceMethod<{segmentId: string}, null>;*/

  deleteUserSegment(segmentId: string) {
    const url = '/2/marketing/segments/' + segmentId;
    return this.http.delete<any>(url);
  }

  /*@ResourceAction({
    method: RequestMethod.Get,
    path: '/2/marketing/geosegments',
    auth: true
  })
  getGeosegments: ResourceMethod<IGeosegmentsInput, IGeosegmentsResponse>;*/

  getGeosegments(params: IGeosegmentsInput): Observable<IGeosegmentsResponse> {
    const url = '/2/marketing/geosegments';
    return this.http.get<IGeosegmentsResponse>(attachParametersToUrl(url, params));
  }

  /*@ResourceAction({
    method: RequestMethod.Post,
    path: '/2/marketing/geosegments',
    auth: true,
    contentTypeJson: true
  })
  postGeosegment: ResourceMethod<IPostGeoInput, null>;*/

  postGeosegment(body: IPostGeoInput): Observable<null> {
    const url = '/2/marketing/geosegments';
    return this.http.post<null>(url, body);
  }

  /*@ResourceAction({
    method: RequestMethod.Delete,
    path: '/2/marketing/geosegments/{!segmentId}',
    auth: true
  })
  deleteGeosegment: ResourceMethod<{segmentId: string}, null>;*/

  deleteGeosegment(segmentId: string) {
    const url = '/2/marketing/geosegments/' + segmentId;
    return this.http.delete<any>(url);
  }

  /*@ResourceAction({
    method: RequestMethod.Get,
    path: '/2/marketing/templates',
    auth: true
  })
  getTemplates: ResourceMethod<{groupId?: string}, ITemplatesResponse>;*/

  getTemplates(params: { groupId?: string }): Observable<ITemplatesResponse> {
    const url = '/2/marketing/templates';
    return this.http.get<ITemplatesResponse>(attachParametersToUrl(url, params));
  }

  /*@ResourceAction({
    method: RequestMethod.Post,
    path: '/2/marketing/templates',
    auth: true,
    contentTypeJson: true
  })
  postTemplate: ResourceMethod<ITemplate, null>;*/

  postTemplate(body: ITemplate): Observable<null> {
    const url = '/2/marketing/templates';
    return this.http.post<null>(url, body);
  }

  /*@ResourceAction({
    method: RequestMethod.Put,
    path: '/2/marketing/templates/{!id}',
    auth: true,
    contentTypeJson: true
  })
  editTemplate: ResourceMethodStrict<ITemplate, {id: string}, null>;*/

  editTemplate(userId: string, body: ITemplate): Observable<null> {
    const url = '/2/marketing/templates/' + userId;
    return this.http.put<null>(url, body);
  }

  /*@ResourceAction({
    method: RequestMethod.Delete,
    path: '/2/marketing/templates/{!id}',
    auth: true
  })
  deleteTemplate: ResourceMethod<{id: string}, null>;*/

  deleteTemplate(templateId: string) {
    const url = '/2/marketing/templates/' + templateId;
    return this.http.delete<any>(url);
  }

  /*@ResourceAction({
    method: RequestMethod.Get,
    path: '/2/marketing/geotriggers',
    auth: true
  })
  getGeoTriggers: ResourceMethod<{groupId?: string}, ITriggersResponse>;*/

  getGeoTriggers(params: { groupId?: string }): Observable<ITriggersResponse> {
    const url = '/2/marketing/geotriggers';
    return this.http.get<ITriggersResponse>(attachParametersToUrl(url, params));
  }

  /*@ResourceAction({
    method: RequestMethod.Post,
    path: '/2/marketing/geotriggers',
    auth: true,
    contentTypeJson: true
  })
  postGeoTrigger: ResourceMethod<ITrigger, null>;*/

  postGeoTrigger(body: ITrigger): Observable<null> {
    const url = '/2/marketing/geotriggers';
    return this.http.post<null>(url, body);
  }

  /*@ResourceAction({
    method: RequestMethod.Delete,
    path: '/2/marketing/geotriggers/{!id}',
    auth: true
  })
  deleteGeoTrigger: ResourceMethod<{id: string}, null>;*/

  deleteGeoTrigger(triggerId: string) {
    const url = '/2/marketing/geotriggers/' + triggerId;
    return this.http.delete<any>(url);
  }

  /*@ResourceAction({
    method: RequestMethod.Get,
    path: '/2/marketing/timezones',
    auth: true,
    isArray: true
  })
  getTimeZones: ResourceMethod<{hint: string}, ITimeZone[]>;*/

  getTimeZones(params: { hint: string }): Observable<ITimeZone[]> {
    const url = '/2/marketing/timezones';
    return this.http.get<ITimeZone[]>(attachParametersToUrl(url, params));
  }
}

export interface IMarketingStatInput {
  to: string;
  from: string;
  groupId?: string;
}

export interface IPushEstimate {
  geoRegionId?: string;
  groupId?: string;
  userSegmentId?: string;
}

export interface IPushAnalyticsRequest {
  groupId: string;
  stats: boolean;
}

export interface IPushAnalyticsResponse {
  cursor?: string;
  metadata: any;
  results: IPushAnalytics[];
}

export interface IPushAnalytics {
  createdAt: string;
  createdBy: string;
  geoLocationName: string;
  groupId: string;
  marketingMessage: string;
  message: string;
  messageTemplateName: string;
  pushMessageId: string;
  stats: IPushAnalyticStats;
  fromUserId: string;
  marketingMessageId: string;
  pushOpen: number;
  pushRead: number;
  pushSent: number;
  userId: string;
  userSegmentName: string;
}

export interface IPushAnalyticStats {
  fromUserId: string;
  groupId: string;
  marketingMessageId: string;
  pushOpen: number;
  pushRead: number;
  pushSent: number;
  userId: string;
}

export interface IPush {
  geoRegionId?: string;
  groupId?: string;
  marketingMessage?: string;
  message?: string;
  userSegmentId?: string;
}

export interface ITriggersResponse {
  cursor?: string;
  metadata: any;
  results: ITrigger[];
}

export interface ITrigger {
  group?: string;
  coolOffDays?: number;
  eventType?: string;
  friday?: boolean;
  hours?: number;
  id?: string;
  marketingLocationId?: string;
  marketingMessageTemplateId?: string;
  marketingUserSegmentId?: string;
  marketingLocationName?: string;
  marketingMessageTemplateName?: string;
  marketingUserSegmentName?: string;
  minutes?: number;
  monday?: boolean;
  saturday?: boolean;
  sunday?: boolean;
  thursday?: boolean;
  timeZone?: any;
  tuesday?: boolean;
  wednesday?: boolean;
}

export interface ITemplatesResponse {
  cursor?: string;
  metadata: any;
  results: ITemplate[];
}

export interface ITemplate {
  group?: string;
  marketingText: string;
  message: string;
  name: string;
  id?: string;
}

export interface ISegmentsResponse {
  cursor?: string;
  metadata: any;
  results: IUserSegment[];
}

export interface IUserSegment {
  gender?: string;
  group?: string;
  id?: string;
  interests?: string[];
  maxActive?: number;
  maxAge?: number;
  maxCreated?: number;
  minActive?: number;
  minAge?: number;
  minCreated?: number;
  name?: string;
  nationality?: string;
}

export interface IGeosegmentsInput {
  groupId?: string;
}

export interface IPostGeoInput {
  group?: string;
  location: ILatLong;
  name: string;
  radius: number;
}

export interface IMarketingStats {
  regionEnters: number;
  regionExits: number;
  totalRegions: number;
}

export interface IGeosegmentsResponse {
  cursor: string;
  metadata: any;
  results: IGeosegment[];
}

export interface IGeosegment {
  group: any;
  id: string;
  location: IMarketingLocation;
  name: string;
  radius: number;
}

export interface IMarketingLocation {
  city: string;
  country: string;
  countryCode?: string;
  date: string;
  latitude: number;
  longitude: number;
  radius: any;
  state?: string;
  suburb: any;
}

export interface ITimeZone {
  name: string;
  recommened: boolean;
}
