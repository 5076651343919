import { canActivateWithAccessGuard } from '@/app/core/auth/access-guard';
import { atLeastGroupAdmin } from '@/app/core/auth/auth-pipes';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { DemographicsComponent } from './demographics/demographics.component';
import { MessageAnalyticsComponent } from './message-analytics/message-analytics.component';
import { NearbyPageComponent } from './nearby-page/nearby-page.component';

const routes: Routes = [
  { path: 'demographics', component: DemographicsComponent, ...canActivateWithAccessGuard(atLeastGroupAdmin) },
  { path: 'push', component: MessageAnalyticsComponent, ...canActivateWithAccessGuard(atLeastGroupAdmin) },
  { path: 'dashboard', component: DashboardPageComponent, ...canActivateWithAccessGuard(atLeastGroupAdmin) },
  {
    path: 'user-map',
    children: [
      {
        path: ':style',
        component: NearbyPageComponent,
        ...canActivateWithAccessGuard(atLeastGroupAdmin),
      },
      { path: '', redirectTo: 'all', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InsightsRoutingModule {}
