import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { canActivateWithAccessGuard } from '@/app/core/auth/access-guard';
import { atLeastGroupAdmin } from '@/app/core/auth/auth-pipes';
import { ChatPageComponent } from './chat-page.component';

const routes: Routes = [
  { path: ':id', component: ChatPageComponent, ...canActivateWithAccessGuard(atLeastGroupAdmin) },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class ChatPageRoutingModule {}
