import { Component, OnInit, ViewChild } from '@angular/core';
import { ToggleSideNavService } from '../../services/toggle-side-nav.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NumberReportedService } from '../../services/number-reported.service';

import { AppConfigService } from '@/app/config/app-config.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IGroupRole } from '@/app/services/repositories/user-repository.service';
import { SessionService } from '@/app/core/auth/session.service';
import { ContactUsModalComponent } from '../modals/contact-us-modal/contact-us-modal.component';
import { MatMenuTrigger } from '@angular/material/menu';

@UntilDestroy()
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  @ViewChild('messagesDropdownTrigger') messagesDropdown: MatMenuTrigger;
  @ViewChild('groupDropdownTrigger') groupDropdown: MatMenuTrigger;

  contactUsModalConfig: MatDialogConfig = new MatDialogConfig();

  constructor(
    private toggleSideNavService: ToggleSideNavService,
    private dialog: MatDialog,
    public currentUser: SessionService,
    public appConfig: AppConfigService,
    public numberReportedService: NumberReportedService,
    private router: Router
  ) {
    this.contactUsModalConfig.width = '400px';
  }

  get user() {
    return this.currentUser.currentUser;
  }

  get showMenuButton() {
    return this.currentUser.isLoggedIn;
  }

  toggleSideNav(): void {
    this.toggleSideNavService.toggleSideNav();
  }

  openContactUsModal(): void {
    this.dialog.open(ContactUsModalComponent, this.contactUsModalConfig);
  }

  ngOnInit() {}

  selectAdminGroup(selectedGroup?: IGroupRole) {
    this.currentUser.selectAdminGroup(selectedGroup);
    this.groupDropdown.closeMenu();
  }

  navToChatMessage(otherPartyUserId: string) {
    this.router.navigateByUrl(`/chat/${otherPartyUserId}`);
    this.messagesDropdown.closeMenu();
  }
}
