<mat-toolbar color="primary">
  <span>Users Map</span>
</mat-toolbar>

<div class="page-content">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <mat-form-field>
      <mat-select (selectionChange)="selectGeoFence($event)" placeholder="Select Fence">
        <mat-option *ngFor="let geoFence of geoFences" [value]="geoFence">
          {{ geoFence.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button routerLink="/geoFences" mat-raised-button>
      <mat-icon >scatter_plot</mat-icon>Go to Your GeoFences
    </button>
    <div></div>
    <button mat-icon-button (click)="openMapSettings()">
      <mat-icon>settings</mat-icon>
    </button>
  </div>

  <div class="nearby-map">
    <div
      id="map"
      class="map"
      style="height: 500px; width: 100%; border: 1px solid #0288d1; box-shadow: 2px 2px #01486e; margin-bottom: 8px"
    >
      <div id="loadingIndicator" *ngIf="loading">
        <mat-spinner [diameter]="80" [strokeWidth]="12"></mat-spinner>
      </div>
    </div>
    <div class="map_attribution" style="text-align: center; font-size: smaller">
      Map tiles by <a href="http://stamen.com">Stamen Design</a>, under
      <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by
      <a href="http://openstreetmap.org">OpenStreetMap</a>, under
      <a href="http://www.openstreetmap.org/copyright">ODbL</a>.
    </div>
  </div>

  <div *ngIf="users.length === limit">Maximum number of markers ({{ limit }}) reached.</div>
</div>
