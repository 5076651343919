<mat-toolbar color="primary">
  <span>Booking Query</span>
</mat-toolbar>

<div class="page-content">
  <div fxLayout="column">
    <div>
      <form [formGroup]="queryForm"
            (ngSubmit)="queryForm.valid && queryBooking()"
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign="space-evenly center"
            fxLayoutGap="1vw"
      >
        <mat-form-field fxFlex>
          <textarea matInput placeholder="Type a Livn Confirmation ID" formControlName="bookingId"></textarea>
        </mat-form-field>
        <button mat-raised-button color="primary" type="submit" [disabled]="!queryForm.valid">Submit</button>
      </form>
    </div>

    <div>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="key">
          <th mat-header-cell *matHeaderCellDef>Key</th>
          <td mat-cell *matCellDef="let element">{{element.key}}</td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>Value</th>
          <td mat-cell *matCellDef="let element"> {{element.value}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay" routerLink="/user/{{ getCurrentUserId() }}"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>
