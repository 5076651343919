import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  templateUrl: './leader-board-page.component.html',
  styleUrls: ['./leader-board-page.component.scss'],
})
export class LeaderBoardPageComponent {
  navLinks: MenuItem[] = [
    { url: './weekly', label: 'Weekly' },
    { url: './all', label: 'All Time' },
  ];

  constructor(router: Router) {}
}
