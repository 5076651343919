import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-geo-fences',
  templateUrl: './geo-fences.component.html',
  styleUrls: ['./geo-fences.component.scss'],
})
export class GeoFencesComponent implements OnInit {
  navLinks: MenuItem[] = [
    { url: './list', label: 'Your GeoFences' },
    { url: './create', label: 'Create GeoFence' },
  ];

  constructor() {}

  ngOnInit(): void {}
}
