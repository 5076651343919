import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { TemplatesPageComponent } from './templates-page.component';
import { YourTemplatesPageComponent } from './pages/your-templates-page/your-templates-page.component';
import { SimplifiedTemplatePageComponent } from './pages/simplified-template-page/simplified-template-page.component';
import { EditTemplatePageComponent } from './pages/edit-template-module/edit-template-page.component';
import { canActivateWithAccessGuard } from '@/app/core/auth/access-guard';
import { atLeastGroupAdmin } from '@/app/core/auth/auth-pipes';

const routes: Routes = [
  {
    path: '',
    component: TemplatesPageComponent,
    ...canActivateWithAccessGuard(atLeastGroupAdmin),
    children: [
      {
        path: 'create',
        component: SimplifiedTemplatePageComponent,
      },
      {
        path: 'list',
        component: YourTemplatesPageComponent,
      },
      { path: '', redirectTo: 'list' },
    ],
  },
  {
    path: 'edit',
    component: EditTemplatePageComponent,
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class TemplatesPageRoutingModule {}
