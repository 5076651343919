import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { GroupsRepositoryService, IGroupInfo } from '@/app/services/repositories/groups-repository.service';
import { IUser } from '@/app/services/repositories/user-repository.service';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SessionService } from '@/app/core/auth/session.service';
import {
  ICroppedPhoto,
  IMyCropperSettings,
  PhotoCropperModalComponent,
} from '@/app/components/modals/photo-cropper-modal/photo-cropper-modal.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

async function dataURItoBlob(dataURI: string) {
  // thanks stackoverflow https://stackoverflow.com/questions/12168909/blob-from-dataurl
  const res = await fetch(dataURI);
  const blob = await res.blob();
  return blob;
}

@UntilDestroy()
@Component({
  selector: 'app-group-info',
  templateUrl: './group-info.component.html',
  styleUrls: ['./group-info.component.scss'],
})
export class GroupInfoComponent implements OnInit {
  groupInfo: IGroupInfo;
  groupAdmins: IUser[] = [];
  marketingUser: IUser[] = [];

  infoForm: UntypedFormGroup;
  descriptionForm: UntypedFormGroup;
  logoPhotoForm: UntypedFormGroup;
  coverPhotoForm: UntypedFormGroup;

  cropperModalConfig: MatDialogConfig = new MatDialogConfig();

  croppedLogoPhoto: ICroppedPhoto;
  logoFile: File;
  @ViewChild('hiddenLogoPhotoInput', { static: true }) hiddenLogoPhotoInput: any;

  croppedCoverPhoto: ICroppedPhoto;
  coverFile: File;
  @ViewChild('hiddenCoverPhotoInput', { static: true }) hiddenCoverPhotoInput: any;

  displayedColumns = ['name', 'email', 'actions'];

  newAdminEmail = undefined;
  newMarketingUserEmail = undefined;

  editInfoSpinner = false;
  editDescSpinner = false;
  logoSpinner = false;
  covPhotoSpinner = false;
  newGroupAdminSpinner = false;
  newMarketingUserSpinner = false;

  constructor(
    public currentUser: SessionService,
    public formbuilder: UntypedFormBuilder,
    public groupsService: GroupsRepositoryService,
    public toastr: ToastrService,
    public modal: MatDialog,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    this.currentUser.currentAdminGroup$.pipe(untilDestroyed(this)).subscribe(() => {
      this.getData();
    });
    this.createDescriptionForm();
    this.createLogoForm();
    this.createCoverForm();
    this.createInfoForm();
    this.cropperModalConfig.width = '600px';
  }

  get currentAdminGroupId() {
    return this.currentUser.currentAdminGroup.groupId;
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.getGroupStats();
    this.getGroupAdmins();
    this.getGroupMarketingUser();
  }

  getGroupStats() {
    this.groupsService.getGroupInfo(this.currentAdminGroupId).subscribe(
      (result) => {
        this.groupInfo = result;
        this.infoForm.reset({
          name: result.name,
          website: result.websiteUrl,
          type: result.type,
          isPublic: result.groupPublic.toString(),
        });
        this.descriptionForm.reset({
          description: result.description,
        });
      },
      (error) => console.log(error)
    );
  }

  getGroupAdmins() {
    this.groupsService.getGroupAdmins(this.currentAdminGroupId).subscribe(
      (result) => {
        this.groupAdmins = result;
      },
      (error) => console.log(error)
    );
  }

  getGroupMarketingUser() {
    this.groupsService.getGroupMarketingUser(this.currentAdminGroupId).subscribe(
      (result) => {
        this.marketingUser = [];
        if (result !== null) {
          this.marketingUser.push(result);
        }
      },
      (error) => console.log(error)
    );
  }

  createInfoForm() {
    this.infoForm = this.formbuilder.group({
      name: ['', Validators.required],
      website: ['', Validators.required],
      type: ['', Validators.required],
      isPublic: ['', Validators.required],
    });
  }

  createDescriptionForm() {
    this.descriptionForm = this.formbuilder.group({
      description: ['', Validators.required],
    });
  }

  saveInfo() {
    this.editInfoSpinner = true;
    this.groupsService.putGroup(this.prepareInfo(), this.currentAdminGroupId).subscribe(
      () => {
        this.editInfoSpinner = false;
        this.toastr.success('Info Saved');
        this.getGroupStats();
      },
      () => {
        this.editInfoSpinner = false;
        this.toastr.error('Info Save Failed');
      }
    );
  }

  prepareInfo(): IGroupInfo {
    return {
      name: this.infoForm.get('name').value,
      websiteUrl: this.infoForm.get('website').value,
      type: this.infoForm.get('type').value,
      groupPublic: this.infoForm.get('isPublic').value,
    };
  }

  saveDescription() {
    this.editDescSpinner = true;
    this.groupsService.putGroup(this.prepareDescription(), this.currentAdminGroupId).subscribe(
      () => {
        this.editDescSpinner = false;
        this.toastr.success('Description Saved');
        this.getGroupStats();
      },
      () => {
        this.editDescSpinner = false;
        this.toastr.error('Description Save Failed');
      }
    );
  }

  prepareDescription(): IGroupInfo {
    return {
      description: this.descriptionForm.get('description').value,
    };
  }

  createLogoForm() {
    this.logoPhotoForm = this.formbuilder.group({
      logo: ['', Validators.required],
    });
  }

  onLogoPhotoChange($event) {
    const files = $event.target.files; // FileList Object
    this.logoFile = files[0]; // File Object

    // Set this for form validation
    this.logoPhotoForm.controls['logo'].setValue(this.logoFile ? this.logoFile.name : '');

    const cropperSettings: IMyCropperSettings = {
      files,
      height: 400,
      width: 400,
    };

    // Open cropping modal
    this.cropperModalConfig.data = cropperSettings;
    const modalRef = this.modal.open(PhotoCropperModalComponent, this.cropperModalConfig);

    // when modal closes we receive our cropped image
    modalRef.afterClosed().subscribe((result) => {
      if (!result.data) {
        return;
      }
      this.croppedLogoPhoto = result.data[0];
      // Clear the actual input in case user deletes photo then tries to reupload the same photo.
      this.hiddenLogoPhotoInput.nativeElement.value = '';
      this.changeDetectorRef.detectChanges();
    });
  }

  deleteLogo() {
    this.logoPhotoForm.controls['logo'].setValue(''); // Reset form validation
    this.croppedLogoPhoto = null;
    this.changeDetectorRef.detectChanges();
  }

  async saveLogo() {
    this.logoSpinner = true;
    const blob = await dataURItoBlob(this.croppedLogoPhoto.srcString);
    this.groupsService.postLogo(blob, this.currentAdminGroupId).subscribe(
      () => {
        this.logoSpinner = false;
        this.toastr.success('Logo Saved');
        this.getGroupStats(); // this will update the logo on the page
      },
      (e) => {
        this.toastr.error('Error Saving Logo');
        console.log(e);
        this.logoSpinner = false;
        this.getGroupStats(); // this will update the logo on the page
        // TODO there is a wierd error where this function returns error even though the post was successful.
      }
    );
  }

  createCoverForm() {
    this.coverPhotoForm = this.formbuilder.group({
      cover: ['', Validators.required],
    });
  }

  onCoverPhotoChange($event) {
    const files = $event.target.files; // FileList Object
    this.coverFile = files[0]; // File Object

    // Set this for form validation
    this.coverPhotoForm.controls['cover'].setValue(this.coverFile ? this.coverFile.name : '');

    const cropperSettings: IMyCropperSettings = {
      files,
      height: 300,
      width: 400,
    };

    // Open cropping modal
    this.cropperModalConfig.data = cropperSettings;
    const modalRef = this.modal.open(PhotoCropperModalComponent, this.cropperModalConfig);

    // when modal closes we receive our cropped image
    modalRef.afterClosed().subscribe((result) => {
      if (!result.data) {
        return;
      }
      this.croppedCoverPhoto = result.data[0];
      // Clear the actual input in case user deletes photo then tries to reupload the same photo.
      this.hiddenCoverPhotoInput.nativeElement.value = '';
      this.changeDetectorRef.detectChanges();
    });
  }

  deleteCover() {
    this.coverPhotoForm.controls['cover'].setValue(''); // Reset form validation
    this.croppedCoverPhoto = null;
    this.changeDetectorRef.detectChanges();
  }

  async saveCover() {
    this.covPhotoSpinner = true;
    const blob = await dataURItoBlob(this.croppedCoverPhoto.srcString);
    this.groupsService.postCover(blob, this.currentAdminGroupId).subscribe(
      () => {
        this.covPhotoSpinner = false;
        this.toastr.success('Cover Saved');
        this.getGroupStats(); // this will update the cover on the page
      },
      (e) => {
        console.log(e);
        this.toastr.error('Error Saving Cover');
        this.covPhotoSpinner = false;
        this.getGroupStats(); // this will update the cover on the page
        // TODO there is a wierd error where this function returns error even though the post was successful.
      }
    );
  }

  addNewAdmin(form: NgForm) {
    this.newGroupAdminSpinner = true;
    this.groupsService.addGroupAdmin([this.newAdminEmail], this.currentAdminGroupId).subscribe(
      () => {
        this.newGroupAdminSpinner = false;
        this.getGroupAdmins();
        this.newAdminEmail = undefined;
        form.reset();
      },
      (error) => {
        this.newGroupAdminSpinner = false;
        console.log(error);
        this.toastr.error('User not found');
      }
    );
  }

  deleteAdmin(admin: IUser) {
    this.groupsService.deleteGroupAdmin(admin.email, this.currentAdminGroupId).subscribe(
      () => {
        this.getGroupAdmins();
        this.toastr.success('Admin Deleted');
      },
      (error) => {
        console.log(error);
        this.toastr.error('Delete Admin Failed');
      }
    );
  }

  addNewMarketUser(form: NgForm) {
    this.newMarketingUserSpinner = true;
    this.groupsService.addMarketingUser(this.newMarketingUserEmail, this.currentAdminGroupId).subscribe(
      () => {
        this.newMarketingUserSpinner = false;
        this.getGroupMarketingUser();
        this.newMarketingUserEmail = undefined;
        form.reset();
      },
      (error) => {
        this.newMarketingUserSpinner = false;
        console.log(error);
        this.toastr.error('Error adding Marketing User');
      }
    );
  }

  deleteMarketUser(mUser: IUser) {
    this.groupsService.deleteMarketingUser(mUser.email, this.currentAdminGroupId).subscribe(
      () => {
        this.getGroupMarketingUser();
        this.toastr.success('Marketing User Deleted');
      },
      (error) => {
        console.log(error);
        this.toastr.error('Delete Marketing User Failed');
      }
    );
  }
}
