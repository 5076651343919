<h2 mat-dialog-title>Support</h2>
<mat-dialog-content class="mat-typography">
  <p>
    If you require assistance or wish to learn more about the services we offer, please email us at the address below:
  </p>
  <mat-list>
    <mat-list-item>
      <mat-icon  mat-list-icon>mail</mat-icon>
      <div mat-line>sales@travelloapp.com</div>
    </mat-list-item>
  </mat-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Close</button>
</mat-dialog-actions>
