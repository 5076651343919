import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@/app/shared.module';
import { ChatPageRoutingModule } from './chat-page-routing.module';
import { ChatPageComponent } from './chat-page.component';
import { ChatMessageComponent } from './components/chat-message/chat-message.component';

@NgModule({
  declarations: [ChatPageComponent, ChatMessageComponent],
  imports: [CommonModule, ChatPageRoutingModule, SharedModule],
})
export class ChatPageModule {}
