import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { canActivateWithAccessGuard } from '@/app/core/auth/access-guard';
import { atLeastGroupAdmin } from '@/app/core/auth/auth-pipes';
import { FeedPageComponent } from './feed-page.component';

const routes: Routes = [
  {
    path: ':typeOfPosts',
    component: FeedPageComponent,
    ...canActivateWithAccessGuard(atLeastGroupAdmin),
  },
  { path: '', redirectTo: 'all', pathMatch: 'full' },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class FeedPageRoutingModule {}
