import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RewardServicePromiseClient } from '@travello/grpc-api/js/reward_grpc_web_pb';
import { TransactionRequest, TransactionResponse } from '@travello/grpc-api/js/reward_pb';
import { AppConfigService } from '@/app/config/app-config.service';
import * as grpcWeb from 'grpc-web';
import { toArray } from 'rxjs/operators';
import { SessionService } from '@/app/core/auth/session.service';

@Injectable()
export class StatusTransactionsResolver implements Resolve<Observable<Array<TransactionResponse>>> {
  client: RewardServicePromiseClient;

  constructor(private appConfigService: AppConfigService, private currentUserService: SessionService) {
    const config = this.appConfigService.appConfig;
    this.client = new RewardServicePromiseClient(config.grpcUrl);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userId = route.paramMap.get('id');
    const request = new TransactionRequest();
    request.setUserId(userId);

    return new Observable<TransactionResponse>((observer) => {
      this.client
        .statusTransactions(request, this.currentUserService.authHeader)
        .on('data', (response: TransactionResponse) => {
          observer.next(response);
        })
        .on('end', () => {
          observer.complete();
        })
        .on('error', (e: any) => {
          console.log(e);
          observer.error(e);
        })
        .on('status', (status: grpcWeb.Status) => {
          if (status.code === 0) {
            // 'end' seems to only occasionally appear in our setup
            observer.complete();
          }
        });
    }).pipe(toArray());
  }
}
