import { NgModule } from '@angular/core';
import { FeedCommentsRoutingModule } from './comments-page-routing.module';
import { CommentCardComponent } from './sub-sections/comment-card.component';
import { FeedCommentsPageComponent } from './comments-page.component';
import { SharedModule } from '../../../shared.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [FeedCommentsPageComponent, CommentCardComponent],
  imports: [CommonModule, SharedModule, FeedCommentsRoutingModule],
})
export class FeedCommentsModule {}
