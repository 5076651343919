import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * GeolocationService class.
 * https://developers.google.com/maps/documentation/javascript/
 * https://dev.w3.org/geo/api/spec-source.html
 */

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class GeolocationService {
  private positionSrc = new ReplaySubject<GeolocationPosition>(1);
  readonly position$ = this.positionSrc.asObservable();
  constructor() {
    this.getCurrentPosition()
      .pipe(
        filter((pos) => !!pos),
        untilDestroyed(this)
      )
      .subscribe((pos) => {
        this.positionSrc.next(pos);
      });
  }
  /**
   * Tries HTML5 geolocation.
   *
   * Wraps the Geolocation API into an observable.
   *
   * @return An observable of Position
   */
  getCurrentPosition() {
    return new Observable<GeolocationPosition>((observer) => {
      // Invokes getCurrentPosition method of Geolocation API.
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          observer.next(position);
          observer.complete();
        },
        (error: GeolocationPositionError) => {
          console.log('Geolocation service: ' + error.message);
          observer.error(error);
        }
      );
    });
  }
}
