import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

/*
    Rest action definitions for white label of the portal
*/
@Injectable({ providedIn: 'root' })
export class GeoserverRepositoryService {
  constructor(private http: HttpClient) {}

  loadHeatmapSource(layerName: string, proj: string, extent: string[], groupId?: string) {
    const url =
      '/geoserver/wfs?service=WFS&' +
      'version=1.1.0&request=GetFeature&typename=travello:' +
      layerName +
      '&' +
      'outputFormat=application/json&srsname=' +
      proj +
      '&' +
      'CQL_FILTER=' +
      '(bbox(the_geom,' +
      extent.join(',') +
      ',%27' +
      proj +
      '%27))' +
      'and' +
      '(group_id%3D%27' +
      encodeURI(groupId ?? '__ALL__') +
      '%27)';

    return this.http.get(url).toPromise();
  }
}
