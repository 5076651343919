import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LeaderBoardPageRoutingModule } from './leader-board-page-routing.module';
import { SharedModule } from '@/app/shared.module';

import { LeaderBoardPageComponent } from './leader-board-page.component';
import { LeaderBoardComponent } from './pages/leader-board.component';
import { TransactionsComponent } from './pages/transactions.component';

@NgModule({
  declarations: [LeaderBoardPageComponent, LeaderBoardComponent, TransactionsComponent],
  imports: [CommonModule, LeaderBoardPageRoutingModule, SharedModule],
})
export class LeaderBoardPageModule {}
