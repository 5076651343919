import { SessionService } from '@/app/core/auth/session.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(private currentUser: SessionService, private router: Router) {}

  async ngOnInit() {
    await this.currentUser.logout();
    this.router.navigateByUrl('/login');
  }
}
