import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { TransactionResponse } from '@travello/grpc-api/js/reward_pb';
import { AppConfigService } from '@/app/config/app-config.service';

@Component({
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent implements OnInit {
  username: string;
  feedBaseUrl: string;
  dataSource = new MatTableDataSource<TransactionResponse>([]);

  columnsToDisplay = ['createdAt', 'icon', 'description', 'points'];

  constructor(route: ActivatedRoute, private appConfigService: AppConfigService) {
    const config = this.appConfigService.appConfig;
    this.feedBaseUrl = config.feedBaseUrl;
    route.paramMap.subscribe((map) => (this.username = map.get('name')));
    route.data.subscribe((data) => (this.dataSource.data = data.transactions));
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

  // bad style to parse IDs, but it works for now in absence of the actual target ID
  public toFeedUrl(transId: string): string {
    if (this.feedBaseUrl) {
      if (transId.startsWith('Feed_Featured_')) {
        return this.feedBaseUrl + transId.substring(14);
      }
      if (transId.startsWith('Feed_Like_')) {
        return this.feedBaseUrl + transId.substring(transId.lastIndexOf('_') + 1);
      }
    }
    return null;
  }
}
