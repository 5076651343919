import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@/app/shared.module';

import { CreateGroupComponent } from './create-group/create-group.component';
import { GroupInfoComponent } from './group-info/group-info.component';
import { GroupRoutingModule } from './group-routing.mobule';
import { GroupsListComponent } from './groups-list/groups-list.component';

@NgModule({
  declarations: [GroupInfoComponent, GroupsListComponent, CreateGroupComponent],
  imports: [CommonModule, GroupRoutingModule, SharedModule],
})
export class GroupModule {}
