<mat-toolbar color="primary">
  <span>Create Group</span>
</mat-toolbar>

<div class="page-content">
  <form [formGroup]="groupForm" (ngSubmit)="groupForm.valid && save()" fxLayout="column" fxLayoutGap="1em" fxFlex="70">
    <mat-form-field>
      <input matInput placeholder="Group Name" type="text" formControlName="name" required />
      <mat-error> This is <strong>required</strong> </mat-error>
    </mat-form-field>

    <mat-form-field>
      <input matInput placeholder="Website" type="text" formControlName="website" required />
      <mat-error> This is <strong>required</strong> </mat-error>
    </mat-form-field>

    <mat-form-field class="fill-parent">
      <mat-select formControlName="type" placeholder="Group Type" required>
        <mat-option value="INTEREST">Interest</mat-option>
        <mat-option value="AGENCY">Agency</mat-option>
      </mat-select>
      <mat-error> This is <strong>required</strong> </mat-error>
    </mat-form-field>

    <mat-form-field>
      <textarea
        matInput
        placeholder="Description"
        type="text"
        rows="5"
        formControlName="description"
        required
      ></textarea>
      <mat-error> This is <strong>required</strong> </mat-error>
    </mat-form-field>

    <div fxLayout="row" fxLayoutGap="8px">
      <button fxFlex="15" class="create-group-save-button" mat-raised-button color="primary" type="submit">Save</button>
    </div>
  </form>
</div>
