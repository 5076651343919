<div>
  <br />
  <div *ngIf="!loading" class="mat-elevation-z8 table-div">
    <mat-table #table [dataSource]="segments">
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      <ng-container matColumnDef="name">
        <mat-header-cell fxFlex="20" *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell fxFlex="20" *matCellDef="let element">
          <div *ngIf="element.name">
            {{ element.name }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="gender">
        <mat-header-cell fxFlex="10" *matHeaderCellDef> Gender </mat-header-cell>
        <mat-cell fxFlex="10" *matCellDef="let element">
          <div *ngIf="element.gender">
            {{ element.gender }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="age">
        <mat-header-cell fxFlex *matHeaderCellDef> Age </mat-header-cell>
        <mat-cell fxFlex *matCellDef="let element">
          <div *ngIf="element.minAge && element.maxAge">From {{ element.minAge }} to {{ element.maxAge }}</div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="lastActive">
        <mat-header-cell fxFlex *matHeaderCellDef> Last Active </mat-header-cell>
        <mat-cell fxFlex *matCellDef="let element">
          <div *ngIf="element.minActive">Within {{ element.minActive }} days</div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="created">
        <mat-header-cell fxFlex *matHeaderCellDef> Created </mat-header-cell>
        <mat-cell fxFlex *matCellDef="let element">
          <div *ngIf="element.minCreated">Within {{ element.minCreated }} days</div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="nationality">
        <mat-header-cell fxFlex *matHeaderCellDef> Nationality </mat-header-cell>
        <mat-cell fxFlex *matCellDef="let element">
          <div *ngIf="element.nationality">
            {{ element.nationality }}
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="interests">
        <mat-header-cell fxFlex *matHeaderCellDef> interests </mat-header-cell>
        <mat-cell fxFlex *matCellDef="let element">
          <div *ngIf="element.interests">
            <div class="my-chip" *ngFor="let interest of element.interests">
              {{ interest }}
            </div>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell fxFlex *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell fxFlex *matCellDef="let element">
          <div fxLayout="row" fxLayoutGap="2em">
            <button mat-raised-button color="warn" (click)="openConfirmationDialog(element)">Delete</button>
          </div>
        </mat-cell>
      </ng-container>
    </mat-table>
  </div>

  <br />
  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center center">
    <p>Loading...</p>
    <div class="spinner-container">
      <mat-spinner [diameter]="20" [strokeWidth]="4"></mat-spinner>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <p *ngIf="segments.length === 0 && !loading">You don't have any user segments set up.</p>
  </div>
  <br />
</div>
