import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RewardServicePromiseClient } from '@travello/grpc-api/js/reward_grpc_web_pb';
import {
  BalanceClearRequestFromAdmin,
  BalanceUpdateRequest,
  ChangeCreditsResponse,
  CreditTransactionResponseForAdmin,
  HomeScreenRequest,
  HomeScreenResponse,
  TransactionRequest
} from '@travello/grpc-api/js/reward_pb';
import { AppConfigService } from '@/app/config/app-config.service';
import * as grpcWeb from 'grpc-web';
import { toArray } from 'rxjs/operators';
import { SessionService } from '@/app/core/auth/session.service';

@Injectable({ providedIn: 'root' })
export class RewardsRepositoryService {
  client: RewardServicePromiseClient;

  constructor(private appConfigService: AppConfigService, private currentUserService: SessionService) {
    const config = this.appConfigService.appConfig;
    this.client = new RewardServicePromiseClient(config.grpcUrl);
  }

  getCreditTransactions(userId: string): Observable<CreditTransactionResponseForAdmin[]> {
    const request = new TransactionRequest();
    request.setUserId(userId);

    return new Observable<CreditTransactionResponseForAdmin>((observer) => {
      this.client
        .creditTransactionsForAdmin(request, this.currentUserService.authHeader)
        .on('data', (response: CreditTransactionResponseForAdmin) => {
          observer.next(response);
        })
        .on('end', () => {
          observer.complete();
        })
        .on('error', (e: any) => {
          console.log(e);
          observer.error(e);
        })
        .on('status', (status: grpcWeb.Status) => {
          if (status.code === 0) {
            observer.complete();
          }
        });
    }).pipe(toArray());
  }

  changeCredits(
    userId: string,
    adminId: string,
    amount: string,
    description: string,
    type: string
  ): Promise<ChangeCreditsResponse> {
    const request = new BalanceUpdateRequest();
    request.setUserId(userId);
    request.setAdminId(adminId);
    request.setDescription(description);
    request.setAmount(parseInt(amount, 10));
    request.setSubType(type);
    return this.client.changeCredits(request, this.currentUserService.authHeader);
  }

  clearCredits(
    userIds: string[],
    adminId: string,
    description: string,
    type: string
  ): Promise<ChangeCreditsResponse> {
    const request = new BalanceClearRequestFromAdmin();
    request.setUserIdsList(userIds);
    request.setAdminid(adminId);
    request.setDescription(description);
    request.setSubtype(type);
    return this.client.clearCredits(request, this.currentUserService.authHeader);
  }

  getCreditSummary(userId: string): Promise<HomeScreenResponse> {
    const request = new HomeScreenRequest();
    request.setUserId(userId);
    return this.client.homeScreen(request, this.currentUserService.authHeader);
  }
}
