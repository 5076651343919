import { Component, OnInit } from '@angular/core';
import { DEFAULT_DATE_RANGE, IDateRange } from '@/app/components/shared/date-selector/date-selector.component';
import { FeedsRepositoryService } from '@/app/services/repositories/feeds-repository.service';
import { NotificationsRepositoryService } from '@/app/services/repositories/notifications-repository.service';
import { MarketingRepositoryService } from '@/app/services/repositories/marketing-repository.service';
import { GroupsRepositoryService } from '@/app/services/repositories/groups-repository.service';
import { ToastrService } from 'ngx-toastr';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SessionService } from '@/app/core/auth/session.service';

@UntilDestroy()
@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent implements OnInit {
  dateRange = DEFAULT_DATE_RANGE;

  totalMembers = 0;
  newMembers = 0;
  newComments = 0;
  newPosts = 0;
  postViews = 0;
  totalPushOpen = 0;
  totalPushRead = 0;
  totalPushSent = 0;
  regionEnters = 0;
  regionExits = 0;
  totalRegions = 0;

  loadingFeedStats = false;
  loadingNotificationStats = false;
  loadingMarketingStats = false;
  loadingGroupStats = false;

  constructor(
    public feedsService: FeedsRepositoryService,
    public notificationsService: NotificationsRepositoryService,
    public marketingService: MarketingRepositoryService,
    public currentUser: SessionService,
    public groupsService: GroupsRepositoryService,
    public toastr: ToastrService
  ) {}

  get currentAdminGroupId() {
    return this.currentUser.currentAdminGroup.groupId;
  }

  ngOnInit(): void {
    this.currentUser.currentAdminGroup$.pipe(untilDestroyed(this)).subscribe(() => {
      this.getStats();
    });
  }

  getStats() {
    this.getNotificationStats();
    this.getMarketingStats();
    this.getFeedStats();
    if (this.currentAdminGroupId !== null) {
      this.getGroupStats();
    }
  }

  getGroupStats() {
    this.loadingGroupStats = true;
    this.groupsService
      .getStats(this.dateRange, this.currentAdminGroupId)
      .toPromise()
      .then(
        (results) => {
          this.loadingGroupStats = false;
          this.totalMembers = results.totalMembers;
          this.newMembers = results.newMembers;
        },
        () => {
          this.toastr.error('Error getting group stats');
          this.loadingGroupStats = false;
        }
      );
  }

  getFeedStats() {
    this.loadingFeedStats = true;
    this.feedsService
      .getStats({
        to: this.dateRange.to,
        from: this.dateRange.from,
        groupid: this.currentAdminGroupId,
      })
      .toPromise()
      .then(
        (results) => {
          this.loadingFeedStats = false;
          this.newComments = results.newComments;
          this.newPosts = results.newPosts;
          this.postViews = results.postViews;
        },
        () => {
          this.toastr.error('Error getting feed stats');
          this.loadingFeedStats = false;
        }
      );
  }

  getNotificationStats() {
    this.loadingNotificationStats = true;
    this.notificationsService
      .getStats({
        to: this.dateRange.to,
        from: this.dateRange.from,
        groupId: this.currentAdminGroupId,
      })
      .toPromise()
      .then(
        (results) => {
          this.loadingNotificationStats = false;
          this.totalPushOpen = results.totalPushOpen;
          this.totalPushRead = results.totalPushRead;
          this.totalPushSent = results.totalPushSent;
        },
        () => {
          this.toastr.error('Error getting notification stats');
          this.loadingNotificationStats = false;
        }
      );
  }

  getMarketingStats() {
    this.loadingMarketingStats = true;
    this.marketingService
      .getStats({
        to: this.dateRange.to,
        from: this.dateRange.from,
        groupId: this.currentAdminGroupId,
      })
      .toPromise()
      .then(
        (results) => {
          this.loadingMarketingStats = false;
          this.regionEnters = results.regionEnters;
          this.regionExits = results.regionExits;
          this.totalRegions = results.totalRegions;
        },
        () => {
          this.toastr.error('Error getting marketing stats');
          this.loadingMarketingStats = false;
        }
      );
  }

  // this is listening for changes emitted by the date range component
  setDateRange(dateRange: IDateRange) {
    this.dateRange = dateRange;
    this.getStats();
  }
}
