import { Component, ElementRef, Input, OnChanges, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-mock-mobile-template',
  templateUrl: './mock-mobile-template.component.html',
  styleUrls: ['./mock-mobile-template.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class MockMobileTemplateComponent implements OnChanges {
  @Input() templateHtml: string;

  @ViewChild('iframe', { static: true }) iframe: ElementRef;

  ngOnChanges() {
    if (this.templateHtml) {
      this.loadData(this.templateHtml);
    }
  }

  loadData(data) {
    const doc = this.iframe.nativeElement.contentDocument || this.iframe.nativeElement.contentWindow;
    doc.open();
    doc.write(data);
    doc.close();
  }
}

export interface IMockTemplateMobile {
  marketingText: string;
}
