import { Component } from '@angular/core';

@Component({
  selector: 'app-triggers-page',
  templateUrl: './triggers-page.component.html',
  styleUrls: ['./triggers-page.component.scss'],
})
export class TriggersPageComponent {
  navLinks: MenuItem[] = [
    { url: './list', label: 'Your Triggers' },
    { url: './create', label: 'Create Trigger' },
  ];
  constructor() {}
}
