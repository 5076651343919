<mat-toolbar color="primary">
  <span>
    Change Credit Points for {{targetUser.userName}}
  </span>
</mat-toolbar>

<div class="page-content">
  <div fxLayout="column">
    <div>
      <form [formGroup]="newRecordForm"
            (ngSubmit)="newRecordForm.valid && saveCreditRecord()"
            fxLayout="row"
            fxLayout.lt-md="column"
            fxLayoutAlign="space-evenly end"
            fxLayoutGap="1vw"
      >
        <mat-form-field fxFlex hintLabel="Note that this appears on the user's Activity Page in their App">
          <textarea matInput placeholder="Type a description" formControlName="description"></textarea>
        </mat-form-field>
        <mat-form-field hintLabel="Support for customer support Market for product promotion" width="60%">
          <mat-select
            formControlName="type"
            [(value)]="defaultTypeValue"
            width="60%"
          >
            <mat-option *ngFor="let typeChoice of typeChoices" [value]="typeChoice.value">
              {{ typeChoice.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex hintLabel="100 rewards points = USD $1.00">
          <textarea matInput placeholder="Type a points amount" formControlName="amount"></textarea>
        </mat-form-field>
        <button mat-raised-button color="primary" type="submit" [disabled]="!newRecordForm.valid">Submit</button>
      </form>
    </div>

    <div>
      <table mat-table [dataSource]="creditPointRecords" class="mat-elevation-z8" width="100%">
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let creditCecord"> {{ creditCecord.getSubType() }}</td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>Value</th>
          <td mat-cell *matCellDef="let creditCecord"> {{ creditCecord.getValue() }}</td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef>CreatedAt</th>
          <td mat-cell *matCellDef="let creditCecord"> {{ creditCecord.getCreatedAt().getSeconds() * 1000 | date: 'dd-MM-yyyy' }}</td>
        </ng-container>

        <ng-container matColumnDef="settlementDate">
          <th mat-header-cell *matHeaderCellDef>SettlementDate</th>
          <td mat-cell *matCellDef="let creditCecord"> {{ creditCecord.getSettlementDate() | date: 'dd-MM-yyyy' }}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let creditCecord"> {{ creditCecord.getDescription() }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="creditPointsColumnsToDisplay"></tr>
        <tr mat-row *matRowDef="let myRowData; columns: creditPointsColumnsToDisplay"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>
