import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from './core/auth/session.service';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  constructor(private currentUserService: SessionService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('http://') === 0 || req.url.indexOf('https://') === 0) {
      return next.handle(req);
    }

    if (req.url === '/build-info.json') {
      return next.handle(req);
    }

    let headers: { [name: string]: string | string[] } = {
      ...this.currentUserService.authHeader,
    };

    if (!(req.body instanceof FormData)) {
      headers = {
        ...headers,
        'Content-Type': 'application/json; charset=utf-8',
      };
    }

    const apiReq = req.clone({
      url: `${window.__APP_CONFIG__.restUrl ?? ''}${req.url}`,
      setHeaders: headers,
    });

    return next.handle(apiReq);
  }
}
