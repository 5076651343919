<mat-toolbar color="primary">
  <span>User Segments</span>
</mat-toolbar>

<div class="page-content">
  <nav mat-tab-nav-bar>
    <a
      mat-tab-link
      *ngFor="let link of navLinks"
      [routerLink]="link.url"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
    >
      {{ link.label }}
    </a>
  </nav>
  <router-outlet></router-outlet>
</div>
