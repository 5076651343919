import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { ApplicationRepositoryService } from '../services/repositories/application-repository.service';

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  private appConfigSrc = new BehaviorSubject<AppConfig>(window.__APP_CONFIG__);
  private buildInfoSrc = new BehaviorSubject<BuildInfo>(null);

  private whiteLabelLogoSrc = new BehaviorSubject<string>('assets/Transparent1x1Pixel.png');
  private whiteLabelAppIdSrc = new BehaviorSubject<string>('TRAVELLO');

  readonly buildInfo$ = this.buildInfoSrc.asObservable();
  readonly appConfig$ = this.appConfigSrc.asObservable();

  readonly ready$ = combineLatest([
    this.appConfig$.pipe(
      filter((v) => !!v),
      distinctUntilChanged()
    ),
    this.buildInfo$.pipe(
      filter((v) => !!v),
      distinctUntilChanged()
    ),
  ]).pipe(take(1));

  constructor(private http: HttpClient, private applicationService: ApplicationRepositoryService) {}

  get appConfig() {
    return this.appConfigSrc.getValue();
  }

  get buildInfo() {
    return this.buildInfoSrc.getValue();
  }

  get whiteLabelLogo() {
    return this.whiteLabelLogoSrc.getValue();
  }

  get whiteLabelAppId() {
    return this.whiteLabelAppIdSrc.getValue();
  }

  async loadAppConfig() {
    this.appConfigSrc.next(window.__APP_CONFIG__);
    await this.loadBuildInfo();
    await this.loadAppInfo();
  }

  private async loadBuildInfo() {
    const data = await this.http.get('/build-info.json').toPromise();
    this.buildInfoSrc.next(data as BuildInfo);
  }

  private async loadAppInfo() {
    const domain = document.domain;
    let referer;

    // info endpoint needs special treatment on localhost to get anything
    if (!domain || domain === 'localhost') {
      referer = this.appConfig.portalUrl;
    } else {
      referer = 'http://' + document.domain;
    }

    try {
      const result = await this.applicationService.getInfo({ referer }).toPromise();
      this.whiteLabelLogoSrc.next(result.iconUrl);
      this.whiteLabelAppIdSrc.next(result.applicationId);
    } catch {}
  }
}
