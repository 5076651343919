import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FeedsRepositoryService, IFeedInput, IFeedPost } from '@/app/services/repositories/feeds-repository.service';
import { ToastrService } from 'ngx-toastr';
import { NumberReportedService } from '../../../services/number-reported.service';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SessionService } from '@/app/core/auth/session.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';

enum TypeOfPosts {
  all = 'ALL',
  pinned = 'PINNEDONLY',
  reported = 'REPORTEDONLY',
  featured = 'FEATUREDONLY',
}
@UntilDestroy()
@Component({
  selector: 'app-feed-page',
  templateUrl: './feed-page.component.html',
  styleUrls: ['./feed-page.component.scss'],
})
export class FeedPageComponent implements OnInit {
  navLinks: MenuItem[] = [
    { url: '/feed/all', label: 'All' },
    { url: '/feed/pinned', label: 'Pinned' },
    { url: '/feed/reported', label: 'Reported' },
    { url: '/feed/featured', label: 'Editor Picks' },
  ];
  posts: IFeedPost[] = [];
  loading = false;
  feedFilterRadioSrc = new BehaviorSubject(TypeOfPosts.all);
  onFirstPage = true;
  cursor: string;

  readonly feedFilterRadio$ = this.feedFilterRadioSrc.asObservable();

  get feedFilterRadio() {
    return this.feedFilterRadioSrc.getValue();
  }

  constructor(
    public feedService: FeedsRepositoryService,
    public toastr: ToastrService,
    public currentUser: SessionService,
    public numberReportedService: NumberReportedService,
    public route: ActivatedRoute,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  get currentAdminGroupId() {
    return this.currentUser.currentAdminGroup.groupId;
  }

  ngOnInit() {
    this.route.paramMap.pipe(untilDestroyed(this)).subscribe((params) => {
      this.feedFilterRadioSrc.next(TypeOfPosts[params.get('typeOfPosts')]);
    });

    this.feedFilterRadio$.pipe(untilDestroyed(this)).subscribe((type) => {
      this.getPosts(type);
    });

    this.currentUser.currentAdminGroup$.pipe(untilDestroyed(this)).subscribe(() => {
      this.getPosts(this.feedFilterRadio);
    });
  }

  getPosts(whatToGet: string) {
    this.loading = true;
    if (this.feedFilterRadio === 'FEATUREDONLY') {
      this.getFeaturedPosts();
    } else {
      this.feedService.getAdminFeed(this.prepareAdminFeedRequest(whatToGet)).subscribe(
        (result) => {
          window.scrollTo(0, 0);
          this.posts = result.results;
          this.cursor = result.cursor;
          this.loading = false;
          if (this.feedFilterRadio === 'REPORTEDONLY') {
            this.numberReportedService.overrideNumReported(result.results.length);
          }
        },
        () => {
          this.posts = [];
          this.loading = false;
          this.toastr.error('Failed to retrieve posts');
        }
      );
    }
  }

  getFeaturedPosts() {
    this.feedService.getFeaturedPosts().subscribe(
      (result) => {
        window.scrollTo(0, 0);
        this.posts = result.results;
        this.cursor = result.cursor;
        this.loading = false;
      },
      () => {
        this.posts = [];
        this.loading = false;
        this.toastr.error('Failed to retrieve posts');
      }
    );
  }

  prepareAdminFeedRequest(whatToGet: string): IFeedInput {
    this.onFirstPage = true;
    if (whatToGet === 'PINNEDONLY') {
      return {
        groupId: this.currentAdminGroupId,
        stayOnTop: true,
      };
    } else if (whatToGet === 'REPORTEDONLY') {
      return {
        groupId: this.currentAdminGroupId,
        reportedOnly: true,
      };
    } else if (whatToGet === 'ALL') {
      return {
        groupId: this.currentAdminGroupId,
      };
    } else {
      // passed through the cursor because show more posts was clicked
      this.onFirstPage = false;
      return {
        groupId: this.currentAdminGroupId,
        cursor: this.cursor,
      };
    }
  }

  deletePost(id) {
    this.feedService.deleteFeedItem(id).subscribe(
      () => {
        for (let i = 0; i < this.posts.length; i++) {
          if (this.posts[i].id === id) {
            this.posts.splice(i, 1);
            break;
          }
        }
        this.toastr.success('Post deleted');
        this.numberReportedService.decreaseNumReported();
      },
      () => this.toastr.error('Delete failed')
    );
  }
}
