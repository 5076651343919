import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { canActivateWithAccessGuard } from '@/app/core/auth/access-guard';
import { atLeastGroupAdmin } from '@/app/core/auth/auth-pipes';
import { BookingQueryComponent } from '@/app/pages/booking/booking-query.component';

const routes: Routes = [
  {
    path: '**',
    component: BookingQueryComponent,
    ...canActivateWithAccessGuard(atLeastGroupAdmin),
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class BookingQueryRoutingModule {}
