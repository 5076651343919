import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PushNotificationComponent } from './push-notification.component';
import { canActivateWithAccessGuard } from '@/app/core/auth/access-guard';
import { atLeastGroupAdmin } from '@/app/core/auth/auth-pipes';

const routes: Routes = [
  { path: '', component: PushNotificationComponent, ...canActivateWithAccessGuard(atLeastGroupAdmin) },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class PushNotificationRoutingModule {}
