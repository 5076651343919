import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { canActivateWithAccessGuard } from '@/app/core/auth/access-guard';
import { atLeastGroupAdmin } from '@/app/core/auth/auth-pipes';
import { CreateGeoTriggerComponent } from './pages/create-geo-trigger/create-geo-trigger.component';
import { YourGeoTriggersComponent } from './pages/your-geo-triggers/your-geo-triggers.component';
import { TriggersPageComponent } from './triggers-page.component';

const routes: Routes = [
  {
    path: '',
    component: TriggersPageComponent,
    children: [
      {
        path: 'create',
        component: CreateGeoTriggerComponent,
        ...canActivateWithAccessGuard(atLeastGroupAdmin),
      },
      {
        path: 'list',
        component: YourGeoTriggersComponent,
        ...canActivateWithAccessGuard(atLeastGroupAdmin),
      },
      { path: '', redirectTo: 'list' },
    ],
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class TriggersPageRoutingModule {}
