import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-map-address-search-bar',
  templateUrl: './map-address-search-bar.component.html',
  styleUrls: ['./map-address-search-bar.component.scss'],
})
export class MapAddressSearchBarComponent implements OnInit, OnChanges {
  @Input() displayInSearchBar: string;
  @Output() addressSelected = new EventEmitter<PlaceResult>();

  @ViewChild('search', { static: true })
  public searchElementRef: ElementRef;

  constructor(private ngZone: NgZone) {}

  ngOnInit() {
    const autoComplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, { types: [] });

    autoComplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        // get the place result
        const place: google.maps.places.PlaceResult = autoComplete.getPlace();

        // verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        this.addressSelected.emit(place);
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'displayInSearchBar') {
        if (changes.displayInSearchBar.currentValue === 'CLEARTEXTBOX') {
          this.searchElementRef.nativeElement.value = '';
        } else {
          this.searchElementRef.nativeElement.value = changes.displayInSearchBar.currentValue;
        }
      }
    }
  }
}
