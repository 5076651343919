import { Injectable } from '@angular/core';
import { AppConfigService } from '@/app/config/app-config.service';
import { SessionService } from '@/app/core/auth/session.service';
import { ProductServicePromiseClient } from '@travello/grpc-api/js/product_grpc_web_pb';
import { BookingResponse, GetBookingRequest } from '@travello/grpc-api/js/product_pb';

@Injectable({ providedIn: 'root' })
export class BookingRepositoryService {
  client: ProductServicePromiseClient;

  constructor(private appConfigService: AppConfigService, private currentUserService: SessionService) {
    const config = this.appConfigService.appConfig;
    this.client = new ProductServicePromiseClient(config.grpcUrl);
  }

  queryBooking(bookingId: string): Promise<BookingResponse> {
    const request = new GetBookingRequest();
    request.setBookingId(bookingId);
    return this.client.getBookingByThirdPartyId(request, this.currentUserService.authHeader);
  }
}
