import { IGroupRole, UserRepositoryService } from '@/app/services/repositories/user-repository.service';
import { Injectable } from '@angular/core';
import { uniqBy } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, firstValueFrom, lastValueFrom, of, pipe } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { SessionQuery } from './session.query';
import { AccessLevel, DEFAULT_ADMIN_GROUP, SessionState, SessionStore } from './session.store';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';

const getAuthHeader = (token?: string) => {
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
};

const sortGroupByName = (a: IGroupRole, b: IGroupRole) => {
  const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();
  if (nameA < nameB) {
    // sort string ascending
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0; // default return value (no sorting)
};

export function getAccessLevel(state: Partial<SessionState>) {
  const { isAdmin, adminGroups, currentAdminGroup, groups, token } = state;
  if (isAdmin) {
    return AccessLevel.FULL_ADMIN;
  }

  if (adminGroups.some((g) => g.groupId === currentAdminGroup.groupId)) {
    return AccessLevel.GROUP_ADMIN;
  }

  if (groups.some((g) => g.groupId === currentAdminGroup.groupId)) {
    return AccessLevel.GROUP_MEMBER;
  }

  if (!!token) {
    return AccessLevel.MEMBER;
  }

  return AccessLevel.NOT_LOGGED_IN;
}

type OAuthUser = Pick<LoginResponse, 'accessToken' | 'userData'>;
@Injectable({
  providedIn: 'root',
})
export class SessionService {
  readonly currentUser$ = this.query.currentUser$;
  readonly currentAdminGroup$ = this.query.currentAdminGroup$;

  bootstrapped = new BehaviorSubject(false);
  resetGroup = true;

  constructor(
    public store: SessionStore,
    private query: SessionQuery,
    private userRepository: UserRepositoryService,
    private toastr: ToastrService,
    // private auth: AngularFireAuth,
    private oidcSecurityService: OidcSecurityService
  ) {
    this.oidcSecurityService.isAuthenticated$.subscribe(({ isAuthenticated }) => {
      if (isAuthenticated) {
        this.syncOnce();
      }
    });
  }

  checkAuth() {
    return this.oidcSecurityService.checkAuth().pipe(this.syncPipe);
  }

  async syncMessages() {
    const chats = await this.userRepository.getChats({ limit: 100 }).toPromise();
    this.store.update({ chats });
  }

  private async selectProfile(user: OAuthUser): Promise<SessionState> {
    const token = user.accessToken;
    const claims = user.userData;
    const isAdmin = claims?.['ROLE'] === 'ADMIN';

    const [currentUser, chats] = await Promise.all([
      this.userRepository.getUser('me', { extended: true }, getAuthHeader(token)).toPromise(),
      this.userRepository.getChats({ limit: 100 }, getAuthHeader(token)).toPromise(),
    ]);

    const { groupRoles = [] } = currentUser;
    const groups = uniqBy(groupRoles.sort(sortGroupByName), 'groupId');
    const adminGroups = uniqBy(groupRoles.filter((e) => e.role === 'ADMIN').sort(sortGroupByName), 'groupId');

    let currentAdminGroup: IGroupRole;
    if (this.resetGroup) {
      currentAdminGroup = !isAdmin && adminGroups[0] ? adminGroups[0] : DEFAULT_ADMIN_GROUP;
      // if not a travello admin the user must be a group admin. therefore select a group for them automatically
    }
    this.resetGroup = true;

    const profile = {
      token,
      currentUser,
      chats,
      isAdmin,
      groups,
      adminGroups,
      currentAdminGroup,
      claims,
    };

    return profile;
  }

  private get syncPipe() {
    return pipe(
      switchMap((user: OAuthUser) => (user ? this.selectProfile(user) : of(undefined))),
      tap((state) => {
        this.store.update(state);
      })
    );
  }

  async syncOnce() {
    const u = <OAuthUser>{
      accessToken: await lastValueFrom(this.oidcSecurityService.getAccessToken()),
      userData: await lastValueFrom( this.oidcSecurityService.getUserData()),
    };
    return lastValueFrom(of(u).pipe(this.syncPipe));
  }

  /** Signs out the current user and clear the store */
  async logout() {
    await this.oidcSecurityService.logoff();
    await this.store.reset();
  }

  selectAdminGroup(selectedGroup: IGroupRole = DEFAULT_ADMIN_GROUP) {
    if (!selectedGroup?.groupId) {
      this.store.update({ currentAdminGroup: selectedGroup });
      this.toastr.success(`Switched back to ${selectedGroup.name} Mode`);
      return;
    }
    const { adminGroups } = this.store.getValue();
    const [group] = adminGroups.filter((g) => g.groupId === selectedGroup.groupId);
    if (group) {
      this.store.update({ currentAdminGroup: group });
      this.toastr.success('Switched to group ' + group.name);
    }
  }

  get chats() {
    return this.store.getValue().chats;
  }

  get adminGroups() {
    return this.store.getValue().adminGroups;
  }

  get groups() {
    return this.store.getValue().groups;
  }

  get currentAdminGroup() {
    return this.store.getValue().currentAdminGroup;
  }

  get currentUser() {
    return this.store.getValue().currentUser;
  }

  get token() {
    return this.store.getValue().token;
  }

  get isLoggedIn() {
    return !!this.token;
  }

  get accessLevel() {
    return getAccessLevel(this.store.getValue());
  }

  get authHeader() {
    return getAuthHeader(this.token);
  }

  get isAdmin() {
    return this.store.getValue().isAdmin;
  }

  canAdminUser(userId: string) {
    return this.accessLevel === AccessLevel.FULL_ADMIN || this.currentUser.id === userId;
  }

  get canAdminFeed() {
    return this.accessLevel >= AccessLevel.GROUP_ADMIN;
  }

  get canAdminCurrentGroup() {
    return this.accessLevel >= AccessLevel.GROUP_ADMIN;
  }

  get canReadCurrentGroup() {
    return this.accessLevel >= AccessLevel.GROUP_MEMBER;
  }

  get defaultAdminGroup() {
    return DEFAULT_ADMIN_GROUP;
  }
}
