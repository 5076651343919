import { canActivateWithAccessGuard } from '@/app/core/auth/access-guard';
import { atLeastGroupAdmin } from '@/app/core/auth/auth-pipes';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PortalComponent } from './portal.component';

const routes: Routes = [
  {
    path: ':portalSlug',
    children: [
      {
        path: '**',
        component: PortalComponent,
        ...canActivateWithAccessGuard(atLeastGroupAdmin),
      },
      {
        path: '',
        component: PortalComponent,
        ...canActivateWithAccessGuard(atLeastGroupAdmin),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PortalRoutingModule {}
