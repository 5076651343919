import { canActivateWithAccessGuard } from '@/app/core/auth/access-guard';
import { atLeastGroupAdmin } from '@/app/core/auth/auth-pipes';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GeoFencesComponent } from './geo-fences.component';
import { CreateGeoFencesPageComponent } from './pages/create-geofences-page/create-geofences-page.component';
import { YourGeoFencesPageComponent } from './pages/your-geofences-page/your-geofences-page.component';

const routes: Routes = [
  {
    path: '',
    component: GeoFencesComponent,
    ...canActivateWithAccessGuard(atLeastGroupAdmin),
    children: [
      { path: 'list', component: YourGeoFencesPageComponent },
      { path: 'create', component: CreateGeoFencesPageComponent },
      { path: '', redirectTo: 'list' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GeoFencesRoutingModule {}
