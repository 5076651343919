import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Layer } from 'ol/layer';

const DEFAULT_HEATMAP_SETTINGS: HeatmapSettings = {
  radius: 15,
  blur: 25,
  dampening: 0.2,
  opacity: 0.95,
};

const DEFAULT_ACTIVE_WITHIN_PERIOD = 1;

export interface MapSettings {
  baseLayer: ManagedLayer;
  weightProperty: LabelledProperty;
  heatmapSettings: HeatmapSettings;
  activeWithin: number;
}

export interface LabelledProperty {
  name: string;
  label: string;
}

export interface ManagedLayer {
  name: string;
  layer: Layer;
  usesOverlays: boolean;
}

export interface HeatmapSettings {
  radius: number;
  blur: number;
  dampening: number;
  opacity: number;
}

@Component({
  selector: 'app-map-settings-dialog',
  templateUrl: './map-settings-dialog.component.html',
  styleUrls: ['./map-settings-dialog.component.scss'],
})
export class MapSettingsDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<MapSettingsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  public confirmMessage: string;

  public static saveSettings(mapSettings: MapSettings) {
    localStorage.setItem('mapSettings.heatmapSettings', JSON.stringify(mapSettings.heatmapSettings));
    localStorage.setItem('mapSettings.baseLayerName', mapSettings.baseLayer.name);
    localStorage.setItem('mapSettings.weightPropertyName', mapSettings.weightProperty.name);
    localStorage.setItem('mapSettings.activeWithin', JSON.stringify(mapSettings.activeWithin));
  }

  public static loadSettings(baseLayers: ManagedLayer[], weightProperties: LabelledProperty[]): MapSettings {
    let heatmapSettings = DEFAULT_HEATMAP_SETTINGS;
    try {
      const item = localStorage.getItem('mapSettings.heatmapSettings');
      if (item) {
        heatmapSettings = JSON.parse(item);
      }
    } catch (e) {}
    let weightProperty = weightProperties.find(() => true);
    try {
      const weightPropertyName = localStorage.getItem('mapSettings.weightPropertyName');
      if (weightPropertyName) {
        for (const property of weightProperties) {
          if (property.name === weightPropertyName) {
            weightProperty = property;
          }
        }
      }
    } catch (e) {}
    let baseLayer = baseLayers.find(() => true);
    try {
      const baseLayerName = localStorage.getItem('mapSettings.baseLayerName');
      if (baseLayerName) {
        for (const layer of baseLayers) {
          if (layer.name === baseLayerName) {
            baseLayer = layer;
            break;
          }
        }
      }
    } catch (e) {}
    let activeWithin = DEFAULT_ACTIVE_WITHIN_PERIOD;
    try {
      const item = localStorage.getItem('mapSettings.activeWithin');
      if (item) {
        activeWithin = JSON.parse(item);
      }
    } catch (e) {}
    return {
      baseLayer,
      weightProperty,
      heatmapSettings,
      activeWithin,
    };
  }

  ngOnInit() {}

  resetHeatmapSettings() {
    this.data.heatmapSettings = DEFAULT_HEATMAP_SETTINGS;
    this.data.activeWithin = DEFAULT_ACTIVE_WITHIN_PERIOD;
  }
}
