import { canActivateWithAccessGuard } from '@/app/core/auth/access-guard';
import { atLeastGroupAdmin, fullAdminOnly, groupOnly } from '@/app/core/auth/auth-pipes';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateGroupComponent } from './create-group/create-group.component';
import { GroupInfoComponent } from './group-info/group-info.component';
import { GroupsListComponent } from './groups-list/groups-list.component';

const routes: Routes = [
  {
    path: '',
    ...canActivateWithAccessGuard(atLeastGroupAdmin),
    children: [
      { path: 'info', component: GroupInfoComponent, ...canActivateWithAccessGuard(groupOnly) },
      { path: 'list', component: GroupsListComponent },
      { path: 'create', component: CreateGroupComponent, ...canActivateWithAccessGuard(fullAdminOnly) },
      { path: '', redirectTo: 'list', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GroupRoutingModule {}
