import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PushNotificationRoutingModule } from './push-notification-routing.module';
import { PushNotificationComponent } from './push-notification.component';
import { SharedModule } from '@/app/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule, PushNotificationRoutingModule],
  declarations: [PushNotificationComponent],
})
export class PushNotificationModule {}
