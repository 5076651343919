import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { LeaderBoardItem } from '@travello/grpc-api/js/reward_pb';

@Component({
  templateUrl: './leader-board.component.html',
  styleUrls: ['./leader-board.component.scss'],
})
export class LeaderBoardComponent implements OnInit {
  dataSource = new MatTableDataSource<LeaderBoardItem>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  columnsToDisplay = ['position', 'points', 'name'];

  constructor(route: ActivatedRoute, private router: Router) {
    route.data.subscribe((data) => (this.dataSource.data = data.leaderboard));
  }
  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
  }
}
