import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { AppConfigService } from '@/app/config/app-config.service';
import { SessionService } from './core/auth/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public rootViewContainerRef: ViewContainerRef;
  public constructor(
    private appConfigService: AppConfigService,
    private sessionService: SessionService
  ) {}

  get buildInfo() {
    return this.appConfigService.buildInfo;
  }

  ngOnInit(): void {
    this.sessionService.checkAuth().subscribe({
      next: (loginResponse) => {
        console.info(loginResponse);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }
}
