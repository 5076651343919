<mat-toolbar color="primary">
  <span>Edit Message Template</span>
</mat-toolbar>

<div [ngClass]="{ 'page-content': mode === eTemplatePageMode.Edit }" class="page-content">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxLayoutGap="1vw">
    <div fxFlex="60">
      <form [formGroup]="templateForm" (ngSubmit)="saveTemplate()">
        <mat-form-field class="fill-parent">
          <input
            type="text"
            formControlName="templateName"
            matInput
            placeholder="Template Name"
            maxlength="100"
            required
          />
          <mat-error>You must enter a name for your template</mat-error>
        </mat-form-field>
        <mat-form-field hintLabel="Entice your customers with short punchy message" class="fill-parent">
          <input
            type="text"
            formControlName="pushMessage"
            matInput
            placeholder="Push Message"
            maxlength="100"
            required
          />
          <mat-error>You must enter a push message</mat-error>
        </mat-form-field>
        <br />
        <br />
        <br />
        <editor [init]="tinymceConfig" [formControl]="templateForm.controls.marketingText"></editor>
        <br />
        <div fxLayout="row" fxLayoutGap="0.5em">
          <div fxFlex></div>
          <button
            *ngIf="mode === eTemplatePageMode.Create"
            mat-raised-button
            color="accent"
            type="submit"
            [disabled]="!templateForm.valid"
            class="centred-button"
          >
            Save Template
          </button>
          <button
            *ngIf="mode === eTemplatePageMode.Edit"
            mat-raised-button
            color="accent"
            type="button"
            (click)="saveEdit()"
            [disabled]="!templateForm.valid"
            class="centred-button"
          >
            Save Edit
          </button>
          <button
            *ngIf="mode === eTemplatePageMode.Edit"
            mat-raised-button
            type="button"
            routerLink="/templates/yourTemplates"
            class="centred-button"
          >
            Back to Your Templates
          </button>
          <div fxFlex></div>
        </div>
      </form>
    </div>

    <app-mock-mobile-template [templateHtml]="templateForm.controls.marketingText.value"></app-mock-mobile-template>
  </div>
</div>
