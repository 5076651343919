export interface ExtraHttpHeaders {
  [header: string]: string | string[];
}

export function attachParametersToUrl(urlStr: string, params: unknown) {
  let queryString = '';
  let firstParam = true;

  if (params) {
    for (const key of Object.keys(params)) {
      if (params[key] !== null) {
        // this allows me to pass in groupId as null in a bunch of components and ignore it
        if (firstParam) {
          queryString += '?';
          firstParam = false;
        } else {
          queryString += '&';
        }
        queryString += key;
        queryString += '=';
        queryString += params[key];
      }
    }
  }

  return urlStr + queryString;
}
