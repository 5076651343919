import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IUserSegment, MarketingRepositoryService } from '@/app/services/repositories/marketing-repository.service';
import { SessionService } from '@/app/core/auth/session.service';
@Component({
  selector: 'app-create-usersegment-page',
  templateUrl: './create-usersegment-page.component.html',
  styleUrls: ['./create-usersegment-page.component.scss'],
})
export class CreateUserSegmentPageComponent implements OnInit {
  userSegmentForm: UntypedFormGroup;

  constructor(
    private formbuilder: UntypedFormBuilder,
    public marketingService: MarketingRepositoryService,
    public toastr: ToastrService,
    public router: Router,
    public currentUser: SessionService
  ) {
    this.createForm();
  }

  get currentAdminGroupId() {
    return this.currentUser.currentAdminGroup.groupId;
  }

  ngOnInit() {}

  createForm() {
    this.userSegmentForm = this.formbuilder.group({
      name: ['', Validators.required],
      gender: '',
      age: [[18, 99]],
      lastActive: 365,
      accountCreated: 3650,
      interests: '',
      nationality: '',
    });
  }

  save() {
    const payload: IUserSegment = this.createGeoPayload();

    this.marketingService.postUserSegment(payload).subscribe(
      () => {
        this.toastr.success('User Segment Saved');
        this.router.navigateByUrl('/userSegments');
      },
      () => {
        this.toastr.error('User Segment Save Failed');
      }
    );
  }

  createGeoPayload(): IUserSegment {
    const payload: IUserSegment = {
      gender: this.userSegmentForm.get('gender').value,
      group: this.currentAdminGroupId,
      maxAge: this.userSegmentForm.get('age').value[1],
      minActive: this.userSegmentForm.get('lastActive').value,
      minAge: this.userSegmentForm.get('age').value[0],
      minCreated: this.userSegmentForm.get('accountCreated').value,
      name: this.userSegmentForm.get('name').value,
    };

    if (this.userSegmentForm.get('interests').value) {
      payload.interests = this.userSegmentForm.get('interests').value;
    }

    if (this.userSegmentForm.get('nationality').value) {
      payload.nationality = this.userSegmentForm.get('nationality').value;
    }

    return payload;
  }
}
